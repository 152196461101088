import React, { useState } from 'react';
import axiosInstance from '../../../axios';
import { useNavigate } from 'react-router-dom';
//MaterialUI
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#fff200',
		color:'black'
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: '#fff200',
		color:'black',
	},
}));

export default function Create() {
	function slugify(string) {
		const a =
			'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
		const b =
			'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
		const p = new RegExp(a.split('').join('|'), 'g');

		return string
			.toString()
			.toLowerCase()
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
			.replace(/&/g, '-and-') // Replace & with 'and'
			.replace(/[^\w\-]+/g, '') // Remove all non-word characters
			.replace(/\-\-+/g, '-') // Replace multiple - with single -
			.replace(/^-+/, '') // Trim - from start of text
			.replace(/-+$/, ''); // Trim - from end of text
	}

	const navigate = useNavigate();
	const initialFormData = Object.freeze({
		title: '',
		slug: '',
		
		content: '',
		image: null,
		status: 'published',
	});

	const [formData, updateFormData] = useState(initialFormData);

	const handleChange = (e) => {
		if ([e.target.name] == 'title') {
			updateFormData({
				...formData,
				// Trimming any whitespace
				[e.target.name]: e.target.value.trim(),
				['slug']: slugify(e.target.value.trim()),
			});
		} else {
			updateFormData({
				...formData,
				// Trimming any whitespace
				[e.target.name]: e.target.value.trim(),
			});
		}
	};

	// const handleSubmit = (e) => {
	// 	e.preventDefault();
	// 	axiosInstance
	// 		.post(`admin/createadmin/`, {
	// 			title: formData.title,
	// 			slug: formData.slug,
	// 			author: 1,
	// 			excerpt: formData.excerpt,
	// 			content: formData.content,
	// 			image: formData.image,
	// 		})
	// 		.then((res) => {
	// 			navigate('/admin');
	// 		});
	// };
	const handleSubmit = (e) => {
		e.preventDefault();
	
		const postData = new FormData();
		postData.append('title', formData.title);
		postData.append('slug', formData.slug);
		postData.append('author', 1);
	
		postData.append('content', formData.content);
		postData.append('image', formData.image); 
		postData.append('status', formData.status);
	
		axiosInstance.post('admin/createadmin/', postData).then((res) => {
		  navigate('/admin');
		});
	  };
	const handleImageChange = (e) => {
		updateFormData({
		  ...formData,
		  image: e.target.files[0], // Update image field with selected file
		});
	  };
	  

	const classes = useStyles();

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}></Avatar>
				<Typography component="h1" variant="h5">
					Create New Post
				</Typography>
				<form className={classes.form} noValidate>
					<Grid container spacing={2}>
					<Grid item xs={12}>
              <input
                accept="image/*"
                id="image"
                name="image"
                type="file"
                onChange={handleImageChange}
              />
            </Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="title"
								label="Post Title"
								name="title"
								autoComplete="title"
								onChange={handleChange}
							/>
						</Grid>
					
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="slug"
								label="slug"
								name="slug"
								autoComplete="slug"
								value={formData.slug}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="content"
								label="content"
								name="content"
								autoComplete="content"
								onChange={handleChange}
								multiline
								rows={4}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel htmlFor="status">Status</InputLabel>
								<Select
									native
									label="Status"
									id="status"
									name="status"
									value={formData.status}
									onChange={handleChange}
								>
									<option value="draft">Draft</option>
									<option value="published">Published</option>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						
						className={classes.submit}
						onClick={handleSubmit}
						style={{	backgroundColor:"#fff200",
											color:"#000"}}
					>
						Create Post
					</Button>
				</form>
			</div>
		</Container>
	);
}