import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles  } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    accordion: {
      // adjust this value to your needs
      margin: '5px auto', // center the accordion horizontally
      backgroundColor: '#fff',
      '&:hover': {
        backgroundColor: '#fff200',
      },
    },
    expanded: {
      backgroundColor: '#fff200',
    },
    titel:{
        fontFamily:'Neuwelt',
       
    },
    sub:{
        fontFamily:'Neuwelt medium',
        textAlign:'justify'
    },
    titel_11:{
        fontFamily:'Neuwelt',
        fontSize:'22px',
        paddingLeft:'10px',
       
    },
  }));

const MyAccordion = () => {
  
 

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Typography className={classes.titel_11} variant="h4" gutterBottom>
        Frequently Asked Questuion
      </Typography>
      <Accordion className={classes.accordion}  expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.titel}>How Long Package Arrived?</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel1' ? classes.expanded : ''}>
          <Typography className={classes.sub}>
          Once an order is placed with Our company, it goes through an internal processing phase. This includes verifying the order details, checking product availability, and preparing the package for shipment. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordion} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.titel}>What Time Max Shippment Takes ?</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel2' ? classes.expanded : ''}>
          <Typography className={classes.sub}>
          Our companies typically have an internal order processing period. This is the time it takes for our company to verify the order details, check product availability, and prepare the package for shipment. The length of this processing time can vary and may depend on factors such as order volume, product complexity, and internal procedures.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.titel}>How Much Price ?</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel3' ? classes.expanded : ''}>
          <Typography className={classes.sub}>
          The pricing of pharmaceutical products in pharmaceutical companies is influenced by several factors. The cost of research and development, manufacturing expenses, intellectual property rights, regulatory requirements, market competition, value and reimbursement considerations, and pricing regulations all play a role in determining the pricing of pharmaceutical products.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* <Accordion className={classes.accordion} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.titel}>Can Shipped To Other City ?</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel4' ? classes.expanded : ''}>
          <Typography className={classes.sub}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
          </Typography>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
}

export default MyAccordion;
