import React, {useState ,useEffect} from 'react';
import './getin.css';


import { useInView } from 'react-intersection-observer';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { FaLocationCrosshairs } from "react-icons/fa6";
import { IoPersonSharp } from "react-icons/io5";
import { IoCall } from "react-icons/io5";





import { useNavigate  } from 'react-router-dom';

import axios from 'axios';





export default function Getin() {




  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);

  const [getin,setGetin] = useState([]);

  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/getinCrate/')  
          .then((response) => {
            console.log(response.data); 
            setGetin(response.data);
            
          })
          .catch((error) => {
            console.error('Error fetching blog recentBlog:', error);
           
          });
  
  }, []);
  
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);

  const navigate = useNavigate();

  return (
    <>

<div className="news_detail_div" style={{ 
     background: `url(${require('../../../assets/get2.jpg') })`, // replace './path_to_your_image.jpg' with the actual path to your image
     backgroundSize: 'cover',
     padding: '15px' ,animation: 'fadeIn 5s'}} >
      <div  className={isMobile ? "yellow_career_detail_cover-mobile" : "yellow_career_detail_cover_get"}/>
         <div className="Group199" style={{ height: "305px", position: 'relative' ,justifyContent:'center'}}>
          
           <div  className={isMobile ? "carrer_detail_titel-mobile" : "carrer_detail_titel"} style={{animation: 'fadeIn 5s'}}>Get In Touch 
</div>
          
 
        </div>


</div>


  

  


  
<div  style={{position:'relative',    background: '#F9F7F5'}}>


          <div  className={` ${isMobile ? "job_detailstitel-mobile" : (isDesktop ? "job_detailstitel-desktop" : "")}`}>
        
         </div>

<div className="shadow-lg rounded-lg bg-white mx-auto w-11/12 mt-4">
    
    <>
    <div className="flex flex-wrap justify-center ">
      {getin.map((getin) => (
        <div key={getin.id} className=" lg:w-1/4 p-4">
            <div className={` ${isMobile ? "job_sub_cards-mobile" : (isDesktop ? "job_sub_cards_2-desktop" : "")}`}>
      <div className={` ${isMobile ? "mas_2-mobile" : (isDesktop ? "mas_2-desktop" : "")}`}>
   
          <div style={{padding:'0px 0px 20px 20px',position:'relative' }}>
            <h2>
              <a style={{textAlign:'left',margin:'0px 0px 10px 0px' ,fontSize:'20px',lineHeight:'1.4em',letterSpacing:'-0.15px', fontFamily:'Neuwelt Black'}}>{getin.title}</a>
            </h2>
            <div className={` ${isMobile ? "mas_date_ico-mobile" : (isDesktop ? "mas_date_ico-desktop" : "")}`}>
            <div className={` ${isMobile ? "mas_date_ico_sub-mobile" : (isDesktop ? "mas_date_ico_sub_2-desktop" : "")}`}>
              <div  className={` ${isMobile ? "mas_date-mobile" : (isDesktop ? "mas_date-desktop" : "")}`}>
    <h5 className={` ${isMobile ? "mas_cata-mobile" : (isDesktop ? "mas_cata-desktop" : "")}`}>
    <IoPersonSharp
className={isMobile ? "mas_date_ico-mobile" : "mas_date_ico"}
  />
      <p className={` ${isMobile ? "mas_date_tex-mobile" : (isDesktop ? "mas_date_tex-desktop" : "")}`} style={{color:'#000',fontWeight:'400',fontFamily:'Neuwelt'}}>{getin.name}</p>
    </h5>
  </div>
  <div className={` ${isMobile ? "mas_date-mobile" : (isDesktop ? "mas_date-desktop" : "")}`}>
    <h5 className={` ${isMobile ? "mas_cata-mobile" : (isDesktop ? "mas_cata-desktop" : "")}`}>
    <FaLocationCrosshairs 
   className={isMobile ? "mas_date_ico_2-mobile" : "mas_date_ico_2"}
  />
      <p className={` ${isMobile ? "mas_date_tex-mobile" : (isDesktop ? "mas_date_tex-desktop" : "")}`}  style={{color:'#000',fontWeight:'400',fontFamily:'Neuwelt'}}>{getin.location}</p>
    </h5>
  </div>
  <div className={` ${isMobile ? "mas_date-mobile" : (isDesktop ? "mas_date-desktop" : "")}`}>
    <h5 className={` ${isMobile ? "mas_cata-mobile" : (isDesktop ? "mas_cata-desktop" : "")}`}>
    <IoCall  
   className={isMobile ? "mas_date_ico_2-mobile" : "mas_date_ico_2"}
  />
      <p className={` ${isMobile ? "mas_date_tex-mobile" : (isDesktop ? "mas_date_tex-desktop" : "")}`}  style={{color:'#000',fontWeight:'400',fontFamily:'Neuwelt'}}>{getin.phone}</p>
    </h5>
  </div>

              </div>
        
            </div>
            <div className={` ${isMobile ? "mas_date-mobile" : (isDesktop ? "mas_date-desktop" : "")}`}>
           
      
                <p className={` ${isMobile ? "mas_date_tex-mobile" : (isDesktop ? "mas_date_tex_2_2-desktop" : "")}`}  style={{color:'#000',fontWeight:'400',fontFamily:'Neuwelt'}}>{getin.email}</p>
             
            </div>
          </div>
      </div>
  </div>
        </div>
      ))}
  
    </div>
    </>
    <div  className={` ${isMobile ? "row-last-mobile" : (isDesktop ? "row-last-desktop" : "")}`}>
          <div className="row row-cols-1 row-cols-md-3  p-3 text-white justify-items-center ">
            
            <div className="col" style={{paddingLeft:'100px', color:''}}>
              <h4>Headoffice</h4>
              <div
                className={` ${isMobile ? "Contactus1img-mobile" : (isDesktop ? "Contactus1img-desktop" : "")}`}
                style={{zIndex:1}}
                  dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.2031048685576!2d38.72894747488421!3d9.045229591016739!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8573b3f6f5e9%3A0x41395cb651598266!2sDroga%20Pharma%20PLC!5e0!3m2!1sen!2set!4v1689142706099!5m2!1sen!2set" width="130" height="100" style="border:10;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"> </iframe>`
                    
                  }}
      />
      <p>Addis Ababa,Ethiopia</p>
      <p>+25111 273 4554<br/>+251 91 366 7537</p>
      <p>info@drogapharma.com<br/>pharmadroga@gmail.com<br/>Gulele Subcity ,Woreda 09 </p>
            </div>
            <div className="col" style={{paddingLeft:'100px', color:''}}>
              <h4>Wholesale Branch</h4>
              <div
                className={` ${isMobile ? "Contactus2img-mobile" : (isDesktop ? "Contactus2img-desktop" : "")}`}
                style={{zIndex:1}}
                  dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.258887400548!2d38.72933767488422!3d9.040132791021657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b89d832ce80e3%3A0x724d9d5aaea9ac69!2sDROGA%20PHARMA%20WHOLESALE!5e0!3m2!1sen!2set!4v1689144597570!5m2!1sen!2set" width="130" height="100" style="border:10;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`}}
                />
              <p>Addis Ababa,Ethiopia </p>
              <p>+25111 273 4554<br/>+251 91 366 7537</p>
              <p>info@drogapharma.com<br/>pharmadroga@gmail.com<br/>Gulele Subcity ,Woreda 09</p>



            </div>
            <div className="col" style={{paddingLeft:'100px' , color:''}}>
              <h4>Somaliland Branch</h4>
              <div
      className={` ${isMobile ? "Contactus3img-mobile" : (isDesktop ? "Contactus3img-desktop" : "")}`}
       style={{zIndex:1}}
        dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3934.36349235798!2d44.0689026!3d9.563900799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1628bf4bf60fb691%3A0x6ee669887e3c89e3!2sDroga%20Pharma%20Somaliland%20PLC!5e0!3m2!1sen!2set!4v1689144939394!5m2!1sen!2set" width="130" height="100" scrolling="auto" style="border:10; "  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`
          
        }}
      />
              <p>Somaleland</p>
              <p>+25111 273 4554<br/>+251 91 366 7537</p>
              <p>info@drogapharma.com<br/>pharmadroga@gmail.com<br/>Gulele Subcity ,Woreda 09 </p>
            </div>
          </div>
        </div>
    </div>

</div>
<div  className={isMobile ? "Group200-mobile" : "Group200"} >

<div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

   {/* <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/> */}
   
   <div  className={` ${isMobile ? "footer_Sub_txt_5-mobile" : (isDesktop ? "footer_Sub_txt_5-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_5_5-mobile" : (isDesktop ? "footer_Sub_txt_5_5-desktop" : "")}`}>
    
          Ethiopia
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_5-mobile" : (isDesktop ? "footer_Sub_para_5-desktop" : "")}`}> 
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
 
 

     
   <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


<div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
    Somaliland
</div>
<div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
<div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />Hargeysa,Somaliland 
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />+252634958444

    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
        Quick Links
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
    <div   className="footer_sub_txt_3">
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  /> <a href='/' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Home</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />  <a  onClick={() => { navigate('/aboutus');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}> About Us</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  /> <a  onClick={() => { navigate('/get');  }}  style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Contact Us</a>
    </div>

       </div>
   </div>
   


 
</div>
   </div>
<div className="footer_right_hm">

<div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

</div>
    </>
    
  );
}
