import React, { useState } from 'react';
import './Productdetail.css';
import MyAccordion from './Accordions'
import proimg10 from '../../../assets/proimg10.jpg';
import pngegg_1 from '../../../assets/pngegg_1.png';
import pngegg from '../../../assets/pngegg.png';
import proimg4 from '../../../assets/proimg4.png';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import dro from '../../../assets/dro.png';





export default function Productdetail() {


  const [activeSection, setActiveSection] = useState('description');

  // Function to handle clicking on the description section
  const handleDescriptionClick = () => {
    setActiveSection('description');
  };

  // Function to handle clicking on the details section
  const handleDetailsClick = () => {
    setActiveSection('details');
  };
  

  return (
    <>


    
<div className="news_detail_div" style={{ 
     background: `url(${require('../../../assets/e001.jpg') })`, // replace './path_to_your_image.jpg' with the actual path to your image
     backgroundSize: 'cover',
     padding: '15px' ,animation: 'fadeIn 5s'}} >
      <div className="yellow_news_detail_cover"/>
         <div className="Group199" style={{ height: "305px", position: 'relative' ,justifyContent:'center'}}>
          
           <div className="News_detail_titel" style={{animation: 'fadeIn 5s'}}>Product Details  
</div>
          
 
        </div>


</div>

<div className="newsdetails">



<div style={{ display: 'flex', width: '80vw', paddingTop: '50px' }}>
  <div className="newdetailcard">
    <div
      className="Rectangle94"
      style={{
        width: 470,
        height: 360,
        background: 'white',
        borderRadius: 10,
      }}
    >
      <img src={pngegg} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
    </div>
    </div>

  <div className="newdetail_txt">
  <div className="newdetailtitel_1">Aspirin</div>
        <div>
          <button className={`bubbly-button proDetails_1 ${activeSection === 'description' ? 'active' : ''}`} onClick={handleDescriptionClick}>Description</button>
          <button className={`bubbly-button proDetails_0 ${activeSection === 'details' ? 'active' : ''}`} onClick={handleDetailsClick}>Details</button>
        </div>
        <div>
          {activeSection === 'description' && (
            <div>
    
              <p className="newdetailtxt_2" >Description content goes here It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
            
              
            </div>
          )}
          {activeSection === 'details' && (
            <div>
            
              <p className="newdetailtxt_2" >Details Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

            
            </div>
          )}
        </div>
      </div>
</div>
</div>

  



<div className="Relatednews" style={{ 
      background: '#F9F7F1',
      padding: '5% 10px 15% 50px' }} >
   
   <div className="Relatednewss_titel" >Related Product</div>

    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',marginTop:"50px",gap:'50px'}}>
<div className="news_detail_crd_new" >
<div className="gr_detail_card_new"  >
<img  className='news_detail_image' src={proimg10} />  
  <div className='covercardtext_news_detail'> 
    <p style={{ fontSize: 20,fontWeight:'Bold',lineHeight:'1.5',fontFamily:'Neuwelt ExtraLight'}}>Recognition Certificate from EPSS</p>
    <p style={{ fontSize: 15,display: 'flex',textAlign:'justify',lineHeight:'1.5',fontFamily:'Neuwelt ExtraLight'}}>We are pleased to announce you that Droga Pharma Plc has received this certificate of recognition for being the Best Pharmaceutical Products Supplier specially orthopedics during the internal conflict in Ethiopia,as a result of its ongoing strive for excellence in the Pharmaceutical Industry.</p>
    <div className="new_moreButton_Area">
        <button onClick={() => { alert('More button clicked!'); }}  className="new_moreBtn">
            Details
        </button>
    </div>
  </div>
</div>
  
</div>
     
<div className="news_detail_crd_new" >
<div className="gr_detail_card_new"  >
<img  className='news_detail_image' src={pngegg_1} />  
  <div className='covercardtext_news_detail'> 
    <p style={{ fontSize: 20,fontWeight:'Bold',lineHeight:'1.5',fontFamily:'Neuwelt ExtraLight'}}>Lorem Ipsum</p>
    <p style={{ fontSize: 15,display: 'flex',textAlign:'justify',lineHeight:'1.5',fontFamily:'Neuwelt ExtraLight'}}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humoursimply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard simply dummy text of the printing and typesetting industry.  </p>
    <div className="new_moreButton_Area">
        <button onClick={() => { alert('More button clicked!'); }}  className="new_moreBtn">
            Details
        </button>
    </div>
  </div>
</div>
  
</div>


<div className="newscrd_new" >
<div className="gr_detail_card_new"  >
<img  className='news_image' src={proimg4} />  
  <div className='covercardtext_news_detail'> 
    <p style={{ fontSize: 20,fontWeight:'Bold',lineHeight:'1.5',fontFamily:'Neuwelt ExtraLight'}}>Lorem Ipsum</p>
    <p style={{ fontSize: 15,display: 'flex',textAlign:'justify',lineHeight:'1.5',fontFamily:'Neuwelt ExtraLight'}}>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humoursimply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard </p>
  
    <div className="new_moreButton_Area">
        <button onClick={() => { alert('More button clicked!'); }}  className="new_moreBtn">
            Details
        </button>
    </div>
  </div>
</div>
  
</div>





</div>
</div>

    <div style={{padding:'100px'}}>
      <MyAccordion />
    </div>

<div className="Group200" style={{background: '#fff200',height: 425, position: 'relative'}}>

<div className="news_detail_footersub"  >
 

   <img className='news_detail_fotter_icon' src={dro} />

 
 
   <div className="news_detail_footerSub_txt1" >


    <div className='news_detail_footerSub_txt11'>
    
          Ethiopia
    </div>
    <div className='news_detail_footerSub_para'> 
    <div  className="news_detail_footersubtxt3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 5,
      color: '#000',
      fontSize:'20px',
      marginTop: '20px',
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="news_detail_footersubtxt3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 5,
      color: '#000',
      fontSize:'20px',
      marginTop: '20px',
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="news_detail_footersubtxt3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 5,
      color: '#000',
      fontSize:'20px',
      marginTop: '20px',
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
   <div className="news_detail_footerSub_txt2" >


<div className='news_detail_footerSub_txt22'>
    Somaliland
</div>
<div className='news_detail_footerSub_para2'> 
<div  className="news_detail_footersubtxt3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 5,
      color: '#000',
      fontSize:'20px',
      marginTop: '20px',
    }}
  />Hargeysa , Somaliland
    </div>
    <div  className="news_detail_footersubtxt3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 5,
      color: '#000',
      fontSize:'20px',
      marginTop: '20px',
    }}
  />+2512634958444/+2512637611171
    </div>
    <div  className="news_detail_footersubtxt3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 5,
      color: '#000',
      fontSize:'20px',
      marginTop: '20px',
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div className="news_detail_footerSub_txt3" >


    <div className='news_detail_footerSub_txt33'>
        
        Quick Links
    </div>
    <div className='news_detail_footerSub_para3'> 
    <div  className="news_detail_footersubtxt3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 5,
      color: '#000',
      fontSize:'20px',
      marginTop: '20px',
    }}
  />Home
    </div>
    <div  className="news_detail_footersubtxt3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 5,
      color: '#000',
      fontSize:'20px',
      marginTop: '20px',
    }}
  />About Us
    </div>
    <div  className="news_detail_footersubtxt3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 5,
      color: '#000',
      fontSize:'20px',
      marginTop: '20px',
    }}
  />Contact Us
    </div>

       </div>
   </div>
   


 
</div>
   </div>
   <div className="news_detail_footer_right">

<div className='news_detail_footerRight_txt'>© 2023 - Droga Pharma PLC</div>

</div>

    </>
    
  );
}
