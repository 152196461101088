import React, { useState, useEffect } from 'react';
import './RD.css';
import ProgressBar from './ProgressiveBar';
import MyAccordion from './Accordions'

import {BsFillCheckCircleFill} from "react-icons/bs";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import dro from '../../../assets/dro.png';
import RandD5 from '../../../assets/RandD5.jpg';
import { Rotate90DegreesCcw } from '@mui/icons-material';


import { useNavigate  } from 'react-router-dom';



export default function RD() {


  const [activeSection, setActiveSection] = useState('description');

  // Function to handle clicking on the description section
  const handleDescriptionClick = () => {
    setActiveSection('description');
  };

  // Function to handle clicking on the details section
  const handleDetailsClick = () => {
    setActiveSection('details');
  };
  
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCompleted((oldValue) => {
        if (oldValue >= 70) {
          clearInterval(timer);
          return 70;
        }

        return oldValue + 1;
      });
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, []);
  const [completed1, setCompleted1] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCompleted1((oldValue) => {
        if (oldValue >= 90) {
          clearInterval(timer);
          return 90;
        }

        return oldValue + 1;
      });
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);
  
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);

  const navigate = useNavigate();

  
  return (
    <>


    
<div  className={` ${isMobile ? "RD_detail_div-mobile" : (isDesktop ? "RD_detail_div-desktop" : "")}`} style={{ 
     background: `url(${require('../../../assets/RandD31.jpg') })`, // replace './path_to_your_image.jpg' with the actual path to your image
     backgroundSize: 'cover'
      ,animation: 'fadeIn 5s'}} >
      <div   className={` ${isMobile ? "yellow_RD_detail_cover-mobile" : (isDesktop ? "yellow_RD_detail_cover_rd-desktop" : "")}`}/>
         <div  className={` ${isMobile ? "Group199_rd-mobile" : (isDesktop ? "Group199_rd-desktop" : "")}`}>
          
           <div   className={` ${isMobile ? "RD_detail_titel-mobile" : (isDesktop ? "RD_detail_titel-desktop" : "")}`}>Research And Development 
</div>
          
 
        </div>


</div>

<div   className={` ${isMobile ? "rd_cov-mobile" : (isDesktop ? "rd_cov-desktop" : "")}`} style={{animation: 'fadeIn 5s',position:'relative'}} >
   

   <div  className={` ${isMobile ? "rd_all-mobile" : (isDesktop ? "rd_all-desktop" : "")}`}>

      
   <div className={` ${isMobile ? "rd_desc-mobile" : (isDesktop ? "rd_desc-desktop" : "")}`}>


<div  className={` ${isMobile ? "rd_tiall-mobile" : (isDesktop ? "rd_tiall-desktop" : "")}`}>

<div    className={` ${isMobile ? "rd_tit2-mobile" : (isDesktop ? "rd_tit2-desktop" : "")}`}>
We Delivered <strong  className={` ${isMobile ? "rd_tit_subb-mobile" : (isDesktop ? "rd_tit_subb-desktop" : "")}`}>Research Primary Services</strong>
</div>
 <div  className={` ${isMobile ? "rd_subtit-mobile" : (isDesktop ? "rd_subtit-desktop" : "")}`}>
      <br/><p className={` ${isMobile ? "rd_subtit_p-mobile" : (isDesktop ? "rd_subtit_p-desktop" : "")}`} >Our Research and Development (R&D) Sector is focuses on innovating and creating new products, services, or processes. The primary goal  is to contribute to the advancement of scientific and technological knowledge, solve problems, and develop technologies that lead to new products, services, or ways of doing things.</p>
 </div>
 
 <div   className={` ${isMobile ? "rd_bullet-mobile" : (isDesktop ? "rd_bullet-desktop" : "")}`}>
 <div className={` ${isMobile ? "rd_txt_2-mobile" : (isDesktop ? "rd_txt_2-desktop" : "")}`}>
 <BsFillCheckCircleFill style={{fontSize: '20px', marginRight:'2%',color:'#fff200'}}/>
          <div  className={` ${isMobile ? "rd_txt_tit_2-mobile" : (isDesktop ? "rd_txt_tit_2-desktop" : "")}`}>
          Research Center
          <div  className="rd_sub1" >
         Dedicated to carrying out investigations in a particular field or area of study.
           </div>
          </div>
          
  </div>

  <div className="rd_txt_2" >
 <BsFillCheckCircleFill style={{fontSize: '20px', marginRight:'2%',color:'#fff200'}}/>
          <div style={{fontFamily:'Neuwelt',width:'50%',textAlign:'justify'}}>
          Lab Technicians
          <div  className="rd_sub1" >
          professionals involved in various aspects of ensuring the accuracy and efficiency of the tests conducted.
           </div>
          </div>
          
  </div>
 </div>

  <div  className={` ${isMobile ? "rd_style-mobile" : (isDesktop ? "rd_style-desktop" : "")}`}>
        <div   className={` ${isMobile ? "rd_style_2-mobile" : (isDesktop ? "rd_style_2-desktop" : "")}`}>
          <h2 className={` ${isMobile ? "rd_style_txt-mobile" : (isDesktop ? "rd_style_txt-desktop" : "")}`}>R&D</h2>
        </div>
       </div>
  {/* <div  className={` ${isMobile ? "rd_txt3-mobile" : (isDesktop ? "rd_txt3-desktop" : "")}`}>

          <div style={{fontFamily:'Neuwelt',width:'95%',textAlign:'justify'}}>
          VACCINES
          <div className="App" >
            <ProgressBar bgcolor={"#fff200"} completed={completed} />
          </div>
          </div>
          <div style={{fontFamily:'Neuwelt',width:'95%',textAlign:'justify', marginRight:'0px'}}>
          MEDICAL RESEARCH
          <div className="App">
            <ProgressBar bgcolor={"#fff200"} completed={completed1} />
          </div>
          </div>
  </div> */}
 
     
</div>
      
  
</div>
{/* <div>
<img   src={RandD5} className={` ${isMobile ? "rd_img-mobile" : (isDesktop ? "rd_img-desktop" : "")}`}/>

  </div> */}
 
  </div> 

</div>

<div   className={` ${isMobile ? "RD_details-mobile" : (isDesktop ? "RD_details-desktop" : "")}`}>

  <div  className={` ${isMobile ? "RD_detail_txt-mobile" : (isDesktop ? "RD_detail_txt-desktop" : "")}`}>
  <div  className={` ${isMobile ? "RD_detailtitel_1-mobile" : (isDesktop ? "RD_detailtitel_1-desktop" : "")}`}>Droga R&D</div>
        

            <div>
            
              <p  className={` ${isMobile ? "RD_detailtxt_2-mobile" : (isDesktop ? "RD_detailtxt_2-desktop" : "")}`}>The research and development departments of Droga pharma was established in 2021 G.C with the aim of providing a state of the art research facility. The unit is currently focused on development and research on two wings: Pharmaceuticals and Nutraceuticals /Food supplements. The unit has experienced professionals providing services and conducting research and development both for the company and for pharmaceutical companies all over the globe.</p>

            
            </div>
     
      
      </div>

</div>

  
    <div  className={` ${isMobile ? "RD_accordion-mobile" : (isDesktop ? "RD_accordion-desktop" : "")}`}>
      <MyAccordion />
    </div>

    <div  className={isMobile ? "Group200-mobile" : "Group200"} >

<div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

   {/* <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/> */}
   
   <div  className={` ${isMobile ? "footer_Sub_txt_5-mobile" : (isDesktop ? "footer_Sub_txt_5-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_5_5-mobile" : (isDesktop ? "footer_Sub_txt_5_5-desktop" : "")}`}>
    
          Ethiopia
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_5-mobile" : (isDesktop ? "footer_Sub_para_5-desktop" : "")}`}> 
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
 
 

     
   <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


<div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
    Somaliland
</div>
<div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
<div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />Hargeysa,Somaliland 
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />+252634958444

    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
        Quick Links
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
    <div   className="footer_sub_txt_3">
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  /> <a href='/' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Home</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />  <a onClick={() => { navigate('/aboutus');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}> About Us</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  /> <a onClick={() => { navigate('/get');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Contact Us</a>
    </div>

       </div>
   </div>
   


 
</div>
   </div>
<div className="footer_right_hm">

<div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

</div>

    </>
    
  );
}
