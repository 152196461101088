import React, {useState ,useEffect} from 'react';
import './orto.css';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import {BsArrowRightCircleFill} from "react-icons/bs";
import { Typography } from '@mui/material';
import MyAccordion from './Accordions';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom';



export default function Ortoh() {



  const [orthopedics, setOrthopedics] = useState();
  const [Orto, setOrto] = useState();



  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/ortophedics/')  
          .then((response) => {
            console.log(response.data[0]); 
            setOrthopedics(response.data);
         
            
          })
          .catch((error) => {
            console.error('Error fetching blog productMed:', error);
           
          });
  
  }, []);


  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/ortocreate/')  
          .then((response) => {
            console.log(response.data[0]); 
            setOrto(response.data);
         
            
          })
          .catch((error) => {
            console.error('Error fetching blog productMed:', error);
           
          });
  
  }, []);


  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);
  
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);

  const navigate = useNavigate();
  return (
    <>


<div className="news_detail_div" style={{ 
     background: `url(${require('../../../assets/mdim3.jpg') })`, // replace './path_to_your_image.jpg' with the actual path to your image
     backgroundSize: 'cover',
     padding: '15px' ,animation: 'fadeIn 5s'}} >
      <div className={` ${isMobile ? "yellow_pro_detail_cover-mobile" : (isDesktop ? "yellow_pro_detail_cover-desktop" : "")}`}/>
         <div className="Group199_medi" >
          
           <div   style={{animation: 'fadeIn 5s'}} className={` ${isMobile ? "pro_medi_titel-mobile" : (isDesktop ? "pro_medi_titel-desktop" : "")}`}>Orthopedics Instruments
</div>
          
 
        </div>


</div>


<div   className={` ${isMobile ? "Produc_medi-mobile" : (isDesktop ? "Produc_medi-desktop" : "")}`} style={{position:'relative'}}>
    <div  className={` ${isMobile ? "med_dev_all-mobile" : (isDesktop ? "med_dev_all-desktop" : "")}`}>
  

<div  className={` ${isMobile ? "med_dev_sub-mobile" : (isDesktop ? "med_dev_sub-desktop" : "")}`}>
{orthopedics && orthopedics.map((orthopedics) => {
  return(
    <div>
<div   className={` ${isMobile ? "med_detailcard-mobile" : (isDesktop ? "med_detailcard-desktop" : "")}`} style={{animation: 'fadeIn 5s'}}>


<img   className={` ${isMobile ? "produc_img-mobile" : (isDesktop ? "produc_img-desktop" : "")}`} src={orthopedics.image} />



</div>
<div   className={` ${isMobile ? "produc_txt_medi-mobile" : (isDesktop ? "produc_txt_medi-desktop" : "")}`}>

    <h2   className={` ${isMobile ? "med_detail_titel-mobile" : (isDesktop ? "med_detail_titel-desktop" : "")}`}>{orthopedics.title}</h2>
    <p  className={` ${isMobile ? "medi_detailtxt_2-mobile" : (isDesktop ? "medi_detailtxt_2-desktop" : "")}`}>{orthopedics.content}</p>
    


</div>
</div>
  )
})}
<div   className={` ${isMobile ? "medicin_dev_accor-mobile" : (isDesktop ? "medicin_dev_accor_oo-desktop" : "")}`}>
      <MyAccordion />
</div>

</div> 


<div  className={` ${isMobile ? "medicin_dev_card-mobile" : (isDesktop ? "medicin_dev_card-desktop" : "")}`}>
   

<div  className={` ${isMobile ? "medicin_dev_card2-mobile" : (isDesktop ? "medicin_dev_card2-desktop" : "")}`}>

<div>
    <Typography   variant="h5" className={` ${isMobile ? "med_dev_tit-mobile" : (isDesktop ? "med_dev_tit-desktop" : "")}`}>
      Orthopedics Instruments
    </Typography>
{Orto && Orto.map((Orto) => {
  return(
  <div  className={` ${isMobile ? "medical_dev_post-mobile" : (isDesktop ? "medical_dev_post-desktop" : "")}`}>

<div   className={` ${isMobile ? "med_devi_blog-mobile" : (isDesktop ? "med_devi_blog-desktop" : "")}`}>
<BsArrowRightCircleFill
   className={` ${isMobile ? "med_devi_icon-mobile" : (isDesktop ? "med_devi_icon-desktop" : "")}`}
/>
<div  className={` ${isMobile ? "med_devi_content-mobile" : (isDesktop ? "med_devi_content-desktop" : "")}`}>


  <div className={` ${isMobile ? "post_content-mobile" : (isDesktop ? "post_content-desktop" : "")}`}>
  <h5>

  <a>{Orto.title}</a>
  </h5>

</div>

<div style={{paddingLeft:'20px'}}>
    <a className='medical_dev_detail_image' >
        <img   className={` ${isMobile ? "medical_device_image_sub-mobile" : (isDesktop ? "medical_device_image_sub-desktop" : "")}`} src={Orto.image}/>
      </a>
</div>
</div>
</div>

  </div>
   
   );
  })}
</div>
</div>
  </div>
    </div>




    

  
</div>
<div  className={isMobile ? "Group200-mobile" : "Group200"} >

<div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

   {/* <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/> */}
   
   <div  className={` ${isMobile ? "footer_Sub_txt_5-mobile" : (isDesktop ? "footer_Sub_txt_5-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_5_5-mobile" : (isDesktop ? "footer_Sub_txt_5_5-desktop" : "")}`}>
    
          Ethiopia
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_5-mobile" : (isDesktop ? "footer_Sub_para_5-desktop" : "")}`}> 
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
 
 

     
   <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


<div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
    Somaliland
</div>
<div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
<div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />Hargeysa,Somaliland 
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />+252634958444

    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
        Quick Links
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
    <div   className="footer_sub_txt_3">
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  /> <a href='/' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Home</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />  <a onClick={() => { navigate('/aboutus');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}> About Us</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  /> <a onClick={() => { navigate('/get');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Contact Us</a>
    </div>

       </div>
   </div>
   


 
</div>
   </div>
<div className="footer_right_hm">

<div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

</div>
    </>
    
  );
}
