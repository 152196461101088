import React from 'react';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './Aboutus.css';
import partener17 from '../../../assets/partener17.jpg';
import partener16 from '../../../assets/partener16.png';
import partener15 from '../../../assets/partener15.png';
import partener14 from '../../../assets/partener14.png';
import partener18 from '../../../assets/partener18.png';
import partener13 from '../../../assets/partener13.jpg';

import partener12 from '../../../assets/partener12.png';
import partener9 from '../../../assets/partener9.png';
import partener8 from '../../../assets/partener8.png';
import partener11 from '../../../assets/partener11.png';



import partener19 from '../../../assets/partener19.png';
import { useNavigate  } from 'react-router-dom';

import ema from '../../../assets/ema.png';
import Droga from '../../../assets/Droga.png';
import aboutcover2 from '../../../assets/aboutcover2.jpg';
import Trust1 from '../../../assets/Trust1.png';
import aboutcover3 from '../../../assets/aboutcover3.jpg';
import dro from '../../../assets/dro.png';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';




import {SiTarget} from "react-icons/si";
import {FaHandshakeSimple} from "react-icons/fa6";
import {IoIosPeople} from "react-icons/io";
import BackHandIcon from '@mui/icons-material/BackHand';
import {FaHandsHoldingChild} from "react-icons/fa6";
import {MdVisibility} from "react-icons/md";

import { useInView } from 'react-intersection-observer';
import { useEffect,useState } from "react";


export default function Aboutus() {

const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation once
    threshold: 0.1  , // Percentage of the component's visibility for the animation to trigger
  });

  const variants = {
    hidden: { x: '50vw' }, // Starts from the left side outside the viewport
    show: {
      x: 0, // Ends at the original position
      transition: {
        duration: 0.8, // Animation duration in seconds
        ease: 'easeOut' // Easing function for the animation
      }
    }
  };

  const cover = [aboutcover2,aboutcover3];
  const initialIndex = 0; 
  const images= [ema, Trust1,
    Droga,ema, 
    Trust1,Droga,ema,Trust1,Droga,ema,Trust1];
  
    useEffect(() => {
      const handler = e => setIsMobile(e.matches);
      window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
    }, []);
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
    
    useEffect(() => {
      const handler = e => setIsDesktop(e.matches);
      window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
    }, []);
    
    const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);

    const navigate = useNavigate();
  return (
    <>

<div className="Aboutus" style={{ 
     background: `url(${require('../../../assets/Boso2.png') })`, // replace './path_to_your_image.jpg' with the actual path to your image
     backgroundSize: 'cover',
     padding: '10px' ,animation: 'fadeIn 5s'}} >
      <div className="yellow_aboutcover"/>
         <div className="Group199" style={{ height: 300, position: 'relative' ,justifyContent:'center'}}>
          
           <div  className={isMobile ? "Aboutustitel-mobile" : "Aboutustitel"}  style={{animation: 'fadeIn 5s'}}>About Us</div>
          
 
        </div>


</div>





  <div className="Aboutus" style={{ position: 'relative'}} >
   
  <div className={isMobile ? "Aboutussubtitle-mobile" : "Aboutussubtitle"} style={{animation: 'slideInDown 2s'}}>
            Welcome to Droga Pharma PLC  
  </div>
<div  className={` ${isMobile ? "Aboutus_all-mobile" : (isDesktop ? "Aboutus_all-desktop" : "")}`} >

<div className={` ${isMobile ? "Aboutus_img_txt-mobile" : (isDesktop ? "Aboutus_img_txt-desktop" : "")}`}>
    <img   className={` ${isMobile ? "Image21-mobile" : (isDesktop ? "Image21-desktop" : "")}`} src={aboutcover3} />
    <div    className={` ${isMobile ? "aboutdesc-mobile" : (isDesktop ? "aboutdesc-desktop" : "")}`}>
       
        Droga Pharma Pvt.Ltd.Co was established in April 2015 by a group of healthcare professionals, who have rich business experience in the pharmaceutical, Research, Manufacturing sector. We are working in the pharmaceuticals import, wholesale and retail business, targeting the public as well as the private health sector of Ethiopia. Apart from the import business, our wholesale division is also working to serve the health care facility and organizations that need pharmaceuticals and healthcare products. 
    </div>
</div>

<div  className={` ${isMobile ? "Aboutus_img_txt_2-mobile" : (isDesktop ? "Aboutus_img_txt_2-desktop" : "")}`}>
    <img  className={` ${isMobile ? "Image21-mobile" : (isDesktop ? "Image21-desktop" : "")}`} src={aboutcover2}  />
    <div   className={` ${isMobile ? "aboutdesc_2-mobile" : (isDesktop ? "aboutdesc_2-desktop" : "")}`}>
        Droga Pharma PLC staffed with a highly qualified and dedicated 500+ employee. We believe in our staff and our commitment to benefit our customers; that is what builds our company. Our annual sales is more than 25 million USD for the private market and also we won more than 60 Million USD in government tenders through our partner manufacturer.
    </div>
</div>

</div> 
  </div>



  



<div  className={` ${isMobile ? "Mission-mobile" : (isDesktop ? "Mission-desktop" : "")}`} style={{
      background: `url(${require('../../../assets/aboutcover5.jpg') })` ,
      backgroundSize:'cover',
     }} >
   

         <div  className={` ${isMobile ? "Group19_9-mobile" : (isDesktop ? "Group19_9-desktop" : "")}`}>
         <div   className={` ${isMobile ? "yellow_aboutcover2-mobile" : (isDesktop ? "yellow_aboutcover2-desktop" : "")}`}/> 
  <div  className={isMobile ? "MissionVision-mobile" : "MissionVision"}>Mission & Vision</div>
  <div  className={` ${isMobile ? "Group198-mobile" : (isDesktop ? "Group198-desktop" : "")}`}>
    <div  className={isMobile ? "Group157-mobile" : "Group157"}>
    <MdVisibility  className={isMobile ? "footericons-mobile" : "footericons"}/>
      <div className={isMobile ? "Vision_sub-mobile" : "Vision_sub"}>Vision</div>
    </div>
    <div  className={isMobile ? "ToBeTh-mobile" : "ToBeTh"}>To be the leading group company in Ethiopia that creates health and wealth for human being.</div>
  </div>
  <div  className={` ${isMobile ? "Group197-mobile" : (isDesktop ? "Group197-desktop" : "")}`}>
    <div className={isMobile ? "Group156-mobile" : "Group156"} >
    <SiTarget className={isMobile ? "footericons-mobile" : "footericons_2"}/>
      <div  className={isMobile ? "Mission_sub-mobile" : "Mission_sub"} >Mission</div>
    </div>
    <div  className={isMobile ? "WeBuildEtn-mobile" : "WeBuildEtn"} >We build ethical companies that provide quality products and services by our talented and motivated members to serve humanity and contribute to the socio economic development of the nation.</div>
  </div>
  
      </div>


</div>




<div  className={isMobile ? "Ourvalues-mobile" : "Ourvalues"} >
    
    <div className={isMobile ? "value_tit-mobile" : "value_tit"} >
          <div className={isMobile ? "value_tit_sub-mobile" : "value_tit_sub"}>Our Values</div>
    </div>
    
<div className={isMobile ? "value_cont_1-mobile" : "value_cont_1"}>

    <div className={isMobile ? "humanity-mobile" : "humanity"}  >
    <FaHandsHoldingChild className={isMobile ? "value_icon-mobile" : "value_icon"}  />
  <div  className={` ${isMobile ? "humanitytitel-mobile" : (isDesktop ? "humanitytitel-desktop" : "")}`}>Humanity</div>
  <div  className={isMobile ? "humanitytxt-mobile" : "humanitytxt"} >
						We operate in the industry where human live is crucial. In each and every steps of our service we give special attention on the quality of our products that will be used in the diagnosis and treatment of the human life. Outmost in our company we respect human being irrespective of any status and identity.</div>
 
     </div>
     

     <div  className={isMobile ? "integrity-mobile" : "integrity"} >
     <BackHandIcon  className={` ${isMobile ? "value_icon_2-mobile" : (isDesktop ? "value_icon_2-desktop" : "")}`}/>
  <div  className={` ${isMobile ? "integritytitel-mobile" : (isDesktop ? "integritytitel-desktop" : "")}`}>Integrity</div>
  <div  className={isMobile ? "integritytxt-mobile" : "integritytxt"}>
						We keep our promise to protect the health of the people. We are honest to 
ourselves, to our customers and to our society in delivering quality product and 
service to the healthcare facility of the country.</div>
 
    </div>

</div>

<div className={isMobile ? "value_cont_2-mobile" : "value_cont_2"} >

<div className={isMobile ? "Commitment-mobile" : "Commitment"} >
<FaHandshakeSimple className={isMobile ? "value_icon_3-mobile" : "value_icon_3"}/>
  <div className={` ${isMobile ? "Commitmenttitel-mobile" : (isDesktop ? "Commitmenttitel-desktop" : "")}`}>Commitment</div>
  <div className={isMobile ? "Commitmenttext-mobile" : "Commitmenttext"}>
						We are committed enough to achieve our target to protect the health of 
the people through supplying on time delivery of service and products. Our 
commitment will last long till we achieve our goals as a company as well as 
individually					</div>

</div>


<div  className={isMobile ? "CustomerCentric-mobile" : "CustomerCentric"}>
<IoIosPeople className={isMobile ? "value_icon_4-mobile" : "value_icon_4"}/>
  <div  className={` ${isMobile ? "CustomerCentrictitel-mobile" : (isDesktop ? "CustomerCentrictitel-desktop" : "")}`}>Customer Centric</div>
  <div  className={isMobile ? "CustomerCentrictxt-mobile" : "CustomerCentrictxt"} >Our success as a team will be achieved in the fulfillment of the customer demand. We respect human being, honest to ourselves and committed to satisfy our customer needs.</div>
 
</div>

</div>

</div>





<div  className={isMobile ? "Droga_partener-mobile" : "Droga_partener"}>
        

         <div  className={isMobile ? "Drogagrouptxt2-mobile" : "Drogagrouptxt2"}>
         Our Satisfied Customers
         </div>
     


               
         <div className="slider">
  <div className="slide-track">
   
    <div className="slide">
      <img src={partener12} alt="Logo 2" className='imgs' style={{width:'150px',marginLeft:'50px'}}/>
    </div>
    <div className="slide">
      <img src={partener13} alt="Logo 1" style={{width:'100px',marginTop:'10px'}} className='imgs'/>
    </div>
    <div className="slide">
      <img src={partener14} alt="Logo 1" className='imgs' style={{width:'250px'}}/>
    </div>

    
  
    <div className="slide">
      <img src={partener18} alt="Logo 2" className='imgs' style={{width:'230px',marginTop:'0px'}}/>
    </div>
    <div className="slide">
      <img src={partener16} alt="Logo 1" style={{width:'230px',marginTop:'30px'}} className='imgs' />
    </div>
    <div className="slide">
      <img src={partener17} alt="Logo 1" className='imgs' style={{width:'150px',marginLeft:'50px'}}/>
    </div>
    <div className="slide">
      <img src={partener9} alt="Logo 2" className='imgs' style={{width:'150px',marginLeft:'50px'}}/>
    </div>



  
    <div className="slide">
      <img src={partener8} alt="Logo 2" className='imgs' style={{width:'230px',marginTop:'50px'}}/>
    </div>
    <div className="slide">
      <img src={partener11} alt="Logo 1" style={{width:'230px',marginTop:'0px'}} className='imgs' />
    </div>
    <div className="slide">
      <img src={partener19} alt="Logo 1" className='imgs' style={{width:'150px',marginLeft:'50px'}}/>
    </div>
    <div className="slide">
      <img src={partener14} alt="Logo 1" className='imgs' style={{width:'250px'}}/>
    </div>

   
    <div className="slide">
      <img src={partener15} alt="Logo 2" className='imgs' style={{width:'230px',marginTop:'50px'}}/>
    </div>
    <div className="slide">
      <img src={partener16} alt="Logo 1" style={{width:'230px',marginTop:'30px'}} className='imgs' />
    </div>
    <div className="slide">
      <img src={partener17} alt="Logo 1" className='imgs'style={{width:'150px',marginLeft:'50px'}}/>
    </div>


    <div className="slide">
      <img src={partener18} alt="Logo 2" className='imgs' style={{width:'230px',marginTop:'0px'}}/>
    </div>
    <div className="slide">
      <img src={partener16} alt="Logo 1" style={{width:'230px',marginTop:'30px'}} className='imgs' />
    </div>
    <div className="slide">
      <img src={partener18} alt="Logo 1" className='imgs' style={{width:'150px',marginLeft:'0px'}}/>
    </div>

    
  </div>
</div>

  
    
    

</div>
<div  className={isMobile ? "Group200-mobile" : "Group200"} >

<div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

   {/* <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/> */}
   
   <div  className={` ${isMobile ? "footer_Sub_txt_5-mobile" : (isDesktop ? "footer_Sub_txt_5-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_5_5-mobile" : (isDesktop ? "footer_Sub_txt_5_5-desktop" : "")}`}>
    
          Ethiopia
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_5-mobile" : (isDesktop ? "footer_Sub_para_5-desktop" : "")}`}> 
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
 
 

     
   <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


<div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
    Somaliland
</div>
<div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
<div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />Hargeysa,Somaliland 
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />+252634958444

    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
        Quick Links
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
    <div   className="footer_sub_txt_3">
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  /> <a href='/' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Home</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />  <a onClick={() => { navigate('/aboutus');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}> About Us</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  /> <a onClick={() => { navigate('/get');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Contact Us</a>
    </div>

       </div>
   </div>
   


 
</div>
   </div>
<div className="footer_right_hm">

<div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

</div>

    </>
    
  );
}
