import React ,  { useRef,useState , useEffect  }from 'react';
import './Contactus.css';
import '../../../index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Ethiopiamap from '../../../assets/Ethiopiamap.png';
import aboutcover3 from '../../../assets/aboutcover3.jpg';
import Ethiopiamap2 from '../../../assets/Ethiopiamap2.png';
import Somalelandmap from '../../../assets/Somalelandmap.png';
import ico from '../../../assets/ico.png';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import {AiOutlineYoutube , AiOutlineFacebook, AiOutlineInstagram, AiOutlineTwitter, AiOutlineLinkedin } from "react-icons/ai";
import {PiTelegramLogoFill,PiTiktokLogoFill} from "react-icons/pi";
import { Typography } from '@mui/material';
import { TextField, Button } from "@material-ui/core";
import { motion} from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import GoogleMapReact from 'google-map-react';
import toast, { Toaster } from 'react-hot-toast';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import dro from '../../../assets/dro.png';
import { FaFacebook, FaTwitter, FaInstagram,FaTelegram,FaLinkedin } from 'react-icons/fa';


import { formControl, formControlLabel } from 'react-bootstrap';

import emailjs from 'emailjs-com';



export default function Vacancy() {

const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation once
    threshold: 0.1  , // Percentage of the component's visibility for the animation to trigger
  });

  const variants = {
    hidden: { x: '50vw' }, // Starts from the left side outside the viewport
    show: {
      x: 0, // Ends at the original position
      transition: {
        duration: 0.8, // Animation duration in seconds
        ease: 'easeOut' // Easing function for the animation
      }
    }
  };

  const inputRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    window.location.href = '/contactus';
  }

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };
  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);
  
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);





// const handleSubmit = (e) => {
//     e.preventDefault();
//     // Your code to send the email
//     console.log(form);
// };






const form = useRef();



  const[result,showResult] = useState(false);
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_6g2p4q7', 'template_tlpj557', e.target, 'wsT4oMIJu8oD0V-Zx')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      showResult(true);
      
  };

setTimeout(() => {
  showResult(false);
},0);

  return (
    <>
{/* <div  className={` ${isMobile ? "Contactus-mobile" : (isDesktop ? "Contactus-desktop" : "")}`}>
  <div>




  

   


  <div className={` ${isMobile ? "Contactus_map_txt-mobile" : (isDesktop ? "Contactus_map_txt-desktop" : "")}`}>
    <div >
      <div
       className={` ${isMobile ? "Contactus1img-mobile" : (isDesktop ? "Contactus1img-desktop" : "")}`}
       style={{zIndex:1}}
        dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.2031048685576!2d38.72894747488421!3d9.045229591016739!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8573b3f6f5e9%3A0x41395cb651598266!2sDroga%20Pharma%20PLC!5e0!3m2!1sen!2set!4v1689142706099!5m2!1sen!2set" width="130" height="100" style="border:10;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"> </iframe>`
          
        }}
      />
    </div>

    <div >
      <div
      className={` ${isMobile ? "Contactus2img-mobile" : (isDesktop ? "Contactus2img-desktop" : "")}`}
       style={{zIndex:1}}
        dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.258887400548!2d38.72933767488422!3d9.040132791021657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b89d832ce80e3%3A0x724d9d5aaea9ac69!2sDROGA%20PHARMA%20WHOLESALE!5e0!3m2!1sen!2set!4v1689144597570!5m2!1sen!2set" width="130" height="100" style="border:10;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`}}
      />
    </div>

    <div >
      <div
      className={` ${isMobile ? "Contactus3img-mobile" : (isDesktop ? "Contactus3img-desktop" : "")}`}
       style={{zIndex:1}}
        dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3934.36349235798!2d44.0689026!3d9.563900799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1628bf4bf60fb691%3A0x6ee669887e3c89e3!2sDroga%20Pharma%20Somaliland%20PLC!5e0!3m2!1sen!2set!4v1689144939394!5m2!1sen!2set" width="130" height="100" scrolling="auto" style="border:10; "  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`
          
        }}
      />
    </div>
    
    <div >

<div   className={` ${isMobile ? "Contactus1-mobile" : (isDesktop ? "Contactus1-desktop" : "")}`}>
  <div className={` ${isMobile ? "Contactustxt-mobile" : (isDesktop ? "Contactustxt-desktop" : "")}`}>
    <div className={` ${isMobile ? "Contactus1titel-mobile" : (isDesktop ? "Contactus1titel-desktop" : "")}`} >Ethiopia Branch</div>
    <div className={` ${isMobile ? "Contactus1desc-mobile" : (isDesktop ? "Contactus1desc-desktop" : "")}`}>Addis Ababa,Ethiopia<br/>+25111 273 4554<br/>+251 91 366 7537<br/>info@drogapharma.com<br/>pharmadroga@gmail.com<br/>Gulele Subcity ,Woreda 09 </div>
   
  </div>
 
</div>    


<div className={` ${isMobile ? "Contactus2-mobile" : (isDesktop ? "Contactus2-desktop" : "")}`} >
  <div className={` ${isMobile ? "Contactustxt-mobile" : (isDesktop ? "Contactustxt-desktop" : "")}`}>
    <div className={` ${isMobile ? "Contactus1titel-mobile" : (isDesktop ? "Contactus1titel-desktop" : "")}`}>Somaliland Branch</div>
    <div className={` ${isMobile ? "Contactus1desc-mobile" : (isDesktop ? "Contactus1desc-desktop" : "")}`}>Addis Ababa,Ethiopia<br/>+25111 273 4554<br/>+251 91 366 7537<br/>info@drogapharma.com<br/>pharmadroga@gmail.com<br/>Gulele Subcity ,Woreda 09 </div>
  </div>
  
</div>
<div  className={` ${isMobile ? "Contactus3-mobile" : (isDesktop ? "Contactus3-desktop" : "")}`}>
  <div  className={` ${isMobile ? "Contactustxt-mobile" : (isDesktop ? "Contactustxt-desktop" : "")}`}>
    <div  className={` ${isMobile ? "Contactus1titel-mobile" : (isDesktop ? "Contactus1titel-desktop" : "")}`}>Wholesale Branch</div>
    <div  className={` ${isMobile ? "Contactus1desc-mobile" : (isDesktop ? "Contactus1desc-desktop" : "")}`}>Addis Ababa,Ethiopia<br/>+25111 273 4554<br/>+251 91 366 7537<br/>info@drogapharma.com<br/>pharmadroga@gmail.com<br/>Gulele Subcity ,Woreda 09 </div>
  </div>
</div>


</div>
</div>

<div  className={` ${isMobile ? "background_card-mobile" : (isDesktop ? "background_card-desktop" : "")}`}>
    <div className='contactus_titel'>
      <h1>Contact Us</h1>
    </div>
    <form onSubmit={handleSubmit}  className={` ${isMobile ? "contact_form-mobile" : (isDesktop ? "contact_form-desktop" : "")}`}>
                <div>
                    <label htmlFor="name">Name:</label>
                    <input type="text" id="name" name="name" value={form.name} onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" value={form.email} onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="phone">Phone:</label>
                    <input type="tel" id="phone" name="phone" value={form.phone} onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="message">Message:</label>
                    <textarea id="message" name="message" value={form.message} onChange={handleChange} />
                </div>
                <button type="submit" className='button'>Submit</button>
            </form>

</div>








  </div>
  

<img src={contactuscover} alt="Contact us"  className={` ${isMobile ? "Contactus_image-mobile" : (isDesktop ? "Contactus_image-desktop" : "")}`}/>










   

    

 


</div> */}
   
<div>
      <div  className={` ${isMobile ? "bg-background-mobile" : (isDesktop ? "bg-background-desktop" : "")}`}>
      <div className="container py-5">
        <div className="row py-5 g-3">
          <div className="col-md-6 first_col ">
            <h1 className="text-center mt-3" style={{fontFamily:"Neuwelt"}}>Contact Us</h1>
            <form className="p-4 mt-5" onSubmit={sendEmail}>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label" style={{fontFamily:"Neuwelt"}} >Name</label>
                <input type="text" className="form-control" id="exampleFormControlInput1"  name="fullname"/>

              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label" style={{fontFamily:"Neuwelt"}} >Email</label>
                <input type="text" className="form-control" id="exampleFormControlInput1" name="email"/>
                
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlTextarea1" className="form-label" style={{fontFamily:"Neuwelt"}} >your message</label>
                <textarea type="text" className="form-control" id="exampleFormControlTextarea1" rows="3" name="message"></textarea>

              </div>
              <div className="mb-3">
                <button className="btn_s">Send Now</button>
           
             
              </div>
              
              <div>
                <Toaster 
                position="top-center"
                reverseOrder={false}
                />
                {result ? toast.success('Your Messsage Successfully sent. We will contact you soon!') : null }
              </div>

             

             
              <div className="cont_social_media">
                <a href="https://www.facebook.com/drogapharmaplc/" target="_blank" style={{color:"#000"}}><FaFacebook className="social-icon" /></a>
                <a href="https://www.instagram.com/drogapharma/" target="_blank" style={{color:"#000"}}><FaInstagram className="social-icon" /></a>
                <a href="https://twitter.com/drogapharmaplc" target="_blank" style={{color:"#000"}}><FaTwitter className="social-icon" /></a>
                <a href="https://www.linkedin.com/in/droga-pharma-plc-7a3682187/" target="_blank" style={{color:"#000"}}><FaLinkedin className="social-icon" /></a>
                <a href='#' target="_blank" style={{color:"#000"}}><PiTelegramLogoFill className="social-icon" /></a>
                
                </div>
            </form>
          </div>
          <div className="col-md-6 sec_col">
            <img src={aboutcover3} className="img-fluid" alt="Contact" />
          </div>
        </div>
        {/* <div  className={` ${isMobile ? "row-last-mobile" : (isDesktop ? "row-last-desktop" : "")}`}>
          <div className="row row-cols-1 row-cols-md-3  p-3 text-white ">
            <div className="col">
              <h4>Headoffice</h4>
              <div
                className={` ${isMobile ? "Contactus1img-mobile" : (isDesktop ? "Contactus1img-desktop" : "")}`}
                style={{zIndex:1}}
                  dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.2031048685576!2d38.72894747488421!3d9.045229591016739!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8573b3f6f5e9%3A0x41395cb651598266!2sDroga%20Pharma%20PLC!5e0!3m2!1sen!2set!4v1689142706099!5m2!1sen!2set" width="130" height="100" style="border:10;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"> </iframe>`
                    
                  }}
      />
      <p>Addis Ababa,Ethiopia</p>
      <p>+25111 273 4554<br/>+251 91 366 7537</p>
      <p>info@drogapharma.com<br/>pharmadroga@gmail.com<br/>Gulele Subcity ,Woreda 09 </p>
            </div>
            <div className="col">
              <h4>Wholesale Branch</h4>
              <div
                className={` ${isMobile ? "Contactus2img-mobile" : (isDesktop ? "Contactus2img-desktop" : "")}`}
                style={{zIndex:1}}
                  dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.258887400548!2d38.72933767488422!3d9.040132791021657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b89d832ce80e3%3A0x724d9d5aaea9ac69!2sDROGA%20PHARMA%20WHOLESALE!5e0!3m2!1sen!2set!4v1689144597570!5m2!1sen!2set" width="130" height="100" style="border:10;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`}}
                />
              <p>Addis Ababa,Ethiopia </p>
              <p>+25111 273 4554<br/>+251 91 366 7537</p>
              <p>info@drogapharma.com<br/>pharmadroga@gmail.com<br/>Gulele Subcity ,Woreda 09</p>



            </div>
            <div className="col">
              <h4>Somaliland Branch</h4>
              <div
      className={` ${isMobile ? "Contactus3img-mobile" : (isDesktop ? "Contactus3img-desktop" : "")}`}
       style={{zIndex:1}}
        dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3934.36349235798!2d44.0689026!3d9.563900799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1628bf4bf60fb691%3A0x6ee669887e3c89e3!2sDroga%20Pharma%20Somaliland%20PLC!5e0!3m2!1sen!2set!4v1689144939394!5m2!1sen!2set" width="130" height="100" scrolling="auto" style="border:10; "  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`
          
        }}
      />
              <p>Somaleland</p>
              <p>+25111 273 4554<br/>+251 91 366 7537</p>
              <p>info@drogapharma.com<br/>pharmadroga@gmail.com<br/>Gulele Subcity ,Woreda 09 </p>
            </div>
          </div>
        </div> */}
      </div>
      </div>
 
    </div>

  
    <div  className={isMobile ? "Group200-mobile" : "Group200"} >

<div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

   {/* <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/> */}
   
   <div  className={` ${isMobile ? "footer_Sub_txt_5-mobile" : (isDesktop ? "footer_Sub_txt_5-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_5_5-mobile" : (isDesktop ? "footer_Sub_txt_5_5-desktop" : "")}`}>
    
          Ethiopia
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_5-mobile" : (isDesktop ? "footer_Sub_para_5-desktop" : "")}`}> 
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
 
 

     
   <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


<div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
    Somaliland
</div>
<div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
<div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />Hargeysa,Somaliland 
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />+252634958444

    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
        Quick Links
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
    <div   className="footer_sub_txt_3">
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  /> <a href='/' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Home</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />  <a href='/aboutus' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}> About Us</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  /> <a href='/contactus' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Contact Us</a>
    </div>

       </div>
   </div>
   


 
</div>
   </div>
<div className="footer_right_hm">

<div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

</div>







    </>
    
  );
}
