import React, {useState ,useEffect} from 'react';
import './certificate.css';


import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { useNavigate  } from 'react-router-dom';
import { CiCalendarDate } from 'react-icons/ci';
import { FaFilePdf } from "react-icons/fa6";

import axios from 'axios';
import fileDownload from 'js-file-download';





export default function Certificate() {


  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);

  const [certificate,setCertificate] = useState([]);

  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/CertificateCrate/')  
          .then((response) => {
            console.log(response.data);
            console.log(certificate.pdf_download_url); 

            setCertificate(response.data);
            
          })
          .catch((error) => {
            console.error('Error fetching blog recentBlog:', error);
           
          });
  
  }, []);
  
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);

  const navigate = useNavigate();


  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === certificate.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? certificate.length - 1 : prevIndex - 1));
  };

  const sliderStyle = {
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    borderRadius: '0.5rem',
    backgroundColor: 'white',
    margin: 'auto',
    width: '11/12',
    marginTop: '0rem',
    padding: '2rem',
  };

  const contentStyle = {
  
    justifyContent: 'center',
  };

  const itemStyle = {
    width: '95%',
    padding: '1rem',
  };

  const imageStyle = {
    height: '20 %',
    width: '20%',
    borderRadius: '0.5rem 0.5rem 0 0',
    transition: 'transform 0.3s ease-in-out',
  };

  const handleImageHover = (event) => {
    event.target.style.transform = 'translateY(-2px)';
  };

  const handleImageLeave = (event) => {
    event.target.style.transform = 'none';
  };
  const buttonStyle = {
    marginTop: '10px',
    backgroundColor: '#fff200',
    color: 'black',
    padding: '6px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontFamily:'Neuwelt',
    fontSize:'15px'
  };
  const buttonStyle2 = {
    marginTop: '10px',
    backgroundColor: '#fff200',
    color: 'black',
    padding: '6px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontFamily:'Neuwelt',
    marginLeft:'10px',
    fontSize:'15px'
    
  };
  const handlePDFDownload = () => {
    axios
      .get('https://backend.drogapharmacy.com/api/', {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };



  return (
    <>

<div className="news_detail_div" style={{ 
     background: `url(${require('../../../assets/Epss1.png') })`, 
     backgroundSize: 'cover',
     padding: '15px' ,animation: 'fadeIn 5s'}} >
      <div  className={isMobile ? "yellow_career_detail_cover-mobile" : "yellow_career_detail_cover_cert"}/>
         <div className="Group199" style={{ height: "305px", position: 'relative' ,justifyContent:'center'}}>
          
           <div  className={isMobile ? "carrer_detail_titel-mobile" : "carrer_detail_titel"} style={{animation: 'fadeIn 5s'}}>Certificates 
</div>
          
 
        </div>


</div>
  


<div className="carr_cover" style={{position:'relative'}} >
   


  <div style={sliderStyle}>
        <div style={contentStyle}>
          {certificate.slice(currentIndex, currentIndex + 3).map((certificate) => (
         
                   <div key={certificate.id} style={itemStyle}>
        <div className="bg-white rounded-lg shadow-lg w-auto flex flex-col sm:flex-row">
             <img src={certificate.image}  className="h-56 w- rounded-t-lg transition-transform duration-300 transform-gpu hover:-translate-y-2  sm:mb-0" />
               <div className="p-4">
                 <div className="overflow-y-auto bg w-full">
                   <p className="text-lg font-semibold">{certificate.title}</p>
                 </div>
                 <div className="overflow-y-auto bg w-full p-1">
                   <h3 className="mt-1 text-gray-600 text-sm text-justify w-auto">{certificate.content}</h3>
                 </div>
         <span class='text-sm w-44 pt-0 inline-block'> <CiCalendarDate style={{color:'#000',display:'inline-block',fontSize:'18px',paddingRight:'5px',fontFamily:'Neuwelt light'}}/>{certificate.date_posted}</span>
         <span>
       {/* <a onClick={handlePDFDownload} download style={{textDecoration:'none',color:'#000',fontFamily:'Neuwelt light'}}>
       <FaFilePdf style={{color:'#000',display:'inline-block',fontSize:'18px',paddingRight:'5px'}}/>Download PDF
       
   </a> */}
<a href={`https://backend.drogapharmacy.com/api/CertificateDownload/${certificate.id}/`} download style={{textDecoration:'none',color:'#000',fontFamily:'Neuwelt light'}}>
 <FaFilePdf style={{color:'#000',display:'inline-block',fontSize:'18px',paddingRight:'5px'}}/>Download PDF
</a>




         </span>
   

               </div>
             </div>
           </div>
         
         
          ))}
        </div>
        <div>
          <button style={buttonStyle} onClick={handlePrevious}>
            Previous
          </button>
          <button style={buttonStyle2} onClick={handleNext}>
            Next
          </button>
        </div>
      </div>



{/* <div className="shadow-lg rounded-lg bg-white mx-auto w-11/12 mt-4">
    
    <>
    
    
<div className="flex flex-wrap justify-center ">
      {certificate.map((certificate) => (
        <div key={certificate.id} className=" lg:w-1/4 p-4">
          <div className="bg-white rounded-lg shadow-lg flex flex-row">
            <img src={certificate.image}  className="h-56 w- rounded-t-lg transition-transform duration-300 transform-gpu hover:-translate-y-2" />
            <div className="p-4">

            <div className='overflow-y-auto bg w-full'>
              <p className="text-lg font-semibold">{certificate.title}</p>
            </div>
              <div className='overflow-y-auto bg w-full p-1'>
              <h3 className="mt-1 text-gray-600 text-sm">{certificate.content}</h3>
            </div>
            </div>
          </div>
        </div>
      ))}
  
    </div>
    </>
  
    </div> */}

    </div>
    <div  className={isMobile ? "Group200-mobile" : "Group200"} >

<div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

   {/* <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/> */}
   
   <div  className={` ${isMobile ? "footer_Sub_txt_5-mobile" : (isDesktop ? "footer_Sub_txt_5-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_5_5-mobile" : (isDesktop ? "footer_Sub_txt_5_5-desktop" : "")}`}>
    
          Ethiopia
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_5-mobile" : (isDesktop ? "footer_Sub_para_5-desktop" : "")}`}> 
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
 
 

     
   <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


<div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
    Somaliland
</div>
<div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
<div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />Hargeysa,Somaliland 
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />+252634958444

    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
        Quick Links
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
    <div   className="footer_sub_txt_3">
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  /> <a href='/' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Home</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />  <a onClick={() => { navigate('/aboutus');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}> About Us</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  /> <a onClick={() => { navigate('/get');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Contact Us</a>
    </div>

       </div>
   </div>
   


 
</div>
   </div>
<div className="footer_right_hm">

<div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

</div>

    </>
    
  );
}
