import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles  } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    accordion: {
      // adjust this value to your needs
      margin: '5px auto', // center the accordion horizontally
      backgroundColor: '#F1F0E8',
      '&:hover': {
        backgroundColor: '#fff200',
      },
    },
    expanded: {
      backgroundColor: '#fff200',
    },
    titel:{
        fontFamily:'Neuwelt'
    },
    sub:{
        fontFamily:'Neuwelt medium',
        textAlign:'justify'
    }
  }));

const MyAccordion = () => {
  
 

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Typography className={classes.titel} variant="h4" gutterBottom>
        Usually Asked Questuion
      </Typography>
      <Accordion className={classes.accordion}  expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.titel}>How Long Package Arrived</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel1' ? classes.expanded : ''}>
          <Typography className={classes.sub}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordion} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.titel}>What Time Max I can Shipped ?</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel2' ? classes.expanded : ''}>
          <Typography className={classes.sub}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.titel}>How Much Price ?</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel3' ? classes.expanded : ''}>
          <Typography className={classes.sub}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.titel}>Can Shipped To Other City ?</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel4' ? classes.expanded : ''}>
          <Typography className={classes.sub}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default MyAccordion;
