import React from 'react'
import './Rdpopup.css'
import { IoMdCloseCircle } from "react-icons/io";

function Rdpopup(props) {
  return (props.trigger) ? (
    <div className="rd_popup">
        <div className="rd_popup_inner">
            <button className="rdpop_close_btn" onClick={() => props.setTrigger(false)}><IoMdCloseCircle style={{fontSize:'20px'}}/></button>
                {props.children}
        </div>
    </div>
    
  ): "";
}

export default Rdpopup
