import React, { useState, useEffect } from 'react';
import './jobs.css';
import MyAccordion from './Accordions'
import { Link } from 'react-router-dom';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import f1 from '../../../assets/f1.png';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';





export default function Job() {


  const [activeSection, setActiveSection] = useState('description');

  // Function to handle clicking on the description section
  const handleDescriptionClick = () => {
    setActiveSection('description');
  };

  // Function to handle clicking on the details section
  const handleDetailsClick = () => {
    setActiveSection('details');
  };
  
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCompleted((oldValue) => {
        if (oldValue >= 70) {
          clearInterval(timer);
          return 70;
        }

        return oldValue + 1;
      });
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, []);
  const [completed1, setCompleted1] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCompleted1((oldValue) => {
        if (oldValue >= 90) {
          clearInterval(timer);
          return 90;
        }

        return oldValue + 1;
      });
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, []);
  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);
  
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);



const navigate = useNavigate();

  return (
    <>


    
<div className="RD_detail_div" style={{ 
     background: `url(${require('../../../assets/dcarr.jpg') })`, // replace './path_to_your_image.jpg' with the actual path to your image
     backgroundSize: 'cover',
     padding: '15px' ,animation: 'fadeIn 5s'}} >
      <div   className={` ${isMobile ? "yellow_RD_detail_cover-mobile" : (isDesktop ? "yellow_RD_detail_cover_job-desktop" : "")}`}/>
         <div className="Group199" style={{ height: "305px", position: 'relative' ,justifyContent:'center'}}>
          
           <div  style={{animation: 'fadeIn 5s'}} className={isMobile ? "RD_detail_titel-mobile" : "RD_detail_titel"}>Careers Detail 
</div>
          
 
        </div>


</div>

<div className="dcarr_cov" style={{position:"relative"}}>
   

   <div   className={` ${isMobile ? "rd_txt_img-mobile" : (isDesktop ? "rd_txt_img-desktop" : "")}`}>

      
   <div   className={` ${isMobile ? "rd_desc-mobile" : (isDesktop ? "rd_desc-desktop" : "")}`}>




<div    className={` ${isMobile ? "dcarr_tit2-mobile" : (isDesktop ? "dcarr_tit2-desktop" : "")}`}>
Find Your<strong style={{fontFamily:'Neuwelt'}}> Dream Job and Career Path</strong>
</div>
 <div  className={` ${isMobile ? "dcarr_tit2_sub-mobile" : (isDesktop ? "dcarr_tit2_sub-desktop" : "")}`}>
      <br/><p  className={` ${isMobile ? "dcarr_tit2_sub_p-mobile" : (isDesktop ? "dcarr_tit2_sub_p-desktop" : "")}`}>Your Dream job is a job that you would love to have and that aligns with your skills, interests, and career goals. It's a job that you would not only enjoy doing, but one that you believe will contribute to your personal and professional growth, also a position that is not only fulfilling and meaningful, but also aligns with your personal values and interests.</p>
 </div>
 {/* <div className="job_button">
              <button  className='job_abut_bn'>
                  Send CV!
              </button>
      </div> */}

  <div style={{ animation: 'slideInDown 2s',transition:'background .3s, border .3s, border-radius .3s, box-shadow .3s, ' , position:'relative',zIndex:'1',marginLeft:'95%'}}>
        <div style={{textAlign:'left',marginBottom:'0px'}}>
          <h2 className={` ${isMobile ? "job_detail_carr-mobile" : (isDesktop ? "job_detail_carr-desktop" : "")}`}>CAREER</h2>
        </div>
  </div>

     

      
  
</div>

   <img  className={` ${isMobile ? "job_detail_image-mobile" : (isDesktop ? "job_detail_image-desktop" : "")}`} src={f1}/>
 
  </div> 

</div>

<Card   className={` ${isMobile ? "jobs_link-mobile" : (isDesktop ? "jobs_link-desktop" : "")}`}>
      <Card.Body >
        <Card.Title  className={` ${isMobile ? "jobs_link_tit-mobile" : (isDesktop ? "jobs_link_tit-desktop" : "")}`}>Join the Linkedin Life Community!</Card.Title>
        <Link  to="https://www.linkedin.com/in/droga-pharma-plc-7a3682187/"  style={{textDecoration:'none' ,color:'black'}}>
        <div style={{ display: 'flex', justifyContent: 'center', }}>
          <button variant="primary"  className={` ${isMobile ? "jobs_link_btn-mobile" : (isDesktop ? "jobs_link_btn-desktop" : "")}`} > Join US</button>
        </div>
        </Link>
       
      </Card.Body>
</Card>

  
    <div  className={` ${isMobile ? "job_detail_accor-mobile" : (isDesktop ? "job_detail_accor-desktop" : "")}`}>
      <MyAccordion />
    </div>

    <div  className={isMobile ? "Group200-mobile" : "Group200"} >

<div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

   {/* <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/> */}
   
   <div  className={` ${isMobile ? "footer_Sub_txt_5-mobile" : (isDesktop ? "footer_Sub_txt_5-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_5_5-mobile" : (isDesktop ? "footer_Sub_txt_5_5-desktop" : "")}`}>
    
          Ethiopia
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_5-mobile" : (isDesktop ? "footer_Sub_para_5-desktop" : "")}`}> 
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
 
 

     
   <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


<div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
    Somaliland
</div>
<div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
<div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />Hargeysa,Somaliland 
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />+252634958444

    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
        Quick Links
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
    <div   className="footer_sub_txt_3">
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  /> <a href='/' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Home</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />  <a onClick={() => { navigate('/aboutus');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}> About Us</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  /> <a onClick={() => { navigate('/get');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Contact Us</a>
    </div>

       </div>
   </div>
   


 
</div>
   </div>
<div className="footer_right_hm">

<div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

</div>

    </>
    
  );
}
