import * as React from 'react';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useState} from 'react';
import TextField from '@mui/material/TextField';





export default function Review() {
  const [checkboxes, setCheckboxes] = useState({
    mr: false,
    ms: false,
    dr: false,
    assistantProf: false,
    associateProf: false
  });
  
  const handleChange = (event) => {
    const { name, checked } = event.target;
  
    // Deselect all checkboxes
    const updatedCheckboxes = {
      mr: false,
      ms: false,
      dr: false,
      assistantProf: false,
      associateProf: false
    };
  
    // Select the clicked checkbox
    updatedCheckboxes[name] = checked;
  
    setCheckboxes(updatedCheckboxes);
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Reference Background
      </Typography>
      <div style={{paddingBottom:'10px',paddingLeft:'15px'}}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkboxes.mr}
            onChange={handleChange}
            name="mr"
          />
        }
        label="Mr."
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkboxes.ms}
            onChange={handleChange}
            name="ms"
          />
        }
        label="Ms."
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkboxes.dr}
            onChange={handleChange}
            name="dr"
          />
        }
        label="Dr."
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkboxes.assistantProf}
            onChange={handleChange}
            name="assistantProf"
          />
        }
        label="Assistant Prof."
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkboxes.associateProf}
            onChange={handleChange}
            name="associateProf"
          />
        }
        label="Associate Prof."
      />
    </div>
    <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="Full Name of Advisor"
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
     
        <Grid item xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="email"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="phoneNumber"
            name="phoneNumber"
            label="Phone Number"
            fullWidth
            autoComplete="tel"
            variant="standard"
          />
        </Grid>
       
       

      
    
      </Grid>
      
    </React.Fragment>
  );
}