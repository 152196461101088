import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {useState} from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function PaymentForm() {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Educational/Proficional Background
      </Typography>
      <Grid container spacing={3}>
     
        {/* <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardNumber"
            label="Card number"
            fullWidth
            autoComplete="cc-number"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="expDate"
            label="Expiry date"
            fullWidth
            autoComplete="cc-exp"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cvv"
            label="CVV"
            helperText="Last three digits on signature strip"
            fullWidth
            autoComplete="cc-csc"
            variant="standard"
          />
        </Grid> */}
        <Grid item xs={12}>
      <FormControl variant="standard" fullWidth>
        <InputLabel id="select-label">What Is Your Current Field?</InputLabel>
        <Select
          labelId="select-label"
          id="demo-simple-select"
          fullWidth
          variant="standard"
        >
          <MenuItem value="Acadamician">Acadamician</MenuItem>
          <MenuItem value="Agricalture Expert">Agricalture Expert</MenuItem>
          <MenuItem value="Biologist">Biologist</MenuItem>
          <MenuItem value="Botanist">Botanist</MenuItem>
          <MenuItem value="Chemist">Chemist</MenuItem>
          <MenuItem value="Food Scientist/Engineer">Food Scientist/Engineer</MenuItem>
          <MenuItem value="Health Officer">Health Officer</MenuItem>
          <MenuItem value="Nurse">Nurse</MenuItem>
          <MenuItem value="Nutritionist">Nutritionist</MenuItem>
          <MenuItem value="Pharmacist">Pharmacist</MenuItem>
          <MenuItem value="Physicans">Physicans</MenuItem>
          <MenuItem value="Student">Student</MenuItem>
          <MenuItem value="Others">Others</MenuItem>

        </Select>
      </FormControl>
      <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardName"
            label="If Other Please Specify"
            fullWidth
            autoComplete="cc-name"
            variant="standard"
          />
        </Grid>
        <FormControl variant="standard" fullWidth>
        <InputLabel id="select-label">If You are Student Please Specify level of Study</InputLabel>
        <Select
          labelId="select-label"
          id="demo-simple-select"
          fullWidth
          variant="standard"
        >
          <MenuItem value="Acadamician">Undergraduate </MenuItem>
          <MenuItem value="Agricalture Expert"> Postgraduate</MenuItem>
          

        </Select>
      </FormControl>
      <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardName"
            label="Please Specify the Field of study/Program You are Enrolled In"
            fullWidth
            autoComplete="cc-name"
            variant="standard"
          />
        </Grid>
        <FormControl variant="standard" fullWidth>
        <InputLabel id="select-label">Institution Type</InputLabel>
        <Select
          labelId="select-label"
          id="demo-simple-select"
          fullWidth
          variant="standard"
        >
          <MenuItem value="Acadamic Institution">Acadamic Institution</MenuItem>
          <MenuItem value="Research Institute">Research Institute</MenuItem>
          <MenuItem value="Government Hospital">Government Hospital</MenuItem>
          <MenuItem value="Private Hospiatal">Private Hospiatal</MenuItem>
          <MenuItem value="Health Center">Health Center</MenuItem>
          <MenuItem value="Clinic">Clinic</MenuItem>
          <MenuItem value="Pharmaciutical Industry">Pharmaciutical Industry</MenuItem>
          <MenuItem value="Pharmaciutical Import/Wholesale">Pharmaciutical Import/Wholesale</MenuItem>
          <MenuItem value="Government Pharmacy">Government Pharmacy</MenuItem>
          <MenuItem value="Private Pharmacy">Private Pharmacy</MenuItem>
      

        </Select>
      </FormControl>
      <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardName"
            label="Please specify the name of institution which you are attending/ working at"
            fullWidth
            autoComplete="cc-name"
            variant="standard"
          />
        </Grid>
    </Grid>
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}