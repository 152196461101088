import * as React   from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AddressForm from '../AddressForm';
import PaymentForm from '../PaymentForm';
import Review from '../Review';
import FinalForm from '../FinalForm';	
import {useState,useEffect} from 'react';
import './Check.css';
import MyAccordion from './Accordions'



function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = ['Step', 'Step', 'Step', 'Step'];

function getStepContent(step) {
  switch (step) {
    case 0:
      return <AddressForm />;
    case 1:
      return <PaymentForm />;
    case 2:
      return <Review />;
    case 3:
        return <FinalForm />;
    default:
      throw new Error('Unknown step');
  }
}

export default function Checkout() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);
  
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);

  return (
    <React.Fragment>
      <CssBaseline />
      <div  className={` ${isMobile ? "job_detail_accor-mobile" : (isDesktop ? "job_detail_accor-desktop" : "")}`}>
      <MyAccordion />
    </div>

      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6,  }, p: { xs: 2, md: 1.5 } ,mx:{md:-5},width: { xs: '100%', md: '650px' },background:'#fff200',}}>

          <Typography component="h1" variant="h4" align="center" style={{fontFamily:'Neuwelt'}}>
            Droga Research Grant
          </Typography>
          
     
          <Stepper activeStep={activeStep} sx={{
  display: { xs: 'block', md: 'flex' },
  pt: { xs: 1, md: 3 },
  pb: { xs: 2, md: 5 },
}}>
  {steps.map((label) => (
    <Step key={label} sx={{
      display: 'block',
      mb: { xs: 1, md: 0 },
    }}>
      <StepLabel>{label}</StepLabel>
    </Step>
  ))}
</Stepper>
  
          {activeStep === steps.length ? (
                <div style={{ display: 'flex',flexDirection:'column', justifyContent: 'center', alignItems: 'center' }}>
                       <React.Fragment>
                        <Typography variant="h5" gutterBottom>
                          Thank you!!
                        </Typography>
                        <Typography variant="subtitle1" >
                          Your Form Success Fully submit. keep In Touch With Us!!
                        </Typography>
                      </React.Fragment>
                </div>
         
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Paper>
        <Copyright />
      </Container>
    </React.Fragment>
  );
}