import React from 'react';
import Navbar from './components/Navbar'

import Home from './components/pages/Home/Home';
import {Routes, BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Aboutus from './components/pages/Service/Aboutus';
import Products from './components/pages/Product/Products';
import MedicalDevice from './components/pages/MedicalDevice/MedicalDevice';
import Labra from './components/pages/Labra/Labra';
import News from './components/pages/News/News';
import SignUp from './components/pages/SignUp/SignUp';
import Newsdetails from './components/pages/Newsdetails/Newsdetails';
import RD from './components/pages/RD/RD';
import Job from './components/pages/jobs/jobs';
import Register from './components/pages/Register/register';
import Login from './components/pages/Login/login';
import Logout from './components/pages/Logout/logout';
import Excutives from './components/pages/Excutives/excutives';
import Managments from './components/pages/managments/managments';
import MedicalEqu from './components/pages/medicalequ/medicalequp';
import Ortoh from './components/pages/Ortopedics/orto';
import Certificate from './components/pages/Certificates/certificate';
import Getin from './components/pages/Get/getin';
import './globalfont.css';






import Productdetail from './components/pages/Productdetail/Productdetail';
import Vacancy from './components/pages/Vacancy/Vacancy';
import Contactus from './components/pages/Contactus/Contactus';
// import Login from './components/Login';
import SidebarData from './components/SidebarData'
import Navbar1 from './components/Navbar1';
import { useState } from 'react';
import Admin from '../src/Admin';
import Create from './components/pages/Admin/create';
import Edit from './components/pages/Admin/edit';
import Delete from './components/pages/Admin/delete'; 
import Checkout from './components/pages/checkoutform/checkout/Checkout';



function App() {
  const [loggedIn, setLoggedIn ] = useState(false)

  return (
   
    <Router> 
       
      <Routes>
      
      
      <Route path="/" element={<><Navbar/><Home/></>} />
      <Route path="/SidebarData" element={<Navbar1 />} />
      <Route path="/checkout" element={<><Navbar/><Checkout /></>} /> 

      
      <Route path="/aboutus" element={<><Navbar/><Aboutus /></>} />
      
      <Route path="/products" element={<><Navbar/><Products /></>} />

      <Route path="/MedicalDevice" element={<><Navbar/><MedicalDevice /></>} />

      <Route path="/labra" element={<><Navbar/><Labra /></>} />
      
      <Route path="/news" element={<><Navbar/><News /></>} />
      {/* <Route path="/sign-in" element={<Login />} /> */}
      
      <Route path="/news/:pk" element={<><Navbar/><Newsdetails /></>} />
      
      <Route path="/RD" element={<><Navbar/><RD /></>} />
      
      <Route path="/Job" element={<><Navbar/><Job /></>} />
      
      <Route path="/Productdetail" element={<><Navbar/><Productdetail /></>} />

      <Route path="/vacancy" element={<><Navbar/><Vacancy /></>} />

      <Route path="/contactus" element={<><Navbar/><Contactus /></>} />

      <Route path="/register" element={<><Navbar/><Register/> </>} />
      <Route path="/login" element={<><Navbar/><Login/> </>} />
      <Route path="/logout" element={<><Navbar/><Logout/> </>} />
      <Route path="/excutives" element={<><Navbar/><Excutives/> </>} />
      <Route path="/managments" element={<><Navbar/><Managments/> </>} />
      <Route  path="/medicalequ" element={<><Navbar/><MedicalEqu/> </>}/>
      <Route  path="/orto" element={<><Navbar/><Ortoh/> </>}/>
      <Route  path="/certificate" element={<><Navbar/><Certificate/> </>}/>
      <Route  path="/get" element={<><Navbar/><Getin/> </>}/>


      



      <Route  path="/admin" element={<><Navbar/><Admin/> </>}/>
			<Route  path="/admin/create"  element={<><Navbar/><Create/> </>}/>
			<Route  path="/admin/edit/:id"  element={<><Navbar/><Edit/> </>}/>
			<Route  path="/admin/delete/:id"  element={<><Navbar/><Delete/> </>}/>





      
        {/* <Route path='/' exact component={Home} />
        <Route path='/services' component={Services} />
        <Route path='/products' component={Products} />
        <Route path='/contact-us' component={ContactUs} />
        <Route path='/sign-up' component={SignUp} />
        <Route path='/marketing' component={Marketing} />
        <Route path='/consulting' component={Consulting} /> */}

      </Routes>
    </Router>
  );
}

export default App;
