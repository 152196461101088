import React, {useState ,useEffect} from 'react';
import './excutives.css';


import { useInView } from 'react-intersection-observer';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';



import { useNavigate  } from 'react-router-dom';

import axios from 'axios';





export default function Executives() {

const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation once
    threshold: 0.1  , // Percentage of the component's visibility for the animation to trigger
  });

  const variants = {
    hidden: { x: '50vw' }, // Starts from the left side outside the viewport
    show: {
      x: 0, // Ends at the original position
      transition: {
        duration: 0.8, // Animation duration in seconds
        ease: 'easeOut' // Easing function for the animation
      }
    }
  };
  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);

  const [excutive,setExcutive] = useState([]);

  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/excutivesCrate/')  
          .then((response) => {
            console.log(response.data); 
            setExcutive(response.data);
            
          })
          .catch((error) => {
            console.error('Error fetching blog recentBlog:', error);
           
          });
  
  }, []);
  
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);

  const navigate = useNavigate();

  return (
    <>

<div className="news_detail_div" style={{ 
     background: `url(${require('../../../assets/itmanager2.jpg') })`, 
     backgroundSize: 'cover',
     padding: '15px' ,animation: 'fadeIn 5s'}} >
      <div  className={isMobile ? "yellow_career_detail_cover-mobile" : "yellow_career_detail_cover_ex"}/>
         <div className="Group199" style={{ height: "305px", position: 'relative' ,justifyContent:'center'}}>
          
           <div  className={isMobile ? "carrer_detail_titel_7-mobile" : "carrer_detail_titel"} style={{animation: 'fadeIn 5s'}}>Executives  
</div>
          
 
        </div>


</div>
  
<div  className={` ${isMobile ? "newsdetails_5-mobile" : (isDesktop ? "newsdetails-desktop" : "")}`} style={{position:"relative"}}>
   
   <div   className={` ${isMobile ? "carrer_sub_titel-mobile" : (isDesktop ? "carrer_sub_titel-desktop" : "")}`}>
    <p>Our Executives </p>
    Our leaders are dedicated to fostering an inclusive and equitable workplace culture and addressing healthcare disparities. We understand that we have a responsibility to drive positive change, both within our organization and in the broader healthcare community. Committed to advancing healthcare and improving the well-being of individuals worldwide. Our core values of innovation, integrity, collaboration, excellence, and patient-centricity drive our actions towards achieving medical breakthroughs and ensuring access to quality healthcare for all.
   </div>



</div>

<div className="carr_cover" >
   

   <div    className={` ${isMobile ? "carrer_alll-mobile" : (isDesktop ? "carrer_alll-desktop" : "")}`}>

     
   <div   className={` ${isMobile ? "carrer_desc-mobile" : (isDesktop ? "carrer_desc-desktop" : "")}`}>


      
  
</div>
<div style={{textAlign:'right'}}>

</div>
   
 
  </div> 

</div>
       
    

<div className={` ${isMobile ? "job_detailstitel_cards-mobile" : (isDesktop ? "job_detailstitel_cards-desktop" : "")}`}>


</div>


<div className="shadow-lg rounded-lg bg-white mx-auto w-11/12 mt-4">
    
    <>
    {/* <div className="flex flex-wrap justify-center ">
      {excutive.map((excutive) => (
        <div key={excutive.id} className=" lg:w-1/4 p-4">
            <div className={` ${isMobile ? "job_sub_cards-mobile" : (isDesktop ? "job_sub_cards-desktop" : "")}`}>
      <div className={` ${isMobile ? "mas-mobile" : (isDesktop ? "mas-desktop" : "")}`}>
          <div className={` ${isMobile ? "mas_img-mobile" : (isDesktop ? "mas_img-desktop" : "")}`}>
            <a style={{width:'100px',minWidth:'100px',margin:'0px 0px 0px 0px' , borderRadius:'10px',textDecoration:'none'}}>
       
            <img src={excutive.image}  className="h-30 w-full rounded-t-lg " />

            </a>
          </div>
          <div style={{padding:'0px 0px 40px 40px',position:'relative',width:'250%' }}>
            <h2>
              <a style={{textAlign:'left',margin:'0px 0px 10px 0px' ,fontSize:'20px',lineHeight:'1.4em',letterSpacing:'-0.15px'}}>{excutive.title}</a>
            </h2>
            <div className={` ${isMobile ? "mas_date_ico-mobile" : (isDesktop ? "mas_date_ico-desktop" : "")}`}>
            <div className={` ${isMobile ? "mas_date_ico_sub-mobile" : (isDesktop ? "mas_date_ico_sub-desktop" : "")}`}>

  <div className={` ${isMobile ? "mas_date-mobile" : (isDesktop ? "mas_date-desktop" : "")}`}>
    <h5 className={` ${isMobile ? "mas_cata-mobile" : (isDesktop ? "mas_cata-desktop" : "")}`}>

      <p className={` ${isMobile ? "mas_date_tex-mobile" : (isDesktop ? "mas_date_tex-desktop" : "")}`}>{excutive.content}</p>
    </h5>
  </div>
              </div>

            </div>
          </div>
      </div>
  </div>
        </div>
      ))}
  
    </div> */}
    
<div className="flex flex-wrap justify-center ">
      {excutive.map((excutive) => (
        <div key={excutive.id} className=" lg:w-1/4 p-4">
          <div className="bg-white rounded-lg shadow-lg">
            <img src={excutive.image}  className="h-48 w-full rounded-t-lg transition-transform duration-300 transform-gpu hover:-translate-y-2" />
            <div className="p-4">

            <div className='overflow-y-auto bg w-full'>
              <p className="text-lg font-semibold">{excutive.title}</p>
            
            </div>
              <div className='overflow-y-auto bg w-full p-1'>
              <h3 className="mt-1 text-gray-600 text-sm">{excutive.content}</h3>
            </div>
            </div>
          </div>
        </div>
      ))}
  
    </div>
    </>
  
    </div>
    <div  className={isMobile ? "Group200-mobile" : "Group200"} >

<div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

   {/* <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/> */}
   
   <div  className={` ${isMobile ? "footer_Sub_txt_5-mobile" : (isDesktop ? "footer_Sub_txt_5-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_5_5-mobile" : (isDesktop ? "footer_Sub_txt_5_5-desktop" : "")}`}>
    
          Ethiopia
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_5-mobile" : (isDesktop ? "footer_Sub_para_5-desktop" : "")}`}> 
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
 
 

     
   <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


<div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
    Somaliland
</div>
<div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
<div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />Hargeysa,Somaliland 
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />+252634958444

    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
        Quick Links
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
    <div   className="footer_sub_txt_3">
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  /> <a href='/' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Home</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />  <a onClick={() => { navigate('/aboutus');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}> About Us</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  /> <a onClick={() => { navigate('/get');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Contact Us</a>
    </div>

       </div>
   </div>
   


 
</div>
   </div>
<div className="footer_right_hm">

<div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

</div>

    </>
    
  );
}
