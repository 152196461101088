import React, { useEffect,useState,useRef } from 'react';

import { Link } from 'react-router-dom';
import './Navbar.css';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { AppBar, Toolbar, IconButton, Typography, MenuItem, Menu,InputBase,Divider ,Collapse,List,ListItem  } from "@material-ui/core";
import Droga from '../assets/Droga.png';
import slider1 from '../assets/slider1.jpg';
import Drocarrer from '../assets/Drocarrer.jpg';
import Drogapro from '../assets/Drogapro.JPG';


import press from '../assets/press.jpg';
import press2 from '../assets/press2.jpg';
import meditop from '../assets/meditop.jpg';

import { FaFacebook, FaTwitter, FaInstagram,FaLinkedin } from 'react-icons/fa';

import {PiTelegramLogoFill} from "react-icons/pi";
import abu from '../assets/abu.jpg';

import aboutcover3 from '../assets/aboutcover3.jpg';
import Researchgrant2 from '../assets/Researchgrant2.jpg';

import Epss1 from '../assets/Epss1.jpg';
import {datafilter} from '../data.js';


import { FiLock, FiLogOut,FiUserPlus } from 'react-icons/fi';

import { redirect } from "react-router-dom";








function Navbar() {
console.log(datafilter);
  const useStyles = makeStyles((theme) => ({



    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    link: {
      margin: theme.spacing(0, 1),
      padding: theme.spacing(1, 1.5),
    },
    toolbarTitle: {
      flexGrow: 1,
    },





    dptitle: {
    
      // paddingTop:'55px',
      fontFamily:'Neuwelt',
      fontSize: '14px', // for large screens
      [theme.breakpoints.down('1200px')]: {
        fontSize: '1.5em', // for medium screens
      },
      [theme.breakpoints.down('600px')]: {
        fontSize: '1em', // for small screens
      },
    },
  
    dpsub: {
      fontFamily:'Neuwelt',
      fontWeight:200,
      // fontSize:'16px',
      fontSize: '13px', // for large screens
      [theme.breakpoints.down('1200px')]: {
        fontSize: '1em', // for medium screens
      },
      [theme.breakpoints.down('600px')]: {
        fontSize: '0.8em', // for small screens
      },
    },
    dpsub_1: {
      paddingTop:'20px',
      fontFamily:'Neuwelt',
      fontWeight:200,
      // fontSize:'16px',
      fontSize: '12px', // for large screens
      [theme.breakpoints.down('1200px')]: {
        fontSize: '1em', // for medium screens
      },
      [theme.breakpoints.down('600px')]: {
        fontSize: '0.8em', // for small screens
      },
    },
    section: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
      },},
    bottomSection: {  
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      
      [theme.breakpoints.down('sm')]: {
        
          width: '100%',
      },  
      [theme.breakpoints.up('md')]: {
        width: '50%',
      },
      [theme.breakpoints.up('lg')]: {
        width: '25%',
        display: 'flex',
      },
      
    },
    dptitle_dpsub:{
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingLeft: '93px',
        paddingBottom:'20px'
     
    },  
    [theme.breakpoints.up('md')]: {
     
    },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: '110px',
      
    
    },
    
    },
    
    logo: {
     width: '20%',
     position: 'fixed',   
      [theme.breakpoints.up('sm')]: {
        maxWidth: '20px',
        maxHeight: '20px',
        animation: 'slideInDown 2s'
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '200px',
        animation: 'slideInDown 2s'
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '90px',
        maxHeight: '90px',
        top: '20px',
        animation: 'slideInDown 2s'
      },
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor:'white',
    },
    menuButton: {
      color:'black',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        '&:hover': {
        
          color:'#ffff00'
        },
    },
    title: {
      paddingTop: theme.spacing(-3),

      color:'black',
      display:'flex',
      flexDirection:'column',
      animation: 'slideInDown 2s'
    },
    navLinks: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        '&:hover': {
        
          color:'#fff'
        },
    },
    navLink: {
      [theme.breakpoints.down('sm')]: {
          display: 'none',
      },
      '&:hover': {
      
        color:'#fff'
      },
  },
    navLinks: {
      display: 'flex',           
      flexDirection: 'row',      
      justifyContent: 'center',
      color:'black',
      fontFamily:'Neuwelt',
      marginRight: theme.spacing(3),
      gap:'40px', 
      animation: 'slideInDown 2s',
      [theme.breakpoints.down('sm')]: {
          display: 'none',
      },
      '&:hover': {
        
        color:'#fff'
      },
      
  },
  navLink: {
    display: 'flex',           
    flexDirection: 'row',      
    justifyContent: 'center',
    color:'black',
    fontFamily:'Neuwelt',
    marginRight: theme.spacing(3),
    paddingTop:'-50px',
    animation: 'slideInDown 2s',
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
    '&:hover': {
      color:'#fff'
    },
},
  divider: {
    margin: theme.spacing(2, 0), // Adjust the margin as needed
    backgroundColor: '#fff200', // Adjust the background color as needed
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    animation: 'slideInDown 2s',
    '&:hover': {
      backgroundColor: '#000',
      color:'#fff'
    },
    marginRight: theme.spacing(1),
    marginLeft: 0,
    width: '50%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    color:'black',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    width: '100%',
  },
  topSection:{
    paddingBottom: '10px', 
    display: 'flex', 
    justifyContent: 'end',
    paddingTop: '10px',
    backgroundColor:'#fff200',
    width:'103.5%',
    height:'55px',
  },
  inputInput: {
    padding: theme.spacing(1, -1, 1, 0),
    paddingLeft: `calc(0.5em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '15ch',
    },
  },
}));


const [searchh, setSearchh] = useState('');



const classes = useStyles();
const [anchorEl, setAnchorEl] = useState(null);
const [searchValue, setSearchValue] = useState('');
const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};
const handleClose = () => {
  setAnchorEl(null);
};
const handleSearchInputChange = (event) => {
  setSearchValue(event.target.value);
};
const handleSearchSubmit = (event) => {
  event.preventDefault();
  console.log(`Search submitted with value: ${searchValue}`);
};


const [openSubmenu, setOpenSubmenu] = useState(false);

const handleSubmenu = (index) => {
  setOpenSubmenu(openSubmenu === index ? null : index);
};

useEffect(() => {
  const handler = e => setIsMobile(e.matches);
  window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
}, []);
const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);

const menuItems = [
  { title2: 'Home', link: '/'  },
  { title: 'Company', link: '/aboutus', subItems: [{ name: 'Executives', link: '/excutives' },{ name: 'Management Team', link: '/managments' }   ]},
  { title: 'Products', link: '/products', subItems: [{ name: 'Medicines', link: '/products' },{ name: 'Medical Devices', link: '/MedicalDevice' } ,{ name: 'Laboratory', link: '/labra' } ,{ name: 'Medical Equipments', link: '/medicalequ' } ,{ name: 'Orthopedics', link: '/orto' } ] },
  { title: 'Media', link: '/news' , subItems:[{ name: 'Press Release', link: '/news' },{ name: 'Certificate Of Recogination', link: '/certificate' }] },
  { title3: 'R&D', link: '/RD' },
  { title: 'Careers', link: '/vacancy', subItems:[{ name: 'Search Jobs', link: '/vacancy' },{ name: 'Open Position', link: '/job' }] },
  { title1: 'Get In Touch', link: '/get'},


];






const [showContent, setShowContent] = useState('Search Job');
const [showContent2, setShowContent2] = useState('Products');
const [showContent3, setShowContent3] = useState('About us');
const [showContent4, setShowContent4] = useState('Media');



const [isPopupOpen, setIsPopupOpen] = useState(false);
const user = (localStorage.getItem('access_token'));
const role = JSON.parse(localStorage.getItem('role'));
const popupRef = useRef(null);

// const handlePasswordChange = () => {

//   history.push('/changepassword');
// };

const handleLogout = () => {
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('access_token');

  window.location.href = '/login'; // Redirect to homepage
};

const togglePopup = () => {
  setIsPopupOpen(!isPopupOpen);
};

useEffect(() => {
  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupOpen(false);
    }
  };

  document.addEventListener('mousedown', handleOutsideClick);
  return () => {
    document.removeEventListener('mousedown', handleOutsideClick);
  };
}, []);

const [isLoggedIn, setIsLoggedIn] = useState(false);


  return (
        <>



<AppBar position="sticky" style={{ background: 'white' }}>
  <Toolbar className={classes.section} style={{ alignItems: 'center' }}>
    <div className={classes.bottomSection}>
      
    <div className={classes.logo}>
    <img src={Droga} alt="Droga Logo" />
    
  </div>
    <div className={classes.title}>
      
  <div className={classes.dptitle_dpsub}>
  <Typography variant="body2" className={classes.dpsub_1}>
      ድሮጋ ፋርማ ኃላ/የተ/የግ/ማ
    </Typography>
    <Typography variant="h6" className={classes.dptitle}>
      Droga Pharma PLC
    </Typography>
    <Typography variant="body2" className={classes.dpsub}>
      Serving the People
    </Typography>

  </div>
  
</div>
</div>

    <Divider orientation="vertical" flexItem />
    <div className={classes.middleSection} style={{ width: '100%' }}>
      
      <div className={classes.topSection}>
        
        
      {/* <div className={classes.search}>
        
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase placeholder="Search..." classes={{root: classes.inputRoot,input: classes.inputInput,}}
          
          onChange={(e) => setSearchh(e.target.value)}
          
          />
        </div> */}
           <div  className={isMobile ? "home_social_media-mobile" : "home_social_media"}>
                <a href="https://www.facebook.com/drogapharmaplc/" target="_blank" style={{color:"#000"}}><FaFacebook className="social-icon" /></a>
                <a href="https://www.instagram.com/droga_pharma_plc/" target="_blank" style={{color:"#000"}}><FaInstagram className="social-icon" /></a>
                <a href="https://twitter.com/drogapharmaplc" target="_blank" style={{color:"#000"}}><FaTwitter className="social-icon" /></a>
                <a href="https://www.linkedin.com/in/droga-pharma-plc-7a3682187/" target="_blank" style={{color:"#000"}}><FaLinkedin className="social-icon" /></a>
                <a href="https://t.me/+WBUtoSeyAP1kODg0" target="_blank" style={{color:"#000"}}><PiTelegramLogoFill className="social-icon" /></a>
                
                </div>
      <div className='Menu'>
      <IconButton edge="start" color="black" aria-label="menu" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems.map((item, index) => (
          <div key={index}>
            <MenuItem onClick={() => handleSubmenu(index)}>
              <Link style={{ textDecoration: 'none', color: 'black',fontFamily:'Neuwelt' }} to={item.link}>
                {item.title}{item.title2}{item.title3}{item.title1}
              </Link>
            </MenuItem>
            {item.subItems && (
              <Collapse in={openSubmenu === index} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.subItems.map((subItem, subIndex) => (
                    <ListItem button key={subIndex}>
                      <Link style={{ textDecoration: 'none',}}  to={subItem.link}>
                        <MenuItem onClick={handleClose} style={{ textDecoration: 'none', color: 'black',fontFamily:'Neuwelt light' }}>
                          {subItem.name}
                        </MenuItem>
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </Menu>
    </div>
      
      </div>
        <Divider orientation="vertical" flexItem />
      <Divider orientation="horizontal"   style={{ width: '100%' }}/>
      
      <div className={classes.navLinks} >
        
  {menuItems.map((item, index) => (
    
    <div className="dropdown" key={index}>
      
      <Typography variant="body1" className={classes.navLink} style={{ fontFamily: 'Neuwelt', color: 'black', paddingBottom: '20px', paddingTop: '20px' }}>
        {item.title}
      <Link key={item.title2} to={item.link} style={{ fontFamily: 'Neuwelt', color: 'black',textDecoration:'none' }}>{item.title2}</Link>
      <Link key={item.title3} to={item.link} style={{ fontFamily: 'Neuwelt', color: 'black',textDecoration:'none' }}>{item.title3}</Link>
      <Link key={item.title1} to={item.link} style={{ fontFamily: 'Neuwelt', color: 'black',textDecoration:'none' }}>{item.title1}</Link>
      </Typography>
      
      {item.title === "Products" && (
       <div className="dropdown-content2" >
       <ul style={{ listStyleType: 'none', paddingLeft: '50px', display: 'flex', justifyContent: 'center' }}>
      <li style={{ color: 'black', paddingLeft: '80px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        
        <Link to="/products"  style={{ textDecoration: 'none', color: 'black', }} >
        <p
          style={{fontSize: '18px',margin: '30px 0 10px 0',paddingLeft: '50px',color: showContent3 === 'Medicines Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent3 === 'Medicines Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1
          }}
          onMouseEnter={() => setShowContent2('Medicines Hovered')} onMouseLeave={() => setShowContent2('')} 
        >
          Medicines
          {showContent3 === 'Medicines Hovered' && (
            <div
            style={{position: 'absolute',top: 0,left: 50,width: '90%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
          }}
            />
          )}
        </p>
        </Link>
        <Link to="/MedicalDevice" style={{ textDecoration: 'none', color: 'black', }}>
        <p
         style={{fontSize: '18px',margin: '10px 0',paddingLeft: '50px',color: showContent2 === 'Medical Devices Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent2 === 'Medical Devices Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1
        }}
          onMouseEnter={() => setShowContent2('Medical Devices Hovered')} onMouseLeave={() => setShowContent2('')}
        >
          Medical Equipments and Devices
          {showContent2 === 'Medical Devices Hovered' && (
            <div
              style={{position: 'absolute',top: 0,left: 50,width: '103%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
              }}
            />
          )}
        </p>
        </Link>
        <Link to="/labra" style={{ textDecoration: 'none', color: 'black', }}>

        <p
        style={{fontSize: '18px',margin: '10px 0',paddingLeft: '50px',color: showContent2 === 'Laboratory Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent2 === 'Laboratory Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1
        }}
          onMouseEnter={() => setShowContent2('Laboratory Hovered')} onMouseLeave={() => setShowContent2('')}
        >
          Laboratory
          {showContent2 === 'Laboratory Hovered' && (
            <div
              style={{position: 'absolute',top: 0,left: 50,width: '234%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5}}
            />
          )}
        </p>
        </Link>
        <Link to="/medicalequ" style={{ textDecoration: 'none', color: 'black', }}>

        <p
          style={{fontSize: '18px',margin: '10px 0',paddingLeft: '50px',color: showContent2 === 'Medical Equipments Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent2 === 'Medical Equipments Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1
          }}
          onMouseEnter={() => setShowContent2('Medical Equipments Hovered')} onMouseLeave={() => setShowContent2('')}
        >Medical Supplies
          {showContent2 === 'Medical Equipments Hovered' && (
            <div
              style={{position: 'absolute',top: 0,left: 50,width: '175%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
              }}
            />
          )}
        </p>
        </Link>
        <Link to="/orto" style={{ textDecoration: 'none', color: 'black', }}>

        <p
          style={{fontSize: '18px',margin: '10px 0',paddingLeft: '50px',color: showContent2 === 'Orthopedics Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent2 === 'Orthopedics Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1
          }}
          onMouseEnter={() => setShowContent2('Orthopedics Hovered')} onMouseLeave={() => setShowContent2('')}
        >Orthopedics and Surgical Instrument
          {showContent2 === 'Orthopedics Hovered' && (
            <div
              style={{position: 'absolute',top: 0,left: 50,width: '94%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
              }}
            />
          )}
        </p>
        </Link>
      </li>
      <div style={{ flex: '1', borderLeft: '2px solid black', marginLeft: '50px', paddingLeft: '50px' }}>
        {showContent3 && (
          <div>
          {showContent3 === 'About us' && (
       <div>
         
         <img src={Drogapro} alt="About us" className="press_image_2" />
       </div>
     )}
            {showContent2 === 'Medical Devices Hovered' && (
              <div>
                {/* <h3 style={{ fontSize: '18px', margin: '50px 0 10px', paddingLeft: '50px', color: 'black', fontSize: '20px' }}>Medical Devices</h3> */}
              </div>
            )}
            {showContent2 === 'Laboratory Hovered' && (
              <div>
                {/* <h3 style={{ fontSize: '18px', margin: '50px 0 10px', paddingLeft: '50px', color: 'black', fontSize: '20px' }}>Laboratory</h3> */}
              </div>
            )}
            {showContent2 === 'Medical Equipments Hovered' && (
              <div>
                {/* <h3 style={{ fontSize: '18px', margin: '50px 0 10px', paddingLeft: '50px', color: 'black', fontSize: '20px' }}>Medical Equipments</h3> */}
              </div>
            )}
            {showContent2 === 'Orthopedics Hovered' && (
              <div>
                {/* <h3 style={{ fontSize: '18px', margin: '50px 0 10px', paddingLeft: '50px', color: 'black', fontSize: '20px' }}>Orthopedics and Surgical Instrument</h3> */}
              </div>
            )}
          </div>
        )}
      </div>
    </ul>
     </div>
      )}
     
  
{item.title === "Company" && (
       <div className="dropdown-content2" >
       <ul style={{  listStyleType: 'none', paddingLeft: '50px', display: 'flex', justifyContent: 'center'  }}>
<li style={{ color: 'black', paddingLeft: '80px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

 <Link to="/aboutus" style={{ textDecoration: 'none', color: 'black', }} >
 <p
      style={{
        fontSize: '18px',margin: '30px 0 10px 0',paddingLeft: '50px',color: showContent3 === 'About us Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent3 === 'About us Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1
      }}
   onMouseEnter={() => setShowContent2('About us Hovered')} onMouseLeave={() => setShowContent2('About us Hovered')} 
 >
   About us
   {showContent3 === 'About us Hovered' && (
  <div
    style={{position: 'absolute',top: 0,left: 50,width: '90%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
  }}
  >
    <img src={abu} alt="About us"  className='press_image_1' />
    <img src={aboutcover3} alt="About us"  className='press_image_2' />
  </div>
  
)}

 </p>



 </Link>
 <Link to="/managments" style={{ textDecoration: 'none', color: 'black', }}>


 <p
          style={{fontSize: '18px',margin: '10px 0 10px 0',paddingLeft: '50px',color: showContent2 === 'Management Team Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent2 === 'Management Team Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1
          }}
          onMouseEnter={() => setShowContent2('Management Team Hovered')}onMouseLeave={() => setShowContent2('')} 
        >
          Management Team
          {showContent2 === 'Management Team Hovered' && (
            <div
              style={{position: 'absolute',top: 0,left: 50,width: '90%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
              }}
            />
          )}
        </p>
  </Link>
 <Link to="/excutives" style={{ textDecoration: 'none', color: 'black', }}>

 {/* <p
     style={{fontSize: '18px',margin: '10px 0',paddingLeft: '50px',color: showContent3 === 'Executives Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent3 === 'Excutives Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1
    }}
   onMouseEnter={() => setShowContent3('Executives Hovered')} onMouseLeave={() => setShowContent3('Executives Hovered')}
 >Executives 
   {showContent3 === 'Executives Hovered' && (
            <div
              style={{position: 'absolute',top: 0,left: 50,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
              }}
            />
          )}
 </p> */}

 <p
          style={{fontSize: '18px',margin: '10px 0 10px 0',paddingLeft: '50px',color: showContent2 === 'Executives Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent2 === 'Executives Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1
          }}
          onMouseEnter={() => setShowContent2('Executives Hovered')}onMouseLeave={() => setShowContent2('')}
        >
          Executives
          {showContent2 === 'Executives Hovered' && (
            <div
              style={{position: 'absolute',top: 0,left: 50,width: '135%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
              }}
            />
          )}
        </p>
  </Link>
</li>
<div style={{ flex: '1', borderLeft: '2px solid black', marginLeft: '50px', paddingLeft: '50px'  }}>
 {showContent3 && (
   <div>
     {showContent3 === 'About us' && (
       <div>
         
         <img src={slider1} alt="About us" className="com_img" />
       </div>
     )}
     {showContent3 === 'Management Team Hovered' && (
       <div>
         <h3 style={{ fontSize: '18px', margin: '50px 0 10px', paddingLeft: '50px', color: 'black', fontSize: '20px' }}>Management Team</h3>
       </div>
     )}
     {showContent3 === 'Executives Hovered' && (
       <div>
         <h3 style={{ fontSize: '18px', margin: '50px 0 10px', paddingLeft: '50px', color: 'black', fontSize: '20px' }}>Executives</h3>
       </div>
     )}
   </div>
 )}
</div>
</ul>
     </div>
      )}
{item.title === "Careers" && (
        <div className="dropdown-content2" >
          <ul style={{  listStyleType: 'none', paddingLeft: '50px', display: 'flex', justifyContent: 'center'  }}>
  <li style={{ color: 'black', paddingLeft: '80px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    
    <Link to="/vacancy" style={{ textDecoration: 'none', color: 'black', }}>
    {/* <p
        style={{fontSize: '18px',margin: '30px 0 10px 0',paddingLeft: '50px',color: showContent === 'Search Jobs Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s', transform: showContent === 'Search Jobs Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1
        }}
      onMouseEnter={() => setShowContent('Search Job')} onMouseLeave={() => setShowContent('Search Job')}
    >Search Jobs
      {showContent === 'Search Jobs Hovered' && (
            <div
              style={{position: 'absolute',top: 0,left: 50,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
              }}
            />
          )}
    </p> */}
    <p
style={{fontSize: '18px',margin: '10px 0',paddingLeft: '50px',color: showContent3 === 'Search Jobs Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent3 === 'Search Jobs Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1}}
      onMouseEnter={() => setShowContent('Search Jobs Hovered')} onMouseLeave={() => setShowContent('Search Jobs Hovered')}
    >Search Jobs
      {showContent3 === 'Search Jobs Hovered' && (
            <div
            style={{position: 'absolute',top: 0,left: 50,width: '90%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
          }}
          
            >
          
            </div>
          )}
    </p>

    </Link>
    <Link to="/job" style={{ textDecoration: 'none', color: 'black', }}>

    <p
style={{fontSize: '18px',margin: '10px 0',paddingLeft: '50px',color: showContent === 'Feature Career Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent === 'Feature Career Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1}}
      onMouseEnter={() => setShowContent('Feature Career Hovered')} onMouseLeave={() => setShowContent('Feature Career Hovered')}
    >Open Positions
      {showContent === 'Feature Career Hovered' && (
            <div
              style={{ position: 'absolute',top: 0,left: 50,width: '89%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
              }}
            />
          )}
    </p>
    </Link>

    {/* <p
         style={{fontSize: '18px',margin: '10px 0',paddingLeft: '50px',color: showContent === 'Experienced Professionals Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent === 'Experienced Professionals Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1 }}
      onMouseEnter={() => setShowContent('Experienced Professionals Hovered')} onMouseLeave={() => setShowContent('Experienced Professionals Hovered')}
    >
      Experienced Professionals
      {showContent === 'Experienced Professionals Hovered' && (
            <div
              style={{position: 'absolute',top: 0,left: 50,width: '92%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
              }}
            />
          )}
    </p> */}
    {/* <p
       style={{fontSize: '18px',margin: '10px 0',paddingLeft: '50px',color: showContent === 'How we hire Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent === 'How we hire Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1
      }}
      onMouseEnter={() => setShowContent('How we hire Hovered')} onMouseLeave={() => setShowContent('How we hire Hovered')}
    >How we hire
      {showContent === 'How we hire Hovered' && (
            <div
              style={{position: 'absolute',top: 0,left: 50,width: '160%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
              }}
            />
          )}
    </p> */}
  </li>
  <div style={{ flex: '1', borderLeft: '2px solid black', marginLeft: '50px', paddingLeft: '50px' }}>
    {showContent3 && (
      <div>
          {showContent3 === 'About us' && (
       <div>
         
         <img src={Drocarrer} alt="About us" className="com_img" />
       </div>
     )}
        {showContent3 === 'Feature Career Hovered' && (
          <div>
            <h3 style={{ fontSize: '18px', margin: '50px 0 10px', paddingLeft: '50px', color: 'black', fontSize: '20px' }}>Open Position</h3>
        
          </div>
        )}
        {showContent3 === 'Experienced Professionals Hovered' && (
          <div>
            <h3 style={{ fontSize: '18px', margin: '50px 0 10px', paddingLeft: '50px', color: 'black', fontSize: '20px' }}>Experienced Professionals</h3><p style={{ fontSize: '18px', margin: '20px 0 10px', paddingLeft: '50px', color: 'black', fontSize: '20px' }}>Human Resource</p><p style={{ fontSize: '18px', margin: '20px 0 10px', paddingLeft: '50px', color: 'black', fontSize: '20px' }}>Finance </p>
          </div>
        )}
        {showContent3 === 'How we hire Hovered' && (
          <div>
            <h3 style={{ fontSize: '18px', margin: '50px 0 10px', paddingLeft: '50px', color: 'black', fontSize: '20px' }}>How we hire</h3>
            <p style={{ fontSize: '18px', margin: '20px 0 10px', paddingLeft: '50px', color: 'black', fontSize: '20px' }}>Frequently Asked Quations</p>
          </div>
        )}
      </div>
    )}
  </div>
</ul>
        </div>
      )}
{item.title === "Media" && (
       <div className="dropdown-content2" >
       <ul style={{  listStyleType: 'none', paddingLeft: '50px', display: 'flex', justifyContent: 'center'  }}>
<li style={{ color: 'black', paddingLeft: '80px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

 <Link to="/news" style={{ textDecoration: 'none', color: 'black', }}>
 <p
    style={{fontSize: '18px',margin: '30px 0 10px 0',paddingLeft: '50px',color: showContent3 === 'Press Release Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent3 === 'Press Release Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1
    }}
   onMouseEnter={() => setShowContent('Press Release Hovered')} onMouseLeave={() => setShowContent('Press Release Hovered')}
 >Press Release
   {showContent3 === 'Press Release Hovered' && (
          <div
          style={{position: 'absolute',top: 0,left: 50,width: '90%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
        }}
        >
          {/* <img src={Epss1} alt="press"  className='press_image_1'/> */}
          <img src={press2} alt="press"  className='press_image_2'/>
        </div>
          )}
 </p>
 
 </Link>
 <Link to="/certificate" style={{ textDecoration: 'none', color: 'black', }}>

 <p
       style={{fontSize: '18px',margin: '5px 0',paddingLeft: '50px',color: showContent === 'Certificate of Recogination Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent === 'Certificate of Recogination Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1
      }}
   onMouseEnter={() => setShowContent('Certificate of Recogination Hovered')}
   onMouseLeave={() => setShowContent('Certificate of Recogination Hovered')}
 >Certificate of Recogination
   {showContent === 'Certificate of Recogination Hovered' && (
            <div
            style={{position: 'absolute',top: 0,left: 50,width: '92%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
              }}
            >
               {/* <img src={Epss1} alt="press"  className='press_image_1'/>
          <img src={press2} alt="press"  className='press_image_2'/> */}
              </div>
          )}
 </p>
 </Link>
 {/* <Link to="/get" style={{ textDecoration: 'none', color: 'black', }}>

<p
      style={{fontSize: '18px',margin: '5px 0',paddingLeft: '50px',color: showContent === 'Get in Touch Hovered' ? 'black' : 'inherit',transition: 'transform 0.3s',transform: showContent === 'Get in Touch Hovered' ? 'translateX(20px)' : 'none',position: 'relative',zIndex: 1
     }}
  onMouseEnter={() => setShowContent('Get in Touch Hovered')}
  onMouseLeave={() => setShowContent('Get in Touch Hovered')}
>Get in Touch
  {showContent === 'Get in Touch Hovered' && (
           <div
           style={{position: 'absolute',top: 0,left: 50,width: '165%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex: -1,transition: 'opacity 0.3s',opacity: 0.5
              }}
           >
              <img src={Epss1} alt="press"  className='press_image_1'/>
         <img src={press2} alt="press"  className='press_image_2'/>
             </div>
         )}
</p>
</Link> */}
</li>
<div style={{ flex: '1', borderLeft: '2px solid black', marginLeft: '50px', paddingLeft: '50px'  }}>
 {showContent3 && (
   <div>
       {showContent3 === 'About us' && (
       <div>
         
         <img src={Epss1} alt="press"  className='press_image_2'/>
         
       </div>
     )}
     {/* {showContent4 === 'Certificate of Recogination Hovered' && (
       <div>
         <h3 style={{ fontSize: '18px', margin: '50px 0 10px', paddingLeft: '50px', color: 'black', fontSize: '20px' }}>Certificate of Recogination</h3>
       </div>
     )} */}
   </div>
 )}
</div>

</ul>
     </div>
      )}
    </div>
  ))}
</div>
    </div>  

    {/* <div  className={isMobile ? "signin_options-mobile" : "signin_options"}>
  
      <button
        className="flex items-center justify-center w-10 h-10 rounded-full bg-synergy-orange focus:outline-none"
        onClick={togglePopup}
      >

        <FiUserPlus color='black' />

        {user && user.username[0].toUpperCase()}
      </button>

      {isPopupOpen && (
        <div className="absolute right-0 mt-2 py-2 w-48 bg-black rounded-lg shadow-xl" ref={popupRef}>
          <div className="flex flex-col items-center justify-center px-8">
            <h3 className="text-lg mt-3 font-bold text-white">
            
              HI, {user.username.toUpperCase()}
            
            </h3>
            <h3 className="text-xs mb-3 text-white">
            {user && typeof user.email === 'string' && user.email.toLowerCase()}
            </h3>
          </div>
          
          
          <div>
          <Link to="/changepassword" className="no-underline">
          <button
            className="flex items-start mb-2 px-4 py-2 text-black hover:bg-gray-100 text-sm rounded-lg w-full "
          >
            <FiLock className="mr-2 " />
            Change Password
          </button>
        </Link>
          </div>
       
              <Link 	to="/register" className="no-underline">
                <button
                  className="flex items-start mb-2 px-4 py-2 text-black hover:bg-gray-100 text-sm rounded-lg w-full"
                >
                  <FiUserPlus className="mr-2" />
                  Register User
                </button>
              </Link>
          
              <Link 	to="/login" className="no-underline">
                <button
                  className="flex items-start mb-2  px-4 py-2 text-black hover:bg-gray-100 text-sm rounded-lg w-full "
                >
                  <FiUserPlus className="mr-2" />
                  login
                </button>
              </Link>
          <button
            className="flex items-start mb-2 px-4 py-2 text-black hover:bg-gray-100 text-sm rounded-lg w-full"
            onClick={handleLogout}
  
          >
            <FiLogOut className="mr-2" />
            Logout
          </button>
        </div>
      )}
    </div>   */}

    <div className={isMobile ? "signin_options-mobile" : "signin_options"}>
      <button
        className="flex items-center justify-center w-10 h-10 rounded-full bg-synergy-orange focus:outline-none"
        onClick={togglePopup}
      >
       
       <FiUserPlus color='black' />

{/* {user && user.username[0].toUpperCase()} */}
      </button>

      {isPopupOpen && (
        <div className="absolute right-0 mt-2 py-2 w-48 bg-black rounded-lg shadow-xl" ref={popupRef}>
          {user ? ( 
            <div>
                   <Link to="/register" className="no-underline">
                <button className="flex items-start mb-2 px-4 py-2 text-black hover:bg-gray-100 text-sm rounded-lg w-full">
                  Register User
                </button>
              </Link>
              <Link to="/changepassword" className="no-underline">
                <button className="flex items-start mb-2 px-4 py-2 text-black hover:bg-gray-100 text-sm rounded-lg w-full">
                  Change Password
                </button>
              </Link>
         
              <button
                className="flex items-start mb-2 px-4 py-2 text-black hover:bg-gray-100 text-sm rounded-lg w-full"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          ) : ( 
            <div>
              <Link to="/login" className="no-underline">
                <button className="flex items-start mb-2 px-4 py-2 text-black hover:bg-gray-100 text-sm rounded-lg w-full">
                  Login
                </button>
              </Link>
            </div>
          )}
     
        </div>
      )}
    </div>
  </Toolbar>
</AppBar>





    
  



        </>
      );
  
}
export default Navbar;



