import React, {useState ,useEffect} from 'react';
import './managments.css';


import { useInView } from 'react-intersection-observer';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';


import { useNavigate  } from 'react-router-dom';

import axios from 'axios';





export default function Vacancy() {

const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation once
    threshold: 0.1  , // Percentage of the component's visibility for the animation to trigger
  });

  const variants = {
    hidden: { x: '50vw' }, // Starts from the left side outside the viewport
    show: {
      x: 0, // Ends at the original position
      transition: {
        duration: 0.8, // Animation duration in seconds
        ease: 'easeOut' // Easing function for the animation
      }
    }
  };
  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);

  const [management,setManagment] = useState([]);

  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/managmentsCrate/')  
          .then((response) => {
            console.log(response.data); 
            setManagment(response.data);
            
          })
          .catch((error) => {
            console.error('Error fetching blog recentBlog:', error);
           
          });
  
  }, []);
  
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);

  const navigate = useNavigate();

  return (
    <>

<div className="news_detail_div" style={{ 
     background: `url(${require('../../../assets/ccc.jpeg') })`, 
     backgroundSize: 'cover',
     padding: '15px' ,animation: 'fadeIn 5s'}} >
      <div  className={isMobile ? "yellow_career_detail_cover-mobile" : "yellow_career_detail_cover_ma"}/>
         <div className="Group199" style={{ height: "305px", position: 'relative' ,justifyContent:'center'}}>
          
           <div  className={isMobile ? "carrer_detail_titel_2-mobile" : "carrer_detail_titel"} style={{animation: 'fadeIn 5s'}}>Managements  
</div>
          
 
        </div>


</div>
  
<div  className={` ${isMobile ? "newsdetails_2-mobile" : (isDesktop ? "newsdetails-desktop" : "")}`} style={{position:"relative"}}>
   
   <div   className={` ${isMobile ? "carrer_sub_titel-mobile" : (isDesktop ? "carrer_sub_titel-desktop" : "")}`}>
    <p>Our Managment Team </p>
    Through their visionary leadership, our management team fosters an environment that encourages creativity, embraces diversity, and promotes collaboration among employees. They prioritize the development and implementation of innovative strategies to tackle healthcare challenges and ensure that our organization remains at the forefront of medical breakthroughs. Furthermore, our management team recognizes the significance of integrity in all aspects of their work. They uphold ethical standards and transparency, promoting trust and accountability within the organization and with external stakeholders. By leading with integrity, they inspire others to do the same and create a culture of ethical behavior and responsible decision-making.
   </div>



</div>

<div className="carr_cover" >
   

   <div    className={` ${isMobile ? "carrer_alll-mobile" : (isDesktop ? "carrer_alll-desktop" : "")}`}>

     
   <div   className={` ${isMobile ? "carrer_desc-mobile" : (isDesktop ? "carrer_desc-desktop" : "")}`}>


      
  
</div>
<div style={{textAlign:'right'}}>

</div>
   
 
  </div> 

</div>
       
    

<div className={` ${isMobile ? "job_detailstitel_cards-mobile" : (isDesktop ? "job_detailstitel_cards-desktop" : "")}`}>


</div>


<div className="shadow-lg rounded-lg bg-white mx-auto w-11/12 mt-4">
    
    <>
    
    
<div className="flex flex-wrap justify-center ">
      {management.map((management) => (
        <div key={management.id} className=" lg:w-1/4 p-4">
          <div className="bg-white rounded-lg shadow-lg">
            <img src={management.image}  className="h-48 w-full rounded-t-lg transition-transform duration-300 transform-gpu hover:-translate-y-2" />
            <div className="p-4">

            <div className='overflow-y-auto bg w-full'>
              <p className="text-lg font-semibold">{management.title}</p>
            </div>
              <div className='overflow-y-auto bg w-full p-1'>
              <h3 className="mt-1 text-gray-600 text-sm">{management.content}</h3>
            </div>
            </div>
          </div>
        </div>
      ))}
  
    </div>
    </>
  
    </div>
    <div  className={isMobile ? "Group200-mobile" : "Group200"} >

<div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

   {/* <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/> */}
   
   <div  className={` ${isMobile ? "footer_Sub_txt_5-mobile" : (isDesktop ? "footer_Sub_txt_5-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_5_5-mobile" : (isDesktop ? "footer_Sub_txt_5_5-desktop" : "")}`}>
    
          Ethiopia
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_5-mobile" : (isDesktop ? "footer_Sub_para_5-desktop" : "")}`}> 
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
 
 

     
   <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


<div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
    Somaliland
</div>
<div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
<div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />Hargeysa,Somaliland 
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />+252634958444

    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
        Quick Links
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
    <div   className="footer_sub_txt_3">
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  /> <a href='/' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Home</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />  <a onClick={() => { navigate('/aboutus');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}> About Us</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  /> <a onClick={() => { navigate('/get');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Contact Us</a>
    </div>

       </div>
   </div>
   


 
</div>
   </div>
<div className="footer_right_hm">

<div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

</div>

    </>
    
  );
}
