import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../axios';
import { useNavigate, useParams } from 'react-router-dom';
//MaterialUI
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: '#fff200',
		color: 'black',
	},
}));

export default function Create() {
	const navigate = useNavigate();
	const { id } = useParams();
	const initialFormData = Object.freeze({
		id: '',
		title: '',
		slug: '',
		
		content: '',
		image: null,
	});

	const [formData, updateFormData] = useState(initialFormData);

	useEffect(() => {
		axiosInstance.get('admin/edit/postdetail/' + id).then((res) => {
			updateFormData({
				...formData,
				['title']: res.data.title,
		
				['slug']: res.data.slug,
				['content']: res.data.content,
			});
			console.log(res.data);
		});
	}, [updateFormData]);

	// const handleChange = (e) => {
	// 	updateFormData({
	// 		...formData,
	// 		// Trimming any whitespace
	// 		[e.target.name]: e.target.value.trim(),
	// 	});
	// };

	const handleChange = (e) => {
		if (e.target.name === 'image') {
		  updateFormData({
			...formData,
			image: e.target.files[0], // Update the image state with the selected file
		  });
		} else {
		  updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		  });
		}
	  };

	// const handleSubmit = (e) => {
	// 	e.preventDefault();
	// 	console.log(formData);

	// 	axiosInstance.put(`admin/edit/` + id + '/', {
	// 		title: formData.title,
	// 		slug: formData.slug,
	// 		author: 1,
	// 		excerpt: formData.excerpt,
	// 		content: formData.content,
	// 	});
	// 	navigate({
	// 		pathname: '/admin',
	// 	});
	// 	window.location.reload();
	// };

	const handleSubmit = async (e) => {
		e.preventDefault();
		const updatedFormData = new FormData();
		updatedFormData.append('title', formData.title);
		updatedFormData.append('slug', formData.slug);
		updatedFormData.append('author', 1);
		
		updatedFormData.append('content', formData.content);
		if (formData.image) {
		  updatedFormData.append('image', formData.image);
		}
	
		try {
		  await axiosInstance.put(`admin/edit/` + id + '/', updatedFormData, {
			headers: {
			  'Content-Type': 'multipart/form-data',
			},
		  });
		  navigate('/admin');
		  window.location.reload();
		} catch (error) {
		  console.error(error);
		  // Handle error cases
		}
	  };
	
	const classes = useStyles();

	return (
		<Container component="main" maxWidth="sm">
			<CssBaseline />
			<div className={classes.paper}>
				<Typography component="h1" variant="h5">
					Edit Post
				</Typography>
				<form className={classes.form} noValidate>
					<Grid container spacing={2}>
					<Grid item xs={12}>
						<input
						type="file"
						id="image"
						name="image"
						onChange={handleChange}
						/>
					</Grid>
								<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="title"
								label="Post Title"
								name="title"
								autoComplete="title"
								value={formData.title}
								onChange={handleChange}
							/>
						</Grid>
						
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="slug"
								label="slug"
								name="slug"
								autoComplete="slug"
								value={formData.slug}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="content"
								label="content"
								name="content"
								autoComplete="content"
								value={formData.content}
								onChange={handleChange}
								multiline
								rows={8}
							/>
						</Grid>
					</Grid>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						className={classes.submit}
						onClick={handleSubmit}
						style={{	backgroundColor:"#fff200",
											color:"#000"}}
					>
						Update Post
					</Button>
				</form>
			</div>
		</Container>
	);
}