
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import './News.css';
import { useState,useEffect } from 'react';
import dro from '../../../assets/dro.png';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { useNavigate } from 'react-router-dom';




const useStyles = makeStyles((theme) => ({
	cardMedia: {
		paddingTop: '76.25%', // 16:9
	},
	link: {
		margin: theme.spacing(1, 1.5),
	},
	cardHeader: {
		backgroundColor:
			theme.palette.type === 'light'
				? theme.palette.grey[200]
				: theme.palette.grey[700],
	},
	postTitle: {
		fontSize: '16px',
		textAlign: 'left',
	},
	postText: {
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'baseline',
		fontSize: '12px',
    textAlign: 'justify',
    fontFamily: 'Neuwelt Medium',  
		marginBottom: theme.spacing(2),
	},
}));
function News() {
  const [appState,setAppState] = useState()
  const classes = useStyles();
  const [loading , setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/')
          .then((response) => {
            console.log(response.data);
            setAppState(response.data);
            setLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching blog appState:', error);
            setLoading(false);
          });
  
  }, []);


  useEffect(() => {
        const handler = e => setIsMobile(e.matches);
        window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
      }, []);
      const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
      
      useEffect(() => {
        const handler = e => setIsDesktop(e.matches);
        window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
      }, []);
      
      const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);
return(
  // <div>
  //   {loading ? (
  //     <p>please wait...</p>
  //   ):(
  //     appState && appState.map(appState => (
  //       <div key={appState.id}>
  //         <h2>{appState.titel}</h2>
  //         <p>{appState.excerpt}</p>
  //         <p>{appState.content}</p>
  //         <p>{appState.author}</p>
  //         <p>{appState.status}</p>
  //       </div>
  //     ))
  //   )
  //   }
  // </div>

  
  <React.Fragment >
   <div className="newsdiv" style={{ 
     background: `url(${require('../../../assets/newcover.jpg') })`, // replace './path_to_your_image.jpg' with the actual path to your image
     backgroundSize: 'cover',
     padding: '9px' ,animation: 'fadeIn 5s'}} >
      <div  className={` ${isMobile ? "yellow_newscover-mobile" : (isDesktop ? "yellow_newscover-desktop" : "")}`}/>
         <div  className={isMobile ? "Group1_99-mobile" : "Group1_99"} >
          
           <div  className={isMobile ? "News_titel-mobile" : "News_titel"} style={{animation: 'fadeIn 5s'}}>News</div>
          
 
        </div>


</div>
<div  className={` ${isMobile ? "News-mobile" : (isDesktop ? "News-desktop" : "")}`} style={{position:"relative"}}>
<Container maxWidth="md" component="main" >
    <Grid container spacing={5} alignItems="flex-end">
      {appState && appState.map((appState) => {
  
  

  const handleDetailsClick = (id) => {
    navigate(`/news/${id}`);
  };
        return (
         
          <Grid item key={appState.id} xs={12} md={4} lg={4}>
            <Card className={classes.card}>
            <CardMedia
              className={classes.cardMedia}
              image={appState.image} 
              title="Image title"
            />
              <CardContent className={classes.cardContent}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h2"
                  className={classes.postTitle}
                >
                  {appState.title.substr(0, 90)}...
                </Typography>
                <div className='flex'>
                 
                    <div className=' pl-18  bottom-0 rounded-lg font-light text-zinc-500 text-xs '>
                      <p>{appState.published}</p>
                    </div>
                </div>
               
                <div className={classes.postText}>
                  <Typography
                    component="p"
                    color="textPrimary"
                  ></Typography>
                  <Typography variant="p" color="textSecondary">
                    {appState.content.substr(0, 180)}...
                  </Typography>

                
             
                </div>

         
<div className='flex'>
<button
                      onClick={() => handleDetailsClick(appState.id)}
                      className='bg-yellow-300 p-2 relative right-[-180px] hover:bg-slate-300 bottom-0 rounded-lg font-bold hover:cursor-pointer text-sm '
                    >
                      Details
                    </button>
                 
</div>
                      
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  </Container>
</div>

<div  className={isMobile ? "Group200-mobile" : "Group200"} >

<div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

   {/* <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/> */}
   
   <div  className={` ${isMobile ? "footer_Sub_txt_5-mobile" : (isDesktop ? "footer_Sub_txt_5-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_5_5-mobile" : (isDesktop ? "footer_Sub_txt_5_5-desktop" : "")}`}>
    
          Ethiopia
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_5-mobile" : (isDesktop ? "footer_Sub_para_5-desktop" : "")}`}> 
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
 
 

     
   <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


<div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
    Somaliland
</div>
<div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
<div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />Hargeysa,Somaliland 
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />+252634958444

    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
        Quick Links
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
    <div   className="footer_sub_txt_3">
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  /> <a href='/' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Home</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />  <a onClick={() => { navigate('/aboutus');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}> About Us</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  /> <a onClick={() => { navigate('/get');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Contact Us</a>
    </div>

       </div>
   </div>
   


 
</div>
   </div>
<div className="footer_right_hm">

<div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

</div>
</React.Fragment>
);
}
export default News;

