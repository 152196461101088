import React, {useState , useEffect} from 'react';
import './Home.css';
import cover2 from '../../../assets/cover2.png';
import proimg16 from '../../../assets/proimg16.png';
import career from '../../../assets/career.jpg';
import career_1 from '../../../assets/career_1.jpg';

import bussinesbeni from '../../../assets/bussinesbeni.jpg';

import cover10 from '../../../assets/cover10.jpg';
import cover3 from '../../../assets/cover3.jpg';
import cover7 from '../../../assets/cover7.jpg';
import medideimg3 from '../../../assets/medideimg3.png';
import medicii from '../../../assets/medicii.png';
import medidevi from '../../../assets/medidevi.png';
import drogagro1 from '../../../assets/drogagro1.jpg';
import drogagro3 from '../../../assets/drogagro3.jpg';
import drogagro4 from '../../../assets/drogagro4.jpg';



import proimg12 from '../../../assets/proimg12.jpg';
import proimg1 from '../../../assets/proimg1.png';
import orto from '../../../assets/orto.png';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import proimg10 from '../../../assets/proimg10.jpg';
import aboutcover3 from '../../../assets/aboutcover3.jpg';
import dro from '../../../assets/dro.png';
import LocalPharmacyOutlinedIcon from '@mui/icons-material/LocalPharmacyOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import AddchartIcon from '@mui/icons-material/Addchart';
import {AiOutlineSafetyCertificate , AiOutlineFacebook, AiOutlineInstagram, AiOutlineTwitter, AiOutlineLinkedin } from "react-icons/ai";
import {PiTelegramLogoFill,PiTiktokLogoFill} from "react-icons/pi";
import PeopleIcon from '@mui/icons-material/People';

import {BsPeople} from "react-icons/bs";
import {GiMedicines} from "react-icons/gi";
import {BiHomeCircle} from "react-icons/bi";
import partner1 from '../../../assets/partner1.png';
import partner2 from '../../../assets/partner2.png';
import partener3 from '../../../assets/partener3.png';
import partner4 from '../../../assets/partner4.png';
import partener5 from '../../../assets/partener5.png';
import partener6 from '../../../assets/partener6.png';
import partn from '../../../assets/partn.jpg';
import partn2jpg from '../../../assets/partn2jpg.jpg';
import partn3 from '../../../assets/partn3.png';
import {MdRadioButtonChecked} from "react-icons/md";
import {FaRegHandshake} from "react-icons/fa";
import {LiaCertificateSolid} from "react-icons/lia";

import { motion} from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { Link } from 'react-router-dom';


import CountUp from 'react-countup';

import { useSwipeable } from 'react-swipeable';

import { useNavigate  } from 'react-router-dom';
import { datafilter } from '../../../data';
import Aos from 'aos';
import "aos/dist/aos.css";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Rdpopup from '../Rdpopup/Rdpopup';



export default function Home() {


 




  
  const [ref_f3] = useInView({
    triggerOnce: true, // Trigger the animation once
    threshold: 0.1  , // Percentage of the component's visibility for the animation to trigger
  });

  const [in_View3, setInView3] = useState(false);         // Initializes state  
            
        useEffect(() => {                                     // Side effect
          const timer = setTimeout(() => {
            setInView3(true);                                 // Change state after 5 secs
          }, 2000);
        
          return () => clearTimeout(timer);                   // Cleanup
        }, []);

  const variants_v3 = {
    hidden: { x: '0vw' }, // Starts from the left side outside the viewport
    show: {
      x: 0, // Ends at the original position
      transition: {
        duration: 1.5, // Animation duration in seconds
        ease: 'easeOut', // Easing function for the animation
        hidden: { opacity: 0 },
        show: { opacity: 1 }
      }
    }
  };

  
const [timmerPopup,setTimmerPopup] = useState(false);


useEffect(() => {
  setTimeout(() =>{
    setTimmerPopup(true);
  }, 2000);
}, []);
            
  useEffect(() => {                                     // Side effect
    const timer = setTimeout(() => {
      setInView3(true);                                 // Change state after 5 secs
    }, 5000);
  
    return () => clearTimeout(timer);                   // Cleanup
  }, []);
  const [in_View4, setInView4] = useState(false);

  useEffect(() => {
    if(in_View3) {
        const timer = setTimeout(() => {
            setInView3(false);
            setInView4(true);
        }, 5000); // 5000 ms delay (5 seconds)

        // Cleanup function to prevent memory leaks
        return () => {
            clearTimeout(timer);
        }
    }
}, [in_View3]);


useEffect(() => {
  const handler = e => setIsMobile(e.matches);
  window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
}, []);
const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);

useEffect(() => {
  const handler = e => setIsDesktop(e.matches);
  window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
}, []);

const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);

const [divDisplay, setDivDisplay] = useState(0);
const [content, setContent] = useState(
  <div className="my-css-class transparent-card">
    
    Droga Pharma PLC<br/> 
    <div className="my-css-class1sub">
      experience working in this sector of the country motivated to embark on the registration and licensing of global healthcare products. Apart from the import business, our wholesale division; working to serve the health care facility and organizations
    </div>
  </div>);

useEffect(() => {
  const interval = setInterval(() => {
    setDivDisplay(prevDivDisplay => (prevDivDisplay+1)%2); // Update display every 5 seconds
    
    // Update the content for the second div
    if (divDisplay === 0) { 
    
      setContent(
        <div className="my-css-class transparent-card">

          Our Experts <br/> 
        
        
        <div className="my-css-class1sub">
          Our team is made up of highly experienced and renowned pharmacists and manufacturing industry professionals that utilize their unique knowledge to create and implement cutting-edge management systems and programs that drive our partners' success.
        </div>
      </div>
      );
    } else {
      // setContent(<div className="my-css-class" style={{justifyContent:"center",fontSize:'85px',animation: 'slideInDown 2s'}}>Serving the People<br/> 
      // <div className='my-css-class1sub' style={{fontSize:'22px'}}>Droga Pharma Pvt.Ltd. was established on April,2015 by a group of health care professionals’ founders’ experience working in this sector of the country motivated to embark on the registration and licensing of global healthcare product. Apart from the import business, our wholesale division; working to serve the health care facility and organization.
      // </div></div>);
      setContent(
        <div className="my-css-class transparent-card">

          Serving the People <br/> 
      
        
        <div className="my-css-class1sub">
          Droga Pharma Pvt.Ltd. was established on April,2015 by a group of health care professionals’ founders’ experience working in this sector of the country motivated to embark on the registration and licensing of global healthcare product. Apart from the import business, our wholesale division; working to serve the health care facility and organization.
        </div>
      </div>
      );
    }
  }, 5000);

  // Cleanup function to clear the interval when we unmount the component
  return () => clearInterval(interval);
}, [divDisplay]);
 
  

  
  

// ,,,  ,,,

  const cover = [cover10,drogagro4,cover7];
  const [currentImage, setCurrentImageIndex] = useState(0);

  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrentImageIndex(currentImage => Math.min(currentImage + 1, cover.length - 1)),
    onSwipedRight: () => setCurrentImageIndex(currentImage => Math.max(currentImage - 1, 0)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
});

useEffect(() => {
  const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex => (prevIndex + 1) % cover.length);
  }, 10000); // change 5000 to the number of milliseconds you want to wait before each slide
  return () => clearInterval(interval); // This clears the interval when the component unmounts, very important for avoiding memory leaks
}, []);
 

  const formatWithPlus = (value) => `${value}+`;
  const formatWithPlusB = (value) => `${value}+B`;
  

  const navigate = useNavigate();



  useEffect(() =>{
Aos.init({duration:2000})

  },[false]
  )
  
  return (
    <>

<div className='containr_home' >
<div className="Cover" style={{position: 'relative', width: '100%', maxWidth: '100vw',justifyContent:'center',alignItems:'center'}}>

<div className="MaskGroup" style={{position: 'relative', height:'80vh' }} {...handlers} >
        <div  className={` ${isMobile ? "black_cover-mobile" : (isDesktop ? "black_cover-desktop" : "")}`}/>


        <img  className={` ${isMobile ? "Image2_1-mobile" : (isDesktop ? "Image2_1-desktop" : "")}`} src={cover[currentImage]}  />  

        <div className="Group170" >
   

      <motion.div
        ref={ref_f3}
        
        initial='hidden'
        animate={(divDisplay === 0 && in_View4) ? 'show' : 'hidden'}
        
      >
        
        <div>
        
          <div >{content}</div>  
        </div>
      </motion.div>

      
    </div>




  </div>
  </div>
    
    

 
</div>



<Rdpopup trigger={timmerPopup} setTrigger={setTimmerPopup}>
        <div>
        <h1 style={{fontFamily:'Neuwelt', fontSize:'32px'}}>Droga Research Grant 2024</h1>
        <p>Application For <strong> Droga Research Grant 2024 </strong> is Now Open!!</p>
        <p>Application Can be accessed at<a onClick={() => { navigate('/Checkout');  }} style={{color:"blue",textDecoration:"underline"}}> R & D</a></p>
        </div>
        
</Rdpopup>


<div className={isMobile ? "covecard-mobile" : ""}>



<div data-aos="flip-up" className={` ${isMobile ? "covercardd-mobile" : (isDesktop ? "covercardd-desktop" : "")}`}>
      <div className={`covercard_text ${isMobile ? "covercard_text-mobile" : (isDesktop ? "covercard_text-desktop" : "")}`}>
    <p style={{ fontSize: 20,fontWeight: '700',display: 'flex',alignItems: 'center',justifyContent: 'center',marginTop:'10px',marginBottom:'10px'}}>Our Headquarter</p>
    <p style={{ fontSize: 14,display: 'flex',textAlign: 'center',justifyContent: 'center',lineHeight:'1.5',fontFamily:'Neuwelt medium',textAlign:'justify'}}>Our headquarter is located in the center of Addis Abeba . This is where we come up with new ideas and products to make people's lives better. And there's a lot more to come.</p>
    
      </div>
</div>

<div  data-aos="flip-up" className={` ${isMobile ? "covercardd2-mobile" : (isDesktop ? "covercardd2-desktop" : "")}`}>
  <div className='covercard_txt2'> 
    <p style={{ fontSize: 20,fontWeight: '700',display: 'flex',alignItems: 'center',justifyContent: 'center',marginTop:'10px',marginBottom:'10px'}}>Serving The People!</p>
    <p style={{ fontSize: 14,display: 'flex',textAlign: 'center',justifyContent: 'center',lineHeight:'1.5',fontFamily:'Neuwelt medium',textAlign:'justify'}}>Droga Pharma Pvt.Ltd Co. aims to serving quality pharmaceuticals, sutures, orthopedic implants, disposable medical devices, and medical equipment at a highly reasonable and fair pricing.</p>
  </div>
</div>


<div data-aos="flip-up" className={isMobile ? "covercardd3-mobile" : "covercardd3"} >
  <div className='covercard_text3'> 
    <p style={{fontSize: 20,fontWeight: '700',display: 'flex',alignItems: 'center',justifyContent: 'center',marginTop:'10px',marginBottom:'10px'}}>Our Experts</p>
    <p style={{ fontSize: 14,display: 'flex',textAlign: 'center',justifyContent: 'center',lineHeight:'1.5',fontFamily:'Neuwelt medium',textAlign:'justify'}}>Our team is comprised of highly experienced and renowned pharmacists and manufacturing industry professionals who use their unique skills to develop and implement cutting-edge management systems and programs that drive the success of our partners.</p>
  </div>
</div>
</div>




  <div   className={` ${isMobile ? "Aboutus_hm-mobile" : (isDesktop ? "Aboutus_hm-desktop" : "")}`}>
   

    <div   className={` ${isMobile ? "Abus_hm-mobile" : (isDesktop ? "Abus_hm-desktop" : "")}`}>

    <img  src={aboutcover3} data-aos="zoom-out-up"   className={` ${isMobile ? "aboutimg-mobile" : (isDesktop ? "aboutimg-desktop" : "")}`}/>
  

<div data-aos="zoom-out-up"   className={` ${isMobile ? "productdesc-mobile" : (isDesktop ? "productdesc-desktop" : "")}`}>

  <div  className={` ${isMobile ? "Aboutustit-mobile" : (isDesktop ? "Aboutustit-desktop" : "")}`}>
          Droga Pharma
  </div>

         <div  className={` ${isMobile ? "Aboutustit2-mobile" : (isDesktop ? "Aboutustit2-desktop" : "")}`}>
          Serving the People!
         </div>
   <div   className={` ${isMobile ? "Dro_Sub-mobile" : (isDesktop ? "Dro_Sub-desktop" : "")}`}>
        <br/><div style={{display:'flex'}}><div  className={` ${isMobile ? "dro_subb-mobile" : (isDesktop ? "dro_subb-desktop" : "")}`}>We are specializes in importing pharmaceutical products from different countries. Our company has manufacturing facilities to produce pharmaceutical products. Apart from the import business, our wholesale division is also working to serve the health care facility and organizations that need pharmaceuticals and healthcare products.</div></div>
        {/* <br/><div style={{display:'flex'}}><div className={` ${isMobile ? "dro_subb-mobile" : (isDesktop ? "dro_subb-desktop" : "")}`} ></div></div> */}
        {/* <br/><div style={{display:'flex'}}><div  className={` ${isMobile ? "dro_subb-mobile" : (isDesktop ? "dro_subb-desktop" : "")}`}></div></div> */}
        <br/><div style={{display:'flex'}}><div className={` ${isMobile ? "dro_subb-mobile" : (isDesktop ? "dro_subb-desktop" : "")}`}>Droga Pharma staffed with a highly qualified and dedicated 500+ employee.We believe in our staff and our commitment to benefit our customers; that is what builds our company. Our annual sales is more than 25 million USD for the private market and also we won more than 60 Million USD in government tenders through our partner manufacturer.</div></div>
   </div>
    
  </div>
        
   </div> 

     
    
    

</div>



<div className={isMobile ? "fact-mobile" : "fact"}>
   
         <div  className={isMobile ? "facttxt2-mobile" : "facttxt2"}>
          Industry Achievements
         </div>
     
<div className='factcard'>
         <div data-aos="zoom-out-up" className="factcard2" >
          <div className='round11'>
  <div  className={isMobile ? "Ellipse1-mobile bounce" : "Ellipse1 bounce"}/>
  <LocalPharmacyOutlinedIcon
   
    className={isMobile ? "Ellipse_icon-mobile_1 bounce" : "Ellipse_icon bounce"}
  />
  </div>
  <div   className={isMobile ? "yellowcard-mobile_2" : "yellowcard"} > 
  <div  className={isMobile ? "yellowcardtext-mobile" : "yellowcardtext"}>
    <CountUp end={20} duration={5} formattingFn={formatWithPlus} ></CountUp>
    
    <p  className={isMobile ? "yellowcardtext_para-mobile" : "yellowcardtext_para"}>Chain Pharmacies</p>
  </div>

    
   
  </div>
</div>
              
         
<div data-aos="zoom-out-up" className="Group62" style={{right:60, width: 96, position: 'relative', margin:"0 auto"}}>

<div className='round11'>
  <div className={isMobile ? "Ellipse1-mobile bounce" : "Ellipse1 bounce"} />
  <VaccinesOutlinedIcon
    className={isMobile ? "Ellipse_icon-mobile_2 bounce" : "Ellipse_icon bounce"}
  />
</div>
  <div className={isMobile ? "yellowcard-mobile_3" : "yellowcard"}  > 
  <div  className={isMobile ? "yellowcardtext-mobile" : "yellowcardtext"}>   
  <CountUp end={2000} duration={2} formattingFn={formatWithPlus}></CountUp>
    
    <p className={isMobile ? "yellowcardtext_para-mobile" : "yellowcardtext_para"}>Products Imported</p>
    </div>

    
   
  </div>
</div>


         
<div data-aos="zoom-out-up" className="Group62" style={{margin:"0 auto", width: 96, position: 'relative', right: 50}}>
<div className='round11'>
  <div className={isMobile ? "Ellipse1-mobile bounce" : "Ellipse1 bounce"} />
  <PeopleIcon
     className={isMobile ? "Ellipse_icon-mobile_3 bounce" : "Ellipse_icon bounce"}
  />
</div>
  <div className={isMobile ? "yellowcard-mobile_4" : "yellowcard"}  >
  <div className={isMobile ? "yellowcardtext-mobile" : "yellowcardtext"}> 
  <CountUp end={500} duration={2} formattingFn={formatWithPlus}></CountUp>
    
    <p className={isMobile ? "yellowcardtext_para-mobile" : "yellowcardtext_para"}>Employee</p>
    </div>

    
   
  </div>
  
</div>
    
</div>
     
    
    

</div>



<div className="Product_hm">
   

    <div className={isMobile ? "product_all-mobile" : "product_all"} >
    <div className="producttit" >
          What We Do

    <div   className={` ${isMobile ? "productsub-mobile" : (isDesktop ? "productsub-desktop" : "")}`}>
    Droga Pharma Pvt. Ltd. is a reputable importer of medical goods. We specialize in the acquisition and distribution of high-quality pharmaceuticals, laboratory equipment, medical devices, orthopedics, surgical instruments, implants, and other products. We endeavor to address the different demands of healthcare professionals by assuring the availability of vital supplies for improved patient care, with an emphasis on quality and dependability.
    </div>
    


  {/* <div className={` ${isMobile ? "producttxt3-mobile" : (isDesktop ? "producttxt3-desktop" : "")}`}>
 <RadioButtonCheckedIcon className={isMobile ? "producicowhatwedo-mobile" : "producic_whatwedo"}/>
          <div className={isMobile ? "bussibranchtitle-mobile" : "bussibranchtitel"} style={{fontFamily:'Neuwelt'}}>
         
          <div    className={` ${isMobile ? "producsub1whatwedo-mobile" : (isDesktop ? "producsub1whatwedo-desktop" : "")}`}>
          Distribution of high-quality pharmaceuticals, laboratory equipment, medical devices, orthopedics, surgical instruments, implants, and other products.
           </div>
          </div>
          
  </div>

    <div className={` ${isMobile ? "producttxt3-mobile" : (isDesktop ? "producttxt3-desktop" : "")}`}>
 <RadioButtonCheckedIcon className={isMobile ? "producicowhatwedo-mobile" : "producic_whatwedo"}/>
          <div className={isMobile ? "bussibranchtitle-mobile" : "bussibranchtitel"} style={{fontFamily:'Neuwelt'}}>
         
          <div    className={` ${isMobile ? "producsub1whatwedo-mobile" : (isDesktop ? "producsub1whatwedo2-desktop" : "")}`}>
          It aims to meet the diverse needs of healthcare professionals.
           </div>
          </div>
          
  </div>

    <div className={` ${isMobile ? "producttxt3-mobile" : (isDesktop ? "producttxt3-desktop" : "")}`}>
 <RadioButtonCheckedIcon className={isMobile ? "producicowhatwedo-mobile" : "producic_whatwedo"}/>
          <div className={isMobile ? "bussibranchtitle-mobile" : "bussibranchtitel"} style={{fontFamily:'Neuwelt'}}>
         
          <div    className={` ${isMobile ? "producsub1whatwedo-mobile" : (isDesktop ? "producsub1whatwedo-desktop" : "")}`}>
          Ensuring the availability of essential supplies for enhanced patient care, with a focus on quality and reliability.
           </div>
          </div>
          
  </div> */}
  <div   className={` ${isMobile ? "producttxt3-mobile" : (isDesktop ? "producttxt3-desktop" : "")}`}>

  <div style={{fontFamily:'Neuwelt medium',fontWeight:'800'}}>
          Labratory

          <div  className={` ${isMobile ? "producsub1-mobile" : (isDesktop ? "producsub1_2-desktop" : "")}`} >
          Hospital furniture is one thing that every hospital should have. Droga Imports is a reputable medical device importer that provides a wide selection of vital Hospital Furniture.
           </div>
           <div className="moreButtonArea">
        <button onClick={() => { navigate('/labra');  }}  className="moreBtn_home">

            Details
        </button>
        </div>
          </div>
          
         <img className={` ${isMobile ? "product_img-mobile" : (isDesktop ? "product_img_2-desktop" : "")}`} src={medideimg3}/>
     
         </div>
    
         <div   className={` ${isMobile ? "producttxt3-mobile" : (isDesktop ? "producttxt3-desktop" : "")}`}>

         <div style={{fontFamily:'Neuwelt medium',fontWeight:'800'}}>
          Orthopedics

          <div  className={` ${isMobile ? "producsub1-mobile" : (isDesktop ? "producsub1_2-desktop" : "")}`} >
          Hospital furniture is one thing that every hospital should have. Droga Imports is a reputable medical device importer that provides a wide selection of vital Hospital Furniture.
           </div>
           <div className="moreButtonArea">
        <button onClick={() => { navigate('/orto');  }}  className="moreBtn_home">
            Details
        </button>
        </div>
          </div>
         <img className={` ${isMobile ? "product_img-mobile" : (isDesktop ? "product_img_2-desktop" : "")}`} src={orto}/>
        
          
         </div>
    
  
    </div>

    

    <div className="productdesc" >
    
        <div  className={` ${isMobile ? "producttxt-mobile" : (isDesktop ? "producttxt-desktop" : "")}`}>
        <div style={{fontFamily:'Neuwelt medium',fontWeight:'800'}}> 
        Medicine
        <div   className={` ${isMobile ? "producsub1-mobile" : (isDesktop ? "producsub1-desktop" : "")}`}>
        Droga Pharma is a pharmaceutical company that specializes in the importing of various medical items.
         </div>
         <div className="moreButtonArea">
         <button onClick={() => { navigate('/products');  }}  className="moreBtn_home">
            Details
        </button>
 
        </div>
        </div>
        <img  className={` ${isMobile ? "product_img-mobile" : (isDesktop ? "product_img-desktop" : "")}`} src={medicii}/>
    
        
         </div>
        
         

         <div   className={` ${isMobile ? "producttxt2-mobile" : (isDesktop ? "producttxt2-desktop" : "")}`}>
         <div style={{fontFamily:'Neuwelt medium',fontWeight:'800'}}>
          Medical Devices
          <div  className={` ${isMobile ? "producsub1-mobile" : (isDesktop ? "producsub1-desktop" : "")}`}>
          Droga Imports is a reputable medical device importer that provides a wide selection of critical healthcare equipment.
          </div>
          <div className="moreButtonArea">
          <button onClick={() => { navigate('/medicaldevice');  }}  className="moreBtn_home">
            Details
        </button>
        </div>
          </div>
         <img className={` ${isMobile ? "product_img_2-mobile" : (isDesktop ? "product_img_3-desktop" : "")}`} src={medidevi}/>
     
          
         </div>

        
         <div   className={` ${isMobile ? "producttxt3-mobile" : (isDesktop ? "producttxt3-desktop" : "")}`}>
         <div style={{fontFamily:'Neuwelt medium',fontWeight:'800'}}>
          Medical Equipment

          <div  className={` ${isMobile ? "producsub1-mobile" : (isDesktop ? "producsub1_2-desktop" : "")}`} >
          Hospital furniture is one thing that every hospital should have. Droga Imports is a reputable medical device importer that provides a wide selection of vital Hospital Furniture.
           </div>
           <div className="moreButtonArea">
        <button onClick={() => { navigate('/medicaldevice');  }}  className="moreBtn_home">
            Details
        </button>
        </div>
          </div>
         <img className={` ${isMobile ? "product_img-mobile" : (isDesktop ? "product_img_2-desktop" : "")}`} src={proimg12}/>
 
          
         </div>



    </div>
        
   </div> 

     
    
    

</div>



<div   className={` ${isMobile ? "news-mobile" : (isDesktop ? "news-desktop" : "")}`}>
    
    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
          <div className="New" style={{color: 'black', fontSize: 32, fontFamily: 'Neuwelt', fontWeight: '800',animation: 'slideInDown 2s'}}>Why Choice Us</div>
    </div>
    
<div  className={` ${isMobile ? "newrow1-mobile" : (isDesktop ? "newrow1-desktop" : "")}`}>
  
<div className={` ${isMobile ? "newscrd2-mobile" : (isDesktop ? "newscrd2-desktop" : "")}`} data-aos="flip-left">
<div  className={` ${isMobile ? "grcard-mobile" : (isDesktop ? "grcard-desktop" : "")}`}>
  <div  className={` ${isMobile ? "covercardtext_hm-mobile" : (isDesktop ? "covercardtext_hm-desktop" : "")}`}> 
    <div  className={` ${isMobile ? "covercardtext_ico-mobile" : (isDesktop ? "covercardtext_ico-desktop" : "")}`}><AddchartIcon  style={{fontSize: '50px'}}/></div>
    <p  className={` ${isMobile ? "covercardtext_par1-mobile" : (isDesktop ? "covercardtext_par1-desktop" : "")}`}>9 Years Experience</p>
    <p  className={` ${isMobile ? "covercardtext_par2-mobile" : (isDesktop ? "covercardtext_par2-desktop" : "")}`}>Droga Pharma has built a global network of reliable suppliers and distribution partners over the years. This enables Us to obtain high-quality pharmaceutical items at competitive prices and efficiently supply.</p>
  </div>
</div>
  
</div>


<div  className={` ${isMobile ? "newscrd2-mobile" : (isDesktop ? "newscrd2-desktop" : "")}`} data-aos="zoom-in-out">
<div   className={` ${isMobile ? "grcar2-mobile" : (isDesktop ? "grcar2-desktop" : "")}`}>
  <div   className={` ${isMobile ? "covercardtext_hm-mobile" : (isDesktop ? "covercardtext_hm-desktop" : "")}`}> 
    <div className={` ${isMobile ? "covercardtext_ico-mobile" : (isDesktop ? "covercardtext_ico-desktop" : "")}`}><FaRegHandshake style={{fontSize: '50px'}}/></div>
    <p className={` ${isMobile ? "covercardtext_par1-mobile" : (isDesktop ? "covercardtext_par1-desktop" : "")}`}  >200+ Partners Registered</p>
    <p className={` ${isMobile ? "covercardtext_par2-mobile" : (isDesktop ? "covercardtext_par2-desktop" : "")}`}>Droga Pharma has a strong footing in the pharmaceutical market, with over 200 pleased customers. We known for Our dedication to quality, compliance, and the timely delivery of a diverse range of pharmaceutical items.</p>
  </div>
</div>

</div>

<div  className={` ${isMobile ? "newscrd_2-mobile" : (isDesktop ? "newscrd_2-desktop" : "")}`} data-aos="flip-left">

<div  className={` ${isMobile ? "grcard3-mobile" : (isDesktop ? "grcard-desktop" : "")}`}>
  <div  className={` ${isMobile ? "covercardtext_hm-mobile" : (isDesktop ? "covercardtext_hm-desktop" : "")}`}> 
    <div className={` ${isMobile ? "covercardtext_ico-mobile" : (isDesktop ? "covercardtext_ico-desktop" : "")}`}><LiaCertificateSolid style={{fontSize: '50px'}}/></div>
    <p className={` ${isMobile ? "covercardtext_par1-mobile" : (isDesktop ? "covercardtext_par1-desktop" : "")}`} >Best Award</p>
    <p className={` ${isMobile ? "covercardtext_par2-mobile" : (isDesktop ? "covercardtext_par2-desktop" : "")}`}>Droga Pharma is a multi-award-winning pharmaceutical import-export corporation. We have received significant industry accolades for their great service and devotion to excellence. </p>

  </div>
</div>

</div>



</div>
          
<div  className={` ${isMobile ? "newrow1-mobile" : (isDesktop ? "newrow2-desktop" : "")}`}>
  
<div  className={` ${isMobile ? "newscrd_3-mobile" : (isDesktop ? "newscrd-desktop" : "")}`} data-aos="flip-right">

  <div  className={` ${isMobile ? "grcard4-mobile" : (isDesktop ? "grcard-desktop" : "")}`}>
  <div  className={` ${isMobile ? "covercardtext_hm-mobile" : (isDesktop ? "covercardtext_hm-desktop" : "")}`}> 
    <div className={` ${isMobile ? "covercardtext_ico-mobile" : (isDesktop ? "covercardtext_ico-desktop" : "")}`}><AiOutlineSafetyCertificate style={{fontSize: '50px'}}/></div>
    <p  className={` ${isMobile ? "covercardtext_par1-mobile" : (isDesktop ? "covercardtext_par1-desktop" : "")}`}>TradeMark Branch</p>
    <p className={` ${isMobile ? "covercardtext_par2-mobile" : (isDesktop ? "covercardtext_par2-desktop" : "")}`}>Droga Pharma is a reputable import-export corporation with a pharmaceutical trademark branch. We have built a strong market presence with our trademark branch. </p>

  </div>
  </div>

</div>


<div  className={` ${isMobile ? "newscrd_4-mobile" : (isDesktop ? "newscrd-desktop" : "")}`} data-aos="zoom-in-out">

  <div  className={` ${isMobile ? "grcard5-mobile" : (isDesktop ? "grcar2-desktop" : "")}`}>
  <div className={` ${isMobile ? "covercardtext_hm-mobile" : (isDesktop ? "covercardtext_hm-desktop" : "")}`} > 
    <div className={` ${isMobile ? "covercardtext_ico-mobile" : (isDesktop ? "covercardtext_ico-desktop" : "")}`}><BsPeople style={{fontSize: '50px'}}/></div>
    <p className={` ${isMobile ? "covercardtext_par1-mobile" : (isDesktop ? "covercardtext_par1-desktop" : "")}`}>Biggest Community</p>
    <p className={` ${isMobile ? "covercardtext_par2-mobile" : (isDesktop ? "covercardtext_par2-desktop" : "")}`}>Droga Pharma has established itself as a trusted and reputable participant in the market, with a large community of consumers, suppliers, and partners. </p>

  </div>
  </div>

</div>

<div  className={` ${isMobile ? "newscrd_5-mobile" : (isDesktop ? "newscrd-desktop" : "")}`} data-aos="flip-right">

  <div  className={` ${isMobile ? "grcard6-mobile" : (isDesktop ? "grcard-desktop" : "")}`}>
  <div className={` ${isMobile ? "covercardtext_hm-mobile" : (isDesktop ? "covercardtext_hm-desktop" : "")}`}> 
    <div className={` ${isMobile ? "covercardtext_ico-mobile" : (isDesktop ? "covercardtext_ico-desktop" : "")}`}><GiMedicines style={{fontSize: '50px'}}/></div>
    <p className={` ${isMobile ? "covercardtext_par1-mobile" : (isDesktop ? "covercardtext_par1-desktop" : "")}`}>Standard Product</p>
    <p className={` ${isMobile ? "covercardtext_par2-mobile" : (isDesktop ? "covercardtext_par2-desktop" : "")}`}>With a strong focus on quality and compliance, Droga Pharma ensures that all our products meet rigorous industry standards. </p>

  </div>
  </div>

</div>



</div>

    

</div>

<div className="carr" >
   

   <div className={isMobile ? "carr_all-mobile" : "carr_all"} >

   <LazyLoadImage  src={career_1} className={isMobile ? "carrimg-mobile" : "carrimg"}  data-aos="zoom-out-up"/>
 

   <div className={isMobile ? "carrdesc-mobile" : "carrdesc"}  data-aos="zoom-out-up">

 <div  className={isMobile ? "carrtit-mobile" : "carrtit"}>
         Careers 
 </div>

        <div  className={isMobile ? "carrtit2-mobile" : "carrtit2"}>
        Join us, and you will be empowered to achieve success, with no limitations or barriers.
        </div>

        <div className={` ${isMobile ? "producttxt3-mobile" : (isDesktop ? "producttxt3-desktop" : "")}`}>
 <RadioButtonCheckedIcon className={isMobile ? "producicowhatwedo-mobile" : "producic_whatwedo"}/>
          <div className={isMobile ? "bussibranchtitle-mobile" : "bussibranchtitel"} style={{fontFamily:'Neuwelt'}}>
         
          <div    className={` ${isMobile ? "producsub1whatwedo-mobile" : (isDesktop ? "dro_subb-desktop" : "")}`}>
          Our company believes in the value of human talent as our most important asset.
           </div>
          </div>
          
  </div>
  <div className={` ${isMobile ? "producttxt3-mobile" : (isDesktop ? "producttxt3-desktop" : "")}`}>
 <RadioButtonCheckedIcon className={isMobile ? "producicowhatwedo-mobile" : "producic_whatwedo"}/>
          <div className={isMobile ? "bussibranchtitle-mobile" : "bussibranchtitel"} style={{fontFamily:'Neuwelt'}}>
         
          <div    className={` ${isMobile ? "producsub1whatwedo-mobile" : (isDesktop ? "dro_subb-desktop" : "")}`}>
          Our recruitment process begins with a detailed job analysis to attract the right people for the right roles.
           </div>
          </div>
          
  </div>
  <div className={` ${isMobile ? "producttxt3-mobile" : (isDesktop ? "producttxt3-desktop" : "")}`}>
 <RadioButtonCheckedIcon className={isMobile ? "producicowhatwedo-mobile" : "producic_whatwedo"}/>
          <div className={isMobile ? "bussibranchtitle-mobile" : "bussibranchtitel"} style={{fontFamily:'Neuwelt'}}>
         
          <div    className={` ${isMobile ? "producsub1whatwedo-mobile" : (isDesktop ? "dro_subb-desktop" : "")}`}>
          We value our employees and believe in treating them well in good times and bad.
           </div>
          </div>
          
  </div>

  <div className={` ${isMobile ? "producttxt3-mobile" : (isDesktop ? "producttxt3-desktop" : "")}`}>
 <RadioButtonCheckedIcon className={isMobile ? "producicowhatwedo-mobile" : "producic_whatwedo"}/>
          <div className={isMobile ? "bussibranchtitle-mobile" : "bussibranchtitel"} style={{fontFamily:'Neuwelt'}}>
         
          <div    className={` ${isMobile ? "producsub1whatwedo-mobile" : (isDesktop ? "dro_subb-desktop" : "")}`}>
          We go to exceptional lengths to hire the best people in the world.
           </div>
          </div>
          
  </div>

  <div className="carrButtonArea">


        <Link to="/vacancy" >
           <button   className={` ${isMobile ? "carrBtn-mobile" : (isDesktop ? "carrBtn-desktop" : "")}`}> Apply</button>
        </Link>
        
  </div>
 </div>
       
  </div> 

    
   
   

</div>


<div className="bussi" >
   

   <div className={isMobile ? "bussi_all-mobile" : "bussi_all"}>

<img src={bussinesbeni} loading='lazy'  className={` ${isMobile ? "bussiimg-mobile" : (isDesktop ? "bussiimg-desktop" : "")}`} data-aos="zoom-out-up" />
      
   <div  className={` ${isMobile ? "bussidesc-mobile" : (isDesktop ? "bussidesc-desktop" : "")}`}  >

<div  className={` ${isMobile ? "bussitit-mobile" : (isDesktop ? "bussitit-desktop" : "")}`}>
        Business Benefite 
</div>

       <div   className={isMobile ? "bussitit2-mobile" : "bussitit2"}>
       What You Get If Join Our Partnership
       </div>
 <div className={isMobile ? "bussitit3-mobile" : "bussitit3"} >
      <br/><p className={isMobile ? "bussititsub-mobile" : "bussititsub"} >Associating with our company can enhance your business's credibility in the market. It can also expand your reach to new potential customers or clients.</p>
 </div>
 <div  className={` ${isMobile ? "bussibranch-mobile" : (isDesktop ? "bussibranch-desktop" : "")}`}>
 <div className={` ${isMobile ? "producttxt3-mobile" : (isDesktop ? "producttxt3-desktop" : "")}`}>
 <BiHomeCircle className={isMobile ? "producico-mobile" : "producico"}/>
          <div className={isMobile ? "bussibranchtitle-mobile" : "bussibranchtitel"} style={{fontFamily:'Neuwelt'}}>
          Ethiopia Branch 
          <div    className={` ${isMobile ? "producsub1-mobile" : (isDesktop ? "producsub1-desktop" : "")}`}>
          Established in 2015, Our company is dedicated to improving people’s access to high-quality, demand-driven, and affordable drugs.  
           </div>
          </div>
          
  </div>

  <div className={` ${isMobile ? "producttxt3-mobile" : (isDesktop ? "producttxt3-desktop" : "")}`}>
 <BiHomeCircle className={isMobile ? "producico-mobile" : "producico"}/>
          <div style={{fontFamily:'Neuwelt'}}>
          Somaliland Branch
          <div  className={` ${isMobile ? "producsub1-mobile" : (isDesktop ? "producsub1-desktop" : "")}`}>
          Established in 2022, Our company is dedicated to to establish and maintain a safe supply chain of essential medicines in the country.
           </div>
          </div>
          
  </div>
 </div>
 
  
</div>
   
 
  </div> 

    
   
   

</div>

<div className="Droga_partener">
        

         <div className="Drogagrouptxt2" >
         Our Partners
         </div>
     


               
         <div class="slider">
  <div class="slide-track" >
   
    <div class="slide">
      <img src={partner1} alt="Logo 2" className='imgs' style={{width:'150px',marginLeft:'50px'}}/>
    </div>
    <div class="slide">
      <img src={partner2} alt="Logo 1" style={{width:'100px',marginTop:'10px'}} className='imgs'/>
    </div>
    <div class="slide">
      <img src={partener3} alt="Logo 1" className='imgs' style={{width:'250px'}}/>
    </div>

    
  
    <div class="slide">
      <img src={partner4} alt="Logo 2" className='imgs' style={{width:'230px',marginTop:'50px'}}/>
    </div>
    <div class="slide">
      <img src={partener5} alt="Logo 1" style={{width:'230px',marginTop:'30px'}} className='imgs' />
    </div>
    <div class="slide">
      <img src={partener6} alt="Logo 1" className='imgs' style={{width:'150px',marginLeft:'50px'}}/>
    </div>
    <div class="slide">
      <img src={partn2jpg} alt="Logo 2" className='imgs' style={{width:'150px',marginLeft:'50px'}}/>
    </div>



  
    <div class="slide">
      <img src={partner4} alt="Logo 2" className='imgs' style={{width:'230px',marginTop:'50px'}}/>
    </div>
    <div class="slide">
      <img src={partener5} alt="Logo 1" style={{width:'230px',marginTop:'30px'}} className='imgs' />
    </div>
    <div class="slide">
      <img src={partener6} alt="Logo 1" className='imgs' style={{width:'150px',marginLeft:'50px'}}/>
    </div>
    <div class="slide">
      <img src={partener3} alt="Logo 1" className='imgs' style={{width:'250px'}}/>
    </div>

   
    <div class="slide">
      <img src={partner4} alt="Logo 2" className='imgs' style={{width:'230px',marginTop:'50px'}}/>
    </div>
    <div class="slide">
      <img src={partener5} alt="Logo 1" style={{width:'230px',marginTop:'30px'}} className='imgs' />
    </div>
    <div class="slide">
      <img src={partn3} alt="Logo 1" className='imgs'style={{width:'150px',marginLeft:'50px'}}/>
    </div>


    <div class="slide">
      <img src={partner4} alt="Logo 2" className='imgs' style={{width:'230px',marginTop:'50px'}}/>
    </div>
    <div class="slide">
      <img src={partener5} alt="Logo 1" style={{width:'230px',marginTop:'30px'}} className='imgs' />
    </div>
    <div class="slide">
      <img src={partn} alt="Logo 1" className='imgs' style={{width:'150px',marginLeft:'50px'}}/>
    </div>

    
  </div>
</div>

  
    
    

</div>

<div  className={isMobile ? "Group200-mobile" : "Group200"} >

<div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

   {/* <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/> */}
   
   <div  className={` ${isMobile ? "footer_Sub_txt_5-mobile" : (isDesktop ? "footer_Sub_txt_5-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_5_5-mobile" : (isDesktop ? "footer_Sub_txt_5_5-desktop" : "")}`}>
    
          Ethiopia
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_5-mobile" : (isDesktop ? "footer_Sub_para_5-desktop" : "")}`}> 
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
 
 

     
   <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


<div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
    Somaliland
</div>
<div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
<div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />Hargeysa,Somaliland 
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />+252634958444

    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
        Quick Links
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
    <div   className="footer_sub_txt_3">
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  /> <a href='/' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Home</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />  <a  onClick={() => { navigate('/aboutus');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}> About Us</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  /> <a  onClick={() => { navigate('/get');  }}  style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Contact Us</a>
    </div>

       </div>
   </div>
   


 
</div>
   </div>
<div className="footer_right_hm">

<div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

</div>

    </>
    
  );
}
