import React from 'react'
import './Popup.css'
import { IoMdCloseCircle } from "react-icons/io";

function Popup(props) {
  return (props.trigger) ? (
    <div className="popup">

        <div className="popup_inner">
            <button className="close_btn" onClick={() => props.setTrigger(false)}><IoMdCloseCircle style={{fontSize:'20px'}}/></button>
            {props.children}
        </div>   
      
    </div>
  ) :   "";
}

export default Popup
