import React, {useState ,useEffect} from 'react';
import './Labra.css';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import {BsArrowRightCircleFill} from "react-icons/bs";
import { Typography } from '@mui/material';

import MyAccordion from './Accordions'


import axios from 'axios';

import { useNavigate  } from 'react-router-dom';









export default function Product() {

  const navigate = useNavigate();

  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);
  const [labraequ, setLabraequ] = useState();
  const [labra, setLabra] = useState();
  const [productMed_2, setProductMed_2] = useState();
  const [productMed_3, setProductMed_3] = useState();


  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);
  


 


  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/labraequcreate/')  
          .then((response) => {
            console.log(response.data[0]); 
            setLabraequ(response.data);
         
            
          })
          .catch((error) => {
            console.error('Error fetching blog productMed:', error);
           
          });
  
  }, []);




  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/labratory/')  
          .then((response) => {
            console.log(response.data[0]); 
            setLabra(response.data);
         
            
          })
          .catch((error) => {
            console.error('Error fetching blog productMed:', error);
           
          });
  
  }, []);



  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/productmedicine2create/')  
          .then((response) => {
            console.log(response.data[0]); 
            setProductMed_2(response.data);
         
            
          })
          .catch((error) => {
            console.error('Error fetching blog productMed:', error);
           
          });
  
  }, []);


  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/productmedicine3create/')  
          .then((response) => {
            console.log(response.data[0]); 
            setProductMed_3(response.data);
         
            
          })
          .catch((error) => {
            console.error('Error fetching blog productMed:', error);
           
          });
  
  }, []);

  if (labraequ === null) {
    return <p>Loading...</p>;
  }







  return (


    <>


<div className="news_detail_div"  style={{ 
     background: `url(${require('../../../assets/labra.jpg') })`, // replace './path_to_your_image.jpg' with the actual path to your image
     backgroundSize: 'cover',
     padding: '15px' ,animation: 'fadeIn 5s'}} >
      <div   className={` ${isMobile ? "yellow_pro_detail_cover-mobile" : (isDesktop ? "yellow_pro_detail_cover-desktop" : "")}`}/>
         <div className={` ${isMobile ? "Grou_p199-mobile" : (isDesktop ? "Grou_p199-desktop" : "")}`}>
          
           <div  className={` ${isMobile ? "pro_detail_titel-mobile" : (isDesktop ? "pro_detail_titel-desktop" : "")}`} style={{animation: 'fadeIn 5s'}}>Labratory Equipments 
</div>
          
 
        </div>


</div>


<div   className={` ${isMobile ? "Produc_details-mobile" : (isDesktop ? "Produc_details-desktop" : "")}`} style={{position:"relative"}}>
    <div  className={` ${isMobile ? "med_all-mobile" : (isDesktop ? "med_all-desktop" : "")}`}>

    <div className={isMobile ? "med_sub1-mobile" : "med_sub1"} >
<div  className={` ${isMobile ? "med_sub1_1-mobile" : (isDesktop ? "med_sub1_1-desktop" : "")}`}>

<div   className={` ${isMobile ? "productdetail_card-mobile" : (isDesktop ? "productdetail_card-desktop" : "")}`}>

{labra && labra.map((labra) => (
  <div key={labra.id}>  
  <img    className={` ${isMobile ? "product_detail_img-mobile" : (isDesktop ? "product_detail_img-desktop" : "")}`} src={labra.image} />
      
      </div>


))}






</div>
<div   className={` ${isMobile ? "produc_txt-mobile" : (isDesktop ? "produc_txt-desktop" : "")}`}>
{labra && labra.map((labra) => (
      <div key={labra.id}>  


    <h2  className={` ${isMobile ? "product_detai_ltitel-mobile" : (isDesktop ? "product_detai_ltitel-desktop" : "")}`}>{labra.title}</h2>
    
    </div>
 ))}


    {labra && labra.map((labra) => (
      <div key={labra.id}>  
    <p   className={` ${isMobile ? "productdetail_txt2-mobile" : (isDesktop ? "productdetail_txt2-desktop" : "")}`}>{labra.content}</p>

    
    </div>
 ))}
    

    {/* <p className={` ${isMobile ? "productdetail_txt2-mobile" : (isDesktop ? "productdetail_txt2-desktop" : "")}`}>network includes distribution centers and our management of a vast number of products, ensuring a consistent and reliable supply of medications.Our company also work diligently to safeguard the pharmaceutical supply chain, ensuring that all medications they distribute are safe and effective.</p>
    <p className={` ${isMobile ? "productdetail_txt2-mobile" : (isDesktop ? "productdetail_txt2-desktop" : "")}`}>Our company is a leading pharmaceutical distributor in Ethiopia. We provide medication at the right time and quality. Our services include the importation and exportation of pharmaceutical products certified by the government. We also offer secure warehousing managed by internationally approved warehouse management systems.</p> */}


</div>
</div> 
{/* <div  className={` ${isMobile ? "productdetail_card-mobile" : (isDesktop ? "productdetail_card-desktop" : "")}`}>
<div  >

{productMed &&
  productMed.map((product) => {
    if (product.id === 5) {
  return (
    <div key={product.id}>  
        <img   className={` ${isMobile ? "product_detail_img-mobile" : (isDesktop ? "product_detail_img-desktop" : "")}`} src={product.image} />
    

    
    </div>
  );
    }})}

</div>

</div> */}
<div   className={` ${isMobile ? "medicin_pro_accor-mobile" : (isDesktop ? "medicin_pro_accor_ll-desktop" : "")}`}>
      <MyAccordion />
</div> 

{/* <div className={` ${isMobile ? "medicin_2_card-mobile" : (isDesktop ? "medicin_2_card-desktop" : "")}`}>

  
<div className={` ${isMobile ? "medicin_pro_card2-mobile" : (isDesktop ? "medicin_pro_card2-desktop" : "")}`}>
{productMed_2 && productMed_2.map((productMed_2) => {
  return(
    <div>
  <div className={` ${isMobile ? "medical_post-mobile" : (isDesktop ? "medical_post-desktop" : "")}`}>
    
<div className={` ${isMobile ? "produc_detail_blog-mobile" : (isDesktop ? "produc_detail_blog-desktop" : "")}`}>
<BsArrowRightCircleFill
   className={` ${isMobile ? "med_icon-mobile" : (isDesktop ? "med_icon-desktop" : "")}`}
/>
<div className={` ${isMobile ? "popular_post_content-mobile" : (isDesktop ? "popular_post_content-desktop" : "")}`}>


  <div style={{width:'0px'}}>
  <h5>

  <a>{productMed_2.title}</a>
  </h5>
  <p>{productMed_2.content}</p> 
</div>

<div >
    <a className='medical_dev_detail_image' >
        <img  className={` ${isMobile ? "medical_dev_image_sub-mobile" : (isDesktop ? "medical_dev_image_sub-desktop" : "")}`}  src={productMed_2.image}/>
      </a>
</div>
</div>

</div>
  </div>
   
  
</div>
  )

})}
</div>
<div className={` ${isMobile ? "medicin_pro_card2-mobile" : (isDesktop ? "medicin_pro_card2-desktop" : "")}`}>
{productMed_3 && productMed_3.map((productMed_3) => {
  return (
<div>
  <div className={` ${isMobile ? "medical_post-mobile" : (isDesktop ? "medical_post-desktop" : "")}`}>
 
<div className={` ${isMobile ? "produc_detail_blog-mobile" : (isDesktop ? "produc_detail_blog-desktop" : "")}`}>
<BsArrowRightCircleFill
  className={` ${isMobile ? "med_icon-mobile" : (isDesktop ? "med_icon-desktop" : "")}`}
/>
<div className={` ${isMobile ? "popular_post_content-mobile" : (isDesktop ? "popular_post_content-desktop" : "")}`} >


  <div >
  <h5>

  <a>{productMed_3.title}</a>
  </h5>
  <p>{productMed_3.content}</p>
</div>

<div >
    <a className='medical_dev_detail_image' >
        <img  className={` ${isMobile ? "medical_dev_image_sub-mobile" : (isDesktop ? "medical_dev_image_sub-desktop" : "")}`}  src={productMed_3.image}/>
      </a>
</div>
</div>
</div>




  </div>
   
  
</div>

  );

})}
</div>

</div> */}
</div>
<div  className={` ${isMobile ? "medicin_pro_card-mobile" : (isDesktop ? "medicin_pro_card-desktop" : "")}`}>

  
<div  className={` ${isMobile ? "medicin_pro_card2-mobile" : (isDesktop ? "medicin_pro_card2-desktop" : "")}`}>
<div>
<Typography  style={{  paddingBottom: '20px',fontWeight: '500',fontFamily: 'Neuwelt'}} variant="h6" >
  Labratory Equipments
  </Typography>
{labraequ && labraequ.map((labraequ) => {

    return (
  <div className={` ${isMobile ? "medical_post-mobile" : (isDesktop ? "medical_post_33-desktop" : "")}`}>
    
<div   className={` ${isMobile ? "produc_detail_blog-mobile" : (isDesktop ? "produc_detail_blog-desktop" : "")}`}>
<BsArrowRightCircleFill
  className={` ${isMobile ? "med_icon-mobile" : (isDesktop ? "med_icon-desktop" : "")}`}
/>

<div   className={` ${isMobile ? "popular_post_content-mobile" : (isDesktop ? "popular_post_content-desktop" : "")}`}>

<div style={{width:'100px'}}>
  <h5>

  <a >{labraequ.title}</a>
  </h5>
  <p >{labraequ.content}</p>
</div>

<div >
    <a className='medical_dev_detail_image' >
        <img   className={` ${isMobile ? "medical_dev_image_sub-mobile" : (isDesktop ? "medical_dev_image_sub-desktop" : "")}`} src={labraequ.image}/>
      </a>
</div>

  
</div>

</div>


  </div>

    );

})}   
  
</div>
</div>




  </div>
    </div>
</div>

<div  className={isMobile ? "Group200-mobile" : "Group200"} >

<div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

   {/* <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/> */}
   
   <div  className={` ${isMobile ? "footer_Sub_txt_5-mobile" : (isDesktop ? "footer_Sub_txt_5-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_5_5-mobile" : (isDesktop ? "footer_Sub_txt_5_5-desktop" : "")}`}>
    
          Ethiopia
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_5-mobile" : (isDesktop ? "footer_Sub_para_5-desktop" : "")}`}> 
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
 
 

     
   <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


<div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
    Somaliland
</div>
<div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
<div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />Hargeysa,Somaliland 
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />+252634958444

    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
        Quick Links
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
    <div   className="footer_sub_txt_3">
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  /> <a href='/' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Home</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />  <a onClick={() => { navigate('/aboutus');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}> About Us</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  /> <a onClick={() => { navigate('/get');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Contact Us</a>
    </div>

       </div>
   </div>
   


 
</div>
   </div>
<div className="footer_right_hm">

<div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

</div>
    </>
    
  );
}
