import React, {useState ,useEffect} from 'react';
import './medicalequp.css';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {BsArrowRightCircleFill} from "react-icons/bs";
import { Typography } from '@mui/material';
import MyAccordion from './Accordions_med'

import axios from 'axios';

import { useNavigate  } from 'react-router-dom';







export default function MedicalEqu() {


  const navigate = useNavigate();
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);
  const [medicalequ1, setMedicalequ1] = useState();
  const [medicalequ, setMedicalequ] = useState();



  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);
  


 


  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/medicalequ1create/')  
          .then((response) => {
            console.log(response.data[0]); 
            setMedicalequ1(response.data);
         
            
          })
          .catch((error) => {
            console.error('Error fetching blog productMed:', error);
           
          });
  
  }, []);




  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/medicalequ/')  
          .then((response) => {
            console.log(response.data[0]); 
            setMedicalequ(response.data);
         
            
          })
          .catch((error) => {
            console.error('Error fetching blog productMed:', error);
           
          });
  
  }, []);



  

 
  if (medicalequ1 === null) {
    return <p>Loading...</p>;
  }







  return (


    <>


<div className="news_detail_div"  style={{ 
     background: `url(${require('../../../assets/medicalequ.jpg') })`, 
     backgroundSize: 'cover',
     padding: '15px' ,animation: 'fadeIn 5s'}} >
      <div   className={` ${isMobile ? "yellow_pro_detail_cover-mobile" : (isDesktop ? "yellow_pro_detail_cover-desktop" : "")}`}/>
         <div className={` ${isMobile ? "Grou_p199-mobile" : (isDesktop ? "Grou_p199-desktop" : "")}`}>
          
           <div  className={` ${isMobile ? "pro_detail_titel-mobile" : (isDesktop ? "pro_detail_titel-desktop" : "")}`} style={{animation: 'fadeIn 5s'}}>Medical Supplies 
</div>
          
 
        </div>


</div>


<div   className={` ${isMobile ? "Produc_details-mobile" : (isDesktop ? "Produc_details-desktop" : "")}`} style={{position:"relative"}}>
    <div  className={` ${isMobile ? "med_all-mobile" : (isDesktop ? "med_all-desktop" : "")}`}>

    <div className={isMobile ? "med_sub1-mobile" : "med_sub1"} >
<div  className={` ${isMobile ? "med_sub1_1-mobile" : (isDesktop ? "med_sub1_1-desktop" : "")}`}>

<div   className={` ${isMobile ? "productdetail_card-mobile" : (isDesktop ? "productdetail_card-desktop" : "")}`}>

{medicalequ && medicalequ.map((medicalequ) => (
  <div key={medicalequ.id}>  
  <img    className={` ${isMobile ? "product_detail_img-mobile" : (isDesktop ? "product_detail_img-desktop" : "")}`} src={medicalequ.image} />
      
      </div>


))}






</div>
<div   className={` ${isMobile ? "produc_txt-mobile" : (isDesktop ? "produc_txt-desktop" : "")}`}>
{medicalequ && medicalequ.map((medicalequ) => (
      <div key={medicalequ.id}>  


    <h2  className={` ${isMobile ? "product_detai_ltitel-mobile" : (isDesktop ? "product_detai_ltitel-desktop" : "")}`}>{medicalequ.title}</h2>
    
    </div>
 ))}


    {medicalequ && medicalequ.map((medicalequ) => (
      <div key={medicalequ.id}>  
    <p   className={` ${isMobile ? "productdetail_txt2-mobile" : (isDesktop ? "productdetail_txt2-desktop" : "")}`}>{medicalequ.content}</p>

    
    </div>
 ))}
    

    

</div>
</div> 

<div   className={` ${isMobile ? "medicin_pro_accor-mobile" : (isDesktop ? "medicin_pro_accor_equ-desktop" : "")}`}>
      <MyAccordion />
</div> 


</div>
<div  className={` ${isMobile ? "medicin_pro_card-mobile" : (isDesktop ? "medicin_pro_card-desktop" : "")}`}>

  
<div  className={` ${isMobile ? "medicin_pro_card2-mobile" : (isDesktop ? "medicin_pro_card2-desktop" : "")}`}>
<div>
<Typography  style={{  paddingBottom: '20px',fontWeight: '500',fontFamily: 'Neuwelt'}} variant="h6" >
  Medical Supplies
  </Typography>
{medicalequ1 && medicalequ1.map((medicalequ1) => {

    return (
  <div className={` ${isMobile ? "medical_post-mobile" : (isDesktop ? "medical_post_44-desktop" : "")}`}>
    
<div   className={` ${isMobile ? "produc_detail_blog-mobile" : (isDesktop ? "produc_detail_blog-desktop" : "")}`}>
<BsArrowRightCircleFill
  className={` ${isMobile ? "med_icon-mobile" : (isDesktop ? "med_icon-desktop" : "")}`}
/>

<div   className={` ${isMobile ? "popular_post_content-mobile" : (isDesktop ? "popular_post_content-desktop" : "")}`}>

<div style={{width:'150px'}}>
  <h5>

  <a >{medicalequ1.title}</a>
  </h5>
  <p >{medicalequ1.content}</p>
</div>

<div >
    <a className='medical_dev_detail_image' >
        <img   className={` ${isMobile ? "medical_dev_image_sub-mobile" : (isDesktop ? "medical_dev_image_sub-desktop" : "")}`} src={medicalequ1.image}/>
      </a>
</div>

  
</div>

</div>


  </div>

    );

})}   
  
</div>
</div>




  </div>
    </div>




    

  
</div>

<div  className={isMobile ? "Group200-mobile" : "Group200"} >

<div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

   {/* <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/> */}
   
   <div  className={` ${isMobile ? "footer_Sub_txt_5-mobile" : (isDesktop ? "footer_Sub_txt_5-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_5_5-mobile" : (isDesktop ? "footer_Sub_txt_5_5-desktop" : "")}`}>
    
          Ethiopia
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_5-mobile" : (isDesktop ? "footer_Sub_para_5-desktop" : "")}`}> 
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
 
 

     
   <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


<div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
    Somaliland
</div>
<div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
<div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />Hargeysa,Somaliland 
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />+252634958444

    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
        Quick Links
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
    <div   className="footer_sub_txt_3">
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  /> <a href='/' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Home</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />  <a onClick={() => { navigate('/aboutus');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}> About Us</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  /> <a onClick={() => { navigate('/get');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Contact Us</a>
    </div>

       </div>
   </div>
   


 
</div>
   </div>
<div className="footer_right_hm">

<div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

</div>
    </>
    
  );
}
