import React, { useState,useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles  } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Picture11 from '../../../assets/Picture11.png';
import Picture12 from '../../../assets/Picture12.jpg';
import RandD from '../../../assets/RandD.jpg';
import RandD8 from '../../../assets/RandD8.jpg';
import RandD7 from '../../../assets/RandD7.jpg';
import RandD9 from '../../../assets/RandD9.jpg';
import RandD10 from '../../../assets/RandD10.jpg';
import Picture14 from '../../../assets/Picture14.png';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';









const useStyles = makeStyles((theme) => ({
    accordion: {
      // adjust this value to your needs
      margin: '10px auto', // center the accordion horizontally
      backgroundColor: '#F1F0E8',
      '&:hover': {
        backgroundColor: '#fff200',
      },
    },
    detail:{
      fontFamily:'Neuwelt',
      marginLeft:'0px',
      
    },
    rd_img:{
width:'20vw',
height:'30Vh',
marginLeft:'40%',
alignContent:'center',
[theme.breakpoints.down('sm')]: {

  width:'80vw',
height:'30Vh',
marginLeft:'5%',
alignContent:'center',
},
    },
    expanded: {
      backgroundColor: '#fff200',
    },
    titel:{
        fontFamily:'Neuwelt'
    },
    
    sub:{
        fontFamily:'Neuwelt medium',
        textAlign:'justify',
        marginTop:'10px',
        marginBottom:'10px'
    }
  }));

const MyAccordion = () => {
  
 

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);
  
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);
  return (
    <div>
      {/* <Typography className={classes.titel} variant="h4" gutterBottom>
      Droga Research Grant
      </Typography> */}
      <Accordion className={classes.accordion}  expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.titel}>Formulation and Development</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel1' ? classes.expanded : ''}>
          <div>
            <div>
            <img className={classes.rd_img}  src={Picture11}/>
            </div>
          <Typography className={classes.sub}>
          It focuses on developing formulations of pharmaceuticals, Nutraceuticals, Food Supplements and cosmetical products in various dosage forms (oral solid dosage forms, oral liquid dosage forms, and semi-solid dosage forms). Droga Pharma’s Research and Development Department was established in 2021 with the aim of building R&D center of excellence in the country. The center aims to introduce innovative and quality products on areas of pharmaceutical, food, cosmetics and others. Currently, R&D department is working on areas of, among the others, Active Pharmaceutical Ingredient (API) and excipient discovery; formulation development; and provision of quality control services. The department majorly comprises of four units: Research Laboratory, Formulation & Development, Quality control and Droga Science. 
          </Typography>
          <div >
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>The activities under this include, but not limited to the following:</p>
          <ul className={classes.detail}>
          
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px' ,display:'flex',width:'280px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>  
            Formulations of pharmaceuticals

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
              <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Formulation of herbal medicines

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Formulations of nutraceuticals

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            The unit develops

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Solid Oral dosage forms

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Oral liquid dosage forms

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Semi solids & Injectable

            </li>
          </ul>
          </div>
          </div>
          
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordion} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.titel}>Research Labratory</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel2' ? classes.expanded : ''}>
          <div>
            <div>
            <img className={classes.rd_img} src={Picture12}/>
            </div>
          <Typography className={classes.sub}>
          This unit works on the screening, identification, extraction of medicinal plants and development of API excipients from natural sources for pharmaceutical, food and cosmetics purpose. Currently the unit is working on the extraction and characterization of fixed and volatile oils: Black Cumin oil, Castor oil, Ginger oil, Rosemery oil, Eucalyptus oil alongside the extraction of two medicinal plants. Moreover, investigations are being carried out with partners on two local herbs for their medicinal values. 
          </Typography>
          <div >
          <ul className={classes.detail}>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
              
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Screening of selected medicinal plants for activities

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Discovery of new compounds or APIs
  
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
              <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
              Conducting pre-formulation studies
    
                </li>
                <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
                <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
                Conducting clinical trials for promising formulations of products
    
                </li>
                <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
                <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
              Excipient discovery and development researches
  
              </li>
          </ul>     
          </div>
          </div>
          
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.titel}>Quality Control Tests</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel3' ? classes.expanded : ''}>
          <div>
            <div>
            <img className={classes.rd_img} src={RandD}/>
            </div>
          <Typography className={classes.sub}>
          Various QC tests are conducted for products obtained from different centers, industries, academic and other research institutes and regulatory bodies. within its physicochemical and microbiological laboratories, Droga R&D department will be engaged in:
          </Typography>
          <div >
          <ul className={classes.detail}>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
              
            Establishment of the analytical procedures and specifications for API and drug products. 

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
              
            Preparation of stability protocols
  
            </li>

            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
              
            Pursues analytical tests and stability studies of API and drug products
    
              </li>

              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
              <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
              
              In-house analytical method development laboratory with stability chambers
      
                </li>
                <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
                <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
              
                Standardization of traditional medicines
      
                </li>

                <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
                <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
              
                Provision of pharmacovigilance services
    
              </li>
          </ul>     
          </div>
          </div>
          
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.titel}>Bioequivalence Center</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel4' ? classes.expanded : ''}>
          <div>
            <div>
            <img className={classes.rd_img} src={RandD7}/>
            </div>
          <Typography className={classes.sub}>
          The department is involved in doing lead optimizations, preclinical evaluation safety/tolerability testing and post market surveillances. Basically, Conducting bioequivalence (BE) studies of generic products for both local pharmaceutical manufacturers and regional ones.
          </Typography>
          <div >
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>The activities under this include the following:</p>
          <ul className={classes.detail}>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Establishment of the laboratory for bioequivalence studies of generic products

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Establishment of a clinical center with 16 bed capacity
  
            </li>

            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Preparation of BE study protocol
    
              </li>

            
          </ul>     
          </div>
          </div>
          
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography className={classes.titel}>Calibration Center</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel5' ? classes.expanded : ''}>
          <div>
            <div>
            <img className={classes.rd_img} src={RandD8}/>
            </div>
          <Typography className={classes.sub}>
          The department is involved in doing lead optimizations, preclinical evaluation safety/tolerability testing and post market surveillances. Basically, Conducting bioequivalence (BE) studies of generic products for both local pharmaceutical manufacturers and regional ones.
          </Typography>
          <div >
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>The following calibration services will be offered by the center:</p>
          <ul className={classes.detail}>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Pharmaceutical equipment

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Medical diagnostic equipment
  
            </li>

            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Laboratory testing equipment
    
              </li>

            
          </ul>     
          </div>
          </div>
          
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography className={classes.titel}>Consulting services</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel6' ? classes.expanded : ''}>
          <div>
            <div>
            <img className={classes.rd_img} src={RandD9}/>
            </div>
          <Typography className={classes.sub}>
          Consultations regarding the risk assessment of pharmaceutical manufacturing by mainly focusing on formulation potentials, developments and risks present on different dosage forms are done.
          </Typography>
          <div >
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>The following calibration services will be offered by the center:</p>
          <ul className={classes.detail}>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Formulation and Manufacturing Processes Consulting

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Analytical method development & method validation
  
            </li>

            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Scale-Up/Technical Transfer Assistance
    
            </li>

            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Formulation Optimization/Rationalization
    
            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Packaging development support
      
              </li>

            
          </ul>     
          </div>
          </div>
          
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion} expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography className={classes.titel}>Partnership</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel7' ? classes.expanded : ''}>
          <div>
            <div>
            <img className={classes.rd_img} src={RandD10}/>
            </div>
          <Typography className={classes.sub}>
          Droga Research and Development Department works with a wide range of partners from different sectors. We continuously learn from experiences with our cooperation partners and evaluate our partner portfolio to deliver the best possible collaboration & assistance in each context. 
          </Typography>
         
          </div>
          
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordion} expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography className={classes.titel}>Droga science</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel8' ? classes.expanded : ''}>
          <div>
            <div>
            <img className={classes.rd_img} src={Picture14}/>
            </div>
          <Typography className={classes.sub}>
          Droga Pharma provides research grants and trainings for young and senior researchers from academic and research institutions and organizes scientific conferences.
          </Typography>
          <div >
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>Scientific conferences and trainings</p>
          <ul className={classes.detail}>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Organizing annual scientific conference

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Organizing problem solving training for pharmaceutical industries
  
            </li>

            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Organizing and providing training regarding BE studies for interested bodies (academic and industries).
    
            </li>

            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Formulation Optimization/Rationalization
    
            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Packaging development support
      
              </li>

            
          </ul>     
          </div>
          <div >
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>Grants/ Awards</p>
          <ul className={classes.detail}>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Provide research grant for young and senior researchers from academic and research institutions

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Droga Pharma PLC is pleased to announce the first round of DRG award winners for the year 2022.  The company’s grant award was launched in spring of 2022 to encourage & support junior & senior researchers that focus & are engaged in, health related problem solving, researches. For the year 2022 grants were to be awarded to research proposals that were within the thematic areas of, Excipient Development & Modification, Pharmaceutical Formulation Development, Nutraceutical Formulation Development, Food Supplements Formulation Development & Herbal Medicines. We are therefore happy to announce that Droga Research Grant 2022 has been awarded to two winning research proposals.
  
            </li>

                <div>
                <p style={{fontFamily:'Neuwelt', marginBottom:'20px',marginLeft:'40%'}}>Congratulations to winners! </p>

                </div>

            
          </ul>     
          </div>
          </div>
          
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default MyAccordion;
