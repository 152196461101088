// import React, {useState ,useEffect} from 'react';
// import './Newsdetails.css';
// import proimg10 from '../../../assets/proimg10.jpg';
// import proimg12 from '../../../assets/proimg12.jpg';
// import Epss1 from '../../../assets/Epss1.jpg';
// import proimg7 from '../../../assets/proimg7.jpg';

// import proimg9 from '../../../assets/proimg9.jpg';

// import { Typography } from '@mui/material';
// import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
// import dro from '../../../assets/dro.png';




// export default function Newsdetails() {


//   useEffect(() => {
//     const handler = e => setIsMobile(e.matches);
//     window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
//   }, []);
//   const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
//   useEffect(() => {
//     const handler = e => setIsDesktop(e.matches);
//     window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
//   }, []);
  
//   const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);
//   return (
//     <>

// <div  className={` ${isMobile ? "product_detail_div-mobile" : (isDesktop ? "product_detail_div-desktop" : "")}`} style={{ 
//      background: `url(${require('../../../assets/e001.jpg') })`, // replace './path_to_your_image.jpg' with the actual path to your image
//      backgroundSize: 'cover',
//      padding: '15px' ,animation: 'fadeIn 5s'}} >
//       <div className={` ${isMobile ? "yellow_product_detail_cover-mobile" : (isDesktop ? "yellow_product_detail_cover-desktop" : "")}`}/>
//          <div   className={` ${isMobile ? "Group_9-mobile" : (isDesktop ? "Group_9-desktop" : "")}`}>
          
//            <div   className={` ${isMobile ? "Product_detail_titel-mobile" : (isDesktop ? "Product_detail_titel-desktop" : "")}`}style={{animation: 'fadeIn 5s'}}>News Details  
// </div>
          
 
//         </div>


// </div>
//   <div  className={` ${isMobile ? "Productdetails-mobile" : (isDesktop ? "Productdetails-desktop" : "")}`} style={{position:"relative"}}>
//     <div  className={` ${isMobile ? "newss_deta-mobile" : (isDesktop ? "newss_deta-desktop" : "")}`}>
  
//     <div  className={` ${isMobile ? "news_detail_card_all-mobile" : (isDesktop ? "news_detail_card_all-desktop" : "")}`}>
// <div  className={` ${isMobile ? "news_detail_card-mobile" : (isDesktop ? "news_detail_card-desktop" : "")}`}>

// <div  className={` ${isMobile ? "productdetailcard-mobile" : (isDesktop ? "productdetailcard-desktop" : "")}`}>
// <div   className={` ${isMobile ? "productdetailcardback-mobile" : (isDesktop ? "productdetailcardback-desktop" : "")}`}>

// <img   className={` ${isMobile ? "productdetailcardimg-mobile" : (isDesktop ? "productdetailcardimg-desktop" : "")}`}  src={Epss1} />

// </div>

// </div>
// <div   className={` ${isMobile ? "productdetailtxt-mobile" : (isDesktop ? "productdetailtxt-desktop" : "")}`}>

//     <h2  className={` ${isMobile ? "product_detailtitel-mobile" : (isDesktop ? "product_detailtitel-desktop" : "")}`}>Droga Pharma Plc announces Droga Orthopedic Research grant 2022 </h2>
//     <p  className={` ${isMobile ? "productdetailtxt2-mobile" : (isDesktop ? "productdetailtxt2-desktop" : "")}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s .Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
//     <p className={` ${isMobile ? "productdetailtxt2-mobile" : (isDesktop ? "productdetailtxt2-desktop" : "")}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s .Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
//     <p className={` ${isMobile ? "productdetailtxt2-mobile" : (isDesktop ? "productdetailtxt2-desktop" : "")}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s .Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
//     <p className={` ${isMobile ? "productdetailtxt2-mobile" : (isDesktop ? "productdetailtxt2-desktop" : "")}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s .Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
//     <p className={` ${isMobile ? "productdetailtxt2-mobile" : (isDesktop ? "productdetailtxt2-desktop" : "")}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s .Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
//     <p className={` ${isMobile ? "productdetailtxt2-mobile" : (isDesktop ? "productdetailtxt2-desktop" : "")}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s .Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>

// </div>
// </div> 

// </div>
// <div className={` ${isMobile ? "news_search-mobile" : (isDesktop ? "news_search-desktop" : "")}`}>
//     <div  className={` ${isMobile ? "side_container-mobile" : (isDesktop ? "side_container-desktop" : "")}`}>
//   <div className="searchForm"  >
//       <Typography style={{  paddingBottom: '20px',fontWeight: '500',fontFamily: 'Neuwelt'}} variant="h6" >
//         Search
//       </Typography>
    
//     <form>
//       <input style={{fontSize:'16px', width:'150%',border:'1px solid #eee'}} type="text" placeholder="Search by title" />
  
//     </form>

//   </div>
//   </div>
//   <div  className={` ${isMobile ? "side_container_2-mobile" : (isDesktop ? "side_container_2-desktop" : "")}`}>
//   <div>
//     <div className='pop_post'>
//       <Typography style={{  paddingBottom: '20px',fontWeight: '500',fontFamily: 'Neuwelt'}}  variant="h6" >
//         Catagory
//       </Typography>
//     <ul  className={` ${isMobile ? "cata_ul-mobile" : (isDesktop ? "cata_ul-desktop" : "")}`}>
//       <li><a>Medical Device</a></li>
//       <li><a>Medicines</a></li>
//       <li><a>Company</a></li>
//       <li><a>EMA</a></li>
//       <li><a>Trust</a></li>
//       <li><a>Droga</a></li>
//     </ul>
//     </div>
     
    
//   </div>
//   </div>
//   <div  className={` ${isMobile ? "side_container_3-mobile" : (isDesktop ? "side_container_3-desktop" : "")}`}>
//   <div>
//     <div className='pop_post'>
//       <Typography style={{  paddingBottom: '20px',fontWeight: '500',fontFamily: 'Neuwelt'}} variant="h6" >
//         Recent Post
//       </Typography>
// <div className='new_detail_blog' >
//   <a className='new_detail_image'>
//     <img className='new_detail_image_sub'  src={proimg7}/>
//   </a>
//   <div   className={` ${isMobile ? "popular_post_content-mobile" : (isDesktop ? "popular_post_content-desktop" : "")}`}>
//     <h5>
//       <a>What are IT teams and What do they do?</a>
//     </h5>
//     <span>30 0ctober 2025</span>
//   </div>
// </div>
// <div className='new_detail_blog'>
//   <a className='new_detail_image'>
//     <img className='new_detail_image_sub' src={proimg9}/>
//   </a>
//   <div className={` ${isMobile ? "popular_post_content-mobile" : (isDesktop ? "popular_post_content-desktop" : "")}`}>
//     <h5>
//       <a>What are IT teams and What do they do?</a>
//     </h5>
// <span >30 0ctober 2025</span>
//   </div>
// </div>
// <div className='new_detail_blog'>
//   <a className='new_detail_image'>
//     <img className='new_detail_image_sub' src={proimg12}/>
//   </a>
//   <div className={` ${isMobile ? "popular_post_content-mobile" : (isDesktop ? "popular_post_content-desktop" : "")}`}>
//     <h5>
//       <a>What are IT teams and What do they do?</a>
//     </h5>
//     <span>30 0ctober 2025</span>
//   </div>
// </div>
//     </div>
     
    
//   </div>
//   </div>
//     </div>
//     </div>

 


   

  
// </div>



  



// <div className="RelatedProduct" >
   
  

//    <div className={` ${isMobile ? "comment_section-mobile" : (isDesktop ? "comment_section-desktop" : "")}`}>
//   <div>
//     <div   className={` ${isMobile ? "com_all-mobile" : (isDesktop ? "com_all-desktop" : "")}`}>
//       <Typography  className={` ${isMobile ? "com_all_txt-mobile" : (isDesktop ? "com_all_txt-desktop" : "")}`}>
//         Leave Your Comment 
//       </Typography>
// <div   className={` ${isMobile ? "com_name_email-mobile" : (isDesktop ? "com_name_email-desktop" : "")}`}>
// <div  className={` ${isMobile ? "comment_name_email-mobile" : (isDesktop ? "comment_name_email-desktop" : "")}`}>
// <input  className={` ${isMobile ? "com_name-mobile" : (isDesktop ? "com_name-desktop" : "")}`} type="text" placeholder="Your Name" />
// </div>
// <div  className={` ${isMobile ? "comment_name_email-mobile" : (isDesktop ? "comment_name_email-desktop" : "")}`}>

// <input  className={` ${isMobile ? "com_email-mobile" : (isDesktop ? "com_email-desktop" : "")}`} type="text" placeholder="Email" />

// </div>

// </div>
// <div   className={` ${isMobile ? "com_texarea-mobile" : (isDesktop ? "com_texarea-desktop" : "")}`}>

//   <textarea  className={` ${isMobile ? "com_texarea_sub-mobile" : (isDesktop ? "com_texarea_sub-desktop" : "")}`}  placeholder='Your Message'></textarea>

// </div>
//         <div >
//         <button  className={` ${isMobile ? "com_btn-mobile" : (isDesktop ? "com_btn-desktop" : "")}`}>Submit</button>
      
//         </div>

     
//     </div>
     
    
//   </div>
//   </div>
// </div>



// <div  className={isMobile ? "Group200-mobile" : "Group200"} >

// <div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

//    <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/>

 
 
//    <div  className={` ${isMobile ? "footer_Sub_txt1-mobile" : (isDesktop ? "footer_Sub_txt1-desktop" : "")}`}>


//     <div   className={` ${isMobile ? "footer_Sub_txt_1_1-mobile" : (isDesktop ? "footer_Sub_txt_1_1-desktop" : "")}`}>
    
//           Ethiopia
//     </div>
//     <div   className={` ${isMobile ? "footer_Sub_para-mobile" : (isDesktop ? "footer_Sub_para-desktop" : "")}`}> 
//     <div  className="footer_sub_txt_3" >
//     <RadioButtonCheckedIcon
//     style={{
//       position: 'relative',
//       right: 10,
//       top: 0,
//       color: '#000',
//       fontSize:'20px',
     
//     }}
//   />
//   Gulele Subcity, Addis Ababa, Ethiopia 
//     </div>

//     <div  className="footer_sub_txt_3" >
//     <RadioButtonCheckedIcon
//     style={{
//       position: 'relative',
//       right: 10,
//       top: 0,
//       color: '#000',
//       fontSize:'20px',
      
//     }}
//   />
//   +251112734554 / +251913667537
//     </div>
//     <div  className="footer_sub_txt_3" >
//     <RadioButtonCheckedIcon
//     style={{
//       position: 'relative',
//       right: 10,
//       top: 0,
//       color: '#000',
//       fontSize:'20px',
      
//     }}
//   />
//   info@drogapharma.com
//     </div>
//        </div>
//    </div>
     
//    <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


// <div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
//     Somaliland
// </div>
// <div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
// <div  className="footer_sub_txt_3" >
//     <RadioButtonCheckedIcon
//     style={{
//       position: 'relative',
//       right: 10,
//       top: 0,
//       color: '#000',
//       fontSize:'20px',
      
//     }}
//   />Hargeysa , Somaliland
//     </div>
//     <div  className="footer_sub_txt_3" >
//     <RadioButtonCheckedIcon
//     style={{
//       position: 'relative',
//       right: 10,
//       top: 0,
//       color: '#000',
//       fontSize:'20px',
      
//     }}
//   />+2512634958444/+2512637611171
//     </div>
//     <div  className="footer_sub_txt_3" >
//     <RadioButtonCheckedIcon
//     style={{
//       position: 'relative',
//       right: 10,
//       top: 0,
//       color: '#000',
//       fontSize:'20px',
      
//     }}
//   />info@drogapharma-som.com
//     </div>
//    </div>
//   </div>


// <div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


//     <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
//         Quick Links
//     </div>
//     <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
//     <div   className="footer_sub_txt_3">
//     <RadioButtonCheckedIcon
//     style={{
//       position: 'relative',
//       right: 10,
//       top: 0,
//       color: '#000',
//       fontSize:'20px',
     
//     }}
//   />Home
//     </div>
//     <div className="footer_sub_txt_3" >
//     <RadioButtonCheckedIcon
//     style={{
//       position: 'relative',
//       right: 10,
//       top: 0,
//       color: '#000',
//       fontSize:'20px',
      
//     }}
//   />About Us
//     </div>
//     <div className="footer_sub_txt_3" >
//     <RadioButtonCheckedIcon
//     style={{
//       position: 'relative',
//       right: 10,
//       top: 0,
//       color: '#000',
//       fontSize:'20px',
      
//     }}
//   />Contact Us
//     </div>

//        </div>
//    </div>
   


 
// </div>
//    </div>
// <div className="footer_right_hm">

// <div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

// </div>

//     </>
    
//   );
// }
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';



import './Newsdetails.css';


import { Typography } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import dro from '../../../assets/dro.png';
import {CiCalendarDate} from "react-icons/ci";
import { useNavigate } from 'react-router-dom';


function NewsDetails() {
  const [newsItem, setNewsItem] = useState(null);
  const { pk } = useParams(); // Assuming the route parameter is named "pk"

  const [recentBlog,setRecentBlog] = useState([]);
  const [blogCatagories,setBlogCatagories] = useState([]);
  

  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/news/blogdetailgetall/')  
          .then((response) => {
            console.log(response.data[1]); 
            setRecentBlog(response.data);
            
          })
          .catch((error) => {
            console.error('Error fetching blog recentBlog:', error);
           
          });
  
  }, []);


  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/blogcatagoriesgetall/')  
          .then((response) => {
            console.log(response.data[0]); 
            setBlogCatagories(response.data);
            
          })
          .catch((error) => {
            console.error('Error fetching blog recentBlog:', error);
           
          });
  
  }, []);


  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);
  
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);

  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(`https://backend.drogapharmacy.com/api/${pk}`) // Replace "api/" with the actual API endpoint to fetch a single news item
      .then((response) => {
        console.log(response.data);
        setNewsItem(response.data);
      })
      .catch((error) => {
        console.error('Error fetching news item:', error);
      });
  }, [pk]);


  if (newsItem === null) {
    return <p>Loading...</p>;
  }



  return (


    <div>
      


<div  className={` ${isMobile ? "product_detail_div-mobile" : (isDesktop ? "product_detail_div-desktop" : "")}`} style={{ 
     background: `url(${require('../../../assets/e001.jpg') })`, // replace './path_to_your_image.jpg' with the actual path to your image
     backgroundSize: 'cover',
     padding: '15px' ,animation: 'fadeIn 5s'}} >
      <div className={` ${isMobile ? "yellow_product_detail_cover-mobile" : (isDesktop ? "yellow_product_detail_cover-desktop" : "")}`}/>
         <div   className={` ${isMobile ? "Group_9-mobile" : (isDesktop ? "Group_9-desktop" : "")}`}>
          
           <div   className={` ${isMobile ? "Product_detail_titel-mobile" : (isDesktop ? "Product_detail_titel-desktop" : "")}`}style={{animation: 'fadeIn 5s'}}>News Details  
</div>

</div>

</div>



<div  className={` ${isMobile ? "Productdetails-mobile" : (isDesktop ? "Productdetails-desktop" : "")}`} style={{position:"relative"}}>
    <div  className={` ${isMobile ? "newss_deta-mobile" : (isDesktop ? "newss_deta-desktop" : "")}`}>
  
    <div  className={` ${isMobile ? "news_detail_card_all-mobile" : (isDesktop ? "news_detail_card_all-desktop" : "")}`}>
<div  className={` ${isMobile ? "news_detail_card-mobile" : (isDesktop ? "news_detail_card-desktop" : "")}`}>

<div  className={` ${isMobile ? "productdetailcard-mobile" : (isDesktop ? "productdetailcard-desktop" : "")}`}>
<div   className={` ${isMobile ? "productdetailcardback-mobile" : (isDesktop ? "productdetailcardback-desktop" : "")}`}>

<img   className={` ${isMobile ? "productdetailcardimg-mobile" : (isDesktop ? "productdetailcardimg-desktop" : "")}`}  src={newsItem.image} />

</div>

</div>
<div   className={` ${isMobile ? "productdetailtxt-mobile" : (isDesktop ? "productdetailtxt-desktop" : "")}`}>

    <h2  className={` ${isMobile ? "product_detailtitel-mobile" : (isDesktop ? "product_detailtitel-desktop" : "")}`}>{newsItem.title} </h2>
        
    <p  className={` ${isMobile ? "productdetailtxt2-mobile" : (isDesktop ? "productdetailtxt2-desktop" : "")}`}>{newsItem.content}</p>
    {/* <p className={` ${isMobile ? "productdetailtxt2-mobile" : (isDesktop ? "productdetailtxt2-desktop" : "")}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s .Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
    <p className={` ${isMobile ? "productdetailtxt2-mobile" : (isDesktop ? "productdetailtxt2-desktop" : "")}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s .Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
    <p className={` ${isMobile ? "productdetailtxt2-mobile" : (isDesktop ? "productdetailtxt2-desktop" : "")}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s .Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
    <p className={` ${isMobile ? "productdetailtxt2-mobile" : (isDesktop ? "productdetailtxt2-desktop" : "")}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s .Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
    <p className={` ${isMobile ? "productdetailtxt2-mobile" : (isDesktop ? "productdetailtxt2-desktop" : "")}`}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s .Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Lorem Ipsum has been the industry's standard dummy text ever since the 1500sLorem Ipsum has been the industry's standard dummy text ever since the 1500s</p> */}

</div>
</div> 

</div>
<div className={` ${isMobile ? "news_search-mobile" : (isDesktop ? "news_search-desktop" : "")}`}>
    {/* <div  className={` ${isMobile ? "side_container-mobile" : (isDesktop ? "side_container-desktop" : "")}`}>
  <div className="searchForm"  >
      <Typography style={{  paddingBottom: '20px',fontWeight: '500',fontFamily: 'Neuwelt'}} variant="h6" >
        Search
      </Typography>
    
    <form>
      <input style={{fontSize:'16px', width:'150%',border:'1px solid #eee'}} type="text" placeholder="Search by title" />
  
    </form>

  </div>
  </div> */}
  <div  className={` ${isMobile ? "side_container_2-mobile" : (isDesktop ? "side_container_2-desktop" : "")}`}>
  <div>
    <div className='pop_post'>
      <Typography style={{  paddingBottom: '20px',fontWeight: '500',fontFamily: 'Neuwelt'}}  variant="h6" >
        Catagory
      </Typography>
    {/* <ul  className={` ${isMobile ? "cata_ul-mobile" : (isDesktop ? "cata_ul-desktop" : "")}`}>
      <li><a>{blogCatagories.title}</a></li>
     
  
    </ul> */}
   
 {blogCatagories.map((category) => (
   <ul key={category.id} className={` ${isMobile ? "cata_ul-mobile" : (isDesktop ? "cata_ul-desktop" : "")}`}>
   <li >
     <a>{category.title}</a>
   </li>
   </ul>
 ))}

    </div>
     
    
  </div>
  </div>

      <div  className={` ${isMobile ? "side_container_3-mobile" : (isDesktop ? "side_container_3-desktop" : "")}`}>
      <div>
        <div className='pop_post'>
          <Typography style={{  paddingBottom: '20px',fontWeight: '500',fontFamily: 'Neuwelt'}} variant="h6" >
            Recent Post
          </Typography>
    {/* <div className={` ${isMobile ? "new_detail_blog-mobile" : (isDesktop ? "new_detail_blog-desktop" : "")}`} >
      <a className='new_detail_image'>
        <img className='new_detail_image_sub'  src={recentBlog.image}/>
      </a>
      <div   className={` ${isMobile ? "popular_post_content-mobile" : (isDesktop ? "popular_post_content-desktop" : "")}`}>
        <h5>
          <a class=' w-22  inline-block text-decoration-none text-black'>{recentBlog.title}</a>
        </h5>
        
        <span class='text-sm w-44 pt-0 inline-block'> <CiCalendarDate style={{color:'#000',display:'inline-block',fontSize:'20px',paddingRight:'5px'}}/>{recentBlog.date_posted}</span>
      </div>
    </div>
    <div className={` ${isMobile ? "new_detail_blog-mobile" : (isDesktop ? "new_detail_blog-desktop" : "")}`}>
      <a className='new_detail_image'>
        <img  className={` ${isMobile ? "new_detail_image_sub-mobile" : (isDesktop ? "new_detail_image_sub-desktop" : "")}`} src={recentBlog.image}/>
      </a>
      <div className={` ${isMobile ? "popular_post_content-mobile" : (isDesktop ? "popular_post_content-desktop" : "")}`}>
        <h5>
          <a class=' w-14 mr-5  inline-block text-decoration-none text-black'>{recentBlog.title}</a>
        </h5>
        <span class='text-sm w-44 pt-0 inline-block justify-end'> <CiCalendarDate style={{color:'#000',display:'inline-block',fontSize:'20px',paddingRight:'5px'}}/>{recentBlog.date_posted}</span>
    
      </div>
    </div> */}
      {recentBlog && recentBlog.map((recentBlog) => {
    return (  
    <div className={` ${isMobile ? "new_detail_blog-mobile" : (isDesktop ? "new_detail_blog-desktop" : "")}`}>
      {/* <a className='new_detail_image'>
        <img className='new_detail_image_sub-desktop' src={recentBlog.image}/>
      </a> */}
      <div className={` ${isMobile ? "popular_post_content-mobile" : (isDesktop ? "popular_post_content-desktop" : "")}`}>
        <h5>
          <a class=' w-16 mr-5 inline-block text-decoration-none text-black'>{recentBlog.title}</a>
        </h5>
        <span class='text-sm w-44 pt-0 inline-block '> <CiCalendarDate style={{color:'#000',display:'inline-block',fontSize:'18px',paddingRight:'5px'}}/>{recentBlog.date_posted}</span>
       
      </div>
    </div>
    );
  })}
        </div>
         
        
      </div>
      </div>

 
    </div>
    </div>

 


   

  
</div>
     
<div  className={isMobile ? "Group200-mobile" : "Group200"} >

<div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

   {/* <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/> */}
   
   <div  className={` ${isMobile ? "footer_Sub_txt_5-mobile" : (isDesktop ? "footer_Sub_txt_5-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_5_5-mobile" : (isDesktop ? "footer_Sub_txt_5_5-desktop" : "")}`}>
    
          Ethiopia
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_5-mobile" : (isDesktop ? "footer_Sub_para_5-desktop" : "")}`}> 
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
 
 

     
   <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


<div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
    Somaliland
</div>
<div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
<div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />Hargeysa,Somaliland 
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />+252634958444

    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
        Quick Links
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
    <div   className="footer_sub_txt_3">
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  /> <a href='/' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Home</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />  <a onClick={() => { navigate('/aboutus');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}> About Us</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  /> <a onClick={() => { navigate('/get');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Contact Us</a>
    </div>

       </div>
   </div>
   


 
</div>
   </div>
<div className="footer_right_hm">

<div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

</div>

    </div>
  );
}

export default NewsDetails;