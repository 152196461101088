import React, { useState,useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles  } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { FaFilePdf } from "react-icons/fa6";









const useStyles = makeStyles((theme) => ({
    accordion: {
      // adjust this value to your needs
      margin: '10px auto', // center the accordion horizontally
      backgroundColor: '#F1F0E8',
      '&:hover': {
        backgroundColor: '#fff200',
      },
    },
    detail:{
      fontFamily:'Neuwelt',
      marginLeft:'0px',
      
    },
    rd_img:{
width:'20vw',
height:'30Vh',
marginLeft:'40%',
alignContent:'center',
[theme.breakpoints.down('sm')]: {

  width:'80vw',
height:'30Vh',
marginLeft:'5%',
alignContent:'center',
},
    },
    expanded: {
      backgroundColor: '#fff200',
    },
    titel:{
        fontFamily:'Neuwelt'
    },
    
    sub:{
        fontFamily:'Neuwelt medium',
        textAlign:'justify',
        marginTop:'10px',
        marginBottom:'10px'
    }
  }));

const MyAccordion = () => {
  
 

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);
  
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);


  return (
    <div>
      {/* <Typography className={classes.titel} variant="h4" gutterBottom>
      Droga Research Grant
      </Typography> */}
      <Accordion className={classes.accordion}  expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.titel}>DROGA RESEARCH GRANT</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel1' ? classes.expanded : ''}>
          <div>
            {/* <div>
            <img className={classes.rd_img}  src={Picture11}/>
            </div> */}
          {/* <Typography className={classes.sub}>
          Droga research grant is an annual research grant that is organized by Droga Pharma PLC in the thematic areas of:
          </Typography> */}
          <div >
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>Droga research grant is an annual research grant that is organized by Droga Pharma PLC in the thematic areas of:</p>
          <ul className={classes.detail}>
          
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px' ,display:'flex'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>  
            API Discovery & Excipient characterization
            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
              <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
              Herbal Medicine
            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Food Supplements & Nutraceuticals

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Formulation Development



            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Cosmetics
            </li>
       
          </ul>
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>The grant is announced in January of each year and applications are received until February. Since 2022 the grant has been awarded to three winning proposals, Namely:</p>
          <ul className={classes.detail}>
          
          <li style={{fontFamily:'Neuwelt', marginBottom:'10px' ,display:'flex'}}>
          <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>  
          Evaluation of the hypolipidemic effect of leaf extract of Lippia Adoensis Koseret in hypercholesterolemic mice.
          </li>
          <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Exploration Of Spirulina (Arthrospira Fusiformis) And Oyster Mushroom Formulation as Potential Nutraceuticals for improved Nutrition and Better Human Health
          </li>
          <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
          <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
          Effect of replacement of Wheat with fava bean and black cumin flours on nutritional properties and sensory attributes of bread.

          </li>
          <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
          <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
          Formulation Development



          </li>
   
     
        </ul>
        <p style={{fontFamily:'Neuwelt', marginBottom:'10px',fontWeight:'900'}}><strong>With 71.984.00 ETB, 100,000.00ETB and 62,800.00ETB respectively.</strong></p>
        <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>Now, Droga Pharma PLC announces the 2024 Droga Research Grant: Read the information below on eligibility criteria and how to apply to the 2024-DRG</p>


          </div>
      
          </div>
          
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordion} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.titel}>STEPS AND ELIGIBILITY CRITERIA FOR DROGA RESEARCH GRANT 2024</Typography>
        </AccordionSummary>
        <AccordionDetails className={expanded === 'panel2' ? classes.expanded : ''}>
          <div>
     
          <div >
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>I. Introduction</p>

          <ul className={classes.detail}>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
              
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            DROGA PHARMA PLC welcomes DRG applications for projects working towards addressing the problems on health and related areas to improve people lives. It will be prioritizing applications that can demonstrate that they are responding to the challenges the community and the country are facing.

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Only one request, per applicant, per year is permitted for DRG. Requested fund cannot exceed 100,000 ETB (hundred thousand birr) per project for a maximum of one year. Funding of research projects that involve human subjects or animals must have documented Institutional Review Board (IRB) approval, prior to the release of funds. Requests for no-cost extensions are granted only under special circumstances and on a case-by-case basis.
  
              </li>
            
          </ul>     
          </div>
          <div >
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>II. Scope</p>
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>The 2024 Droga Research Grant will be granted to projects/researches in of the following areas/fields:</p>


          <ul className={classes.detail}>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
              
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            API Discovery & Excipient Characterization

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Herbal medicine
  
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Food supplements & Nutraceuticals
  
              </li>

              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Formulation Development
  
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Cosmetics
  
              </li>
          </ul>     
          </div>
          <div >
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>1. Eligibilit</p>
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>Who is eligible for the DRG?</p>
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>Applicants must fulfill the following to be considered eligible:</p>



          <ul className={classes.detail}>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
              
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            The project/ research should be set up in Ethiopia

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            The applicant must be a holder of an Ethiopian citizenship
  
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            The applicant should be a student/researcher/lecture/professional under the fields of study of Biology, Botany, Chemistry, Medicine, Nursing, Nutrition, Pharmacy
  
              </li>

              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            The applicant can also be any healthcare professional working either in a government or private set up
  
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Applicants from academic institution or research organization are highly encouraged.
  
              </li>
          </ul>     
          </div>
          <div >
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>2. Droga Research Grant application timeline</p>
         



          <ul className={classes.detail}>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
              
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Call for application to DRG 2024 is made on the second week of January 2024

            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Only applications submitted before 23:59 of the closing deadline date will be reviewed
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Only electronic submissions via http://group.drogapharma.com/RD/ will be accepted
              </li>

              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Proposals can be submitted from January 15 to February 15 2024
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Research Grants are for a maximum of one year
              </li>
          </ul>     
          </div>
          <div >
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>3. Funding</p>
         



          <ul className={classes.detail}>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
              
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            The award is a fixed-price grant of up to 100,000.00 ETB (hundred thousand birr) per project per year
            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            An applicant will not be funded for more than one project per year
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            An initial payment of fifty percent (50%) of the total awarded amount is distributed upon the execution of the research agreement and assignment of copyright.
              </li>

              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            A second-round payment of twenty percent (20%) of the awarded amount is granted upon a progress assessment by the organizing body (Droga Pharma PLC)
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            A final payment of thirty percent (30%) of the total awarded amount is distributed upon delivery of the final report to DROGA PHARMA PLC
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            The first installment of the grant will be awarded directly to the researcher after announcement & MOU signing
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            The grant's contract administrator cannot be the research advisor.
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Grants can be used to support doctoral, master’s and undergraduate thesis research, directed study, or research projects
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Funds cannot be used for applicant(s) salary support.
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Candidates must disclose/ demonstrate that the DRG is sufficient to complete the research or that additional sources of funding exist to enable completion of the research.
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
            <RadioButtonCheckedIcon style={{fontSize:'18px'}}/>
            Any applicants that have previously received funding for a DRG must provide information on prior funding including publications resulting from the grant
              </li>
          </ul>     
          </div>
          <div >
          <p style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>4. Application Process</p>
         



          <ul className={classes.detail}>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
              
           
            Step 1. Download the PDF file below for the guidelines of writing the proposal and abstract.

           
            </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
                  <a href="DRG-2024-Proposal-Preparation-guideline.pdf" download="DRG-2024-Proposal-Preparation-guideline.pdf" style={{textDecoration:'none',color:'#000',fontFamily:'Neuwelt light'}}>
                      <FaFilePdf style={{color:'#000',display:'inline-block',fontSize:'18px',paddingRight:'5px'}}/>Download PDF
                      
                  </a>
              </li>
            <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
         
            Step 2.Complete the DRG application form below
              </li>
              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
           
            <strong>N.B.</strong> When filling the application form the applicant must upload his/her documents in both a PDF and WORD format. The documents should be put in a Zip file, and the name of the zip file should be "DRG2024APPLICANT NAME".
              </li>

              <li style={{fontFamily:'Neuwelt', marginBottom:'10px'}}>
          
             <strong>i.e.</strong> The documents to be uploaded are, CV of the applicant, CV of the supervisor /Advisor if any; two recommendation letters, letter of support/approval from the institution and proposal
              </li>
              
          </ul>     
          </div>
          </div>
          
        </AccordionDetails>
      </Accordion>

    
    </div>
  );
}

export default MyAccordion;
