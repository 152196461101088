import React, {useState ,useEffect} from 'react';
import './Vacancy.css';


import { useInView } from 'react-intersection-observer';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import f2 from '../../../assets/f2.png';
import {BsFillCheckCircleFill} from "react-icons/bs";
import {CiCalendarDate} from "react-icons/ci";
import {AiOutlineFolderOpen} from "react-icons/ai";
import {AiOutlineArrowRight} from "react-icons/ai";
import Popup from '../Popup/Popup';
import toast, { Toaster } from 'react-hot-toast';





import { useNavigate  } from 'react-router-dom';

import axios from 'axios';





export default function Vacancy() {




const [ref, inView] = useInView({
    triggerOnce: true, // Trigger the animation once
    threshold: 0.1  , // Percentage of the component's visibility for the animation to trigger
  });

  const variants = {
    hidden: { x: '50vw' }, // Starts from the left side outside the viewport
    show: {
      x: 0, // Ends at the original position
      transition: {
        duration: 0.8, // Animation duration in seconds
        ease: 'easeOut' // Easing function for the animation
      }
    }
  };
  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);

  // const [jobflash,setJobflash] = useState([]);

  // useEffect(() => {
  //   axios
  //         .get('https://backend.drogapharmacy.com/api/jobflashCrate/')  
  //         .then((response) => {
  //           console.log(response.data); 
  //           setJobflash(response.data);
            
  //         })
  //         .catch((error) => {
  //           console.error('Error fetching blog recentBlog:', error);
           
  //         });
  
  // }, []);
  
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);

  const navigate = useNavigate();
  const [buttonPopup_2,setButtonPopup_2] = useState(false);
  const [jobflash, setJobFlash] = useState([]);
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [linkedinProfile, setLinkedinProfile] = useState('');
  const [resume, setResume] = useState(null);
  const [introduction, setIntroduction] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [resulttt, setResult] = useState(false);

const handleSubmit = async (e) => {
 e.preventDefault();
 setIsSubmitting(true);
 const formData = new FormData();
 formData.append('name', name);
 formData.append('email', email);
 formData.append('phone', phone);
 formData.append('resume', resume);
 formData.append('introduction', introduction);

 if (resulttt) {
  toast.success('Your Messsage Successfully sent. We will contact you soon!');
}


 axios.post('https://backend.drogapharmacy.com/api/user_details/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
 })
 .then((response) => {
    console.log(response.data);
    setResult(true);
    e.target.reset();
    setIsSubmitting(false);
 })
 .catch((error) => {
    console.log(error);
    setIsSubmitting(false);
 });
};

 
  useEffect(() => {
    const fetchJobFlash = async () => {
      try {
        const response = await axios.get('https://backend.drogapharmacy.com/api/jobflashCrate/');
        console.log(response.data);
        setJobFlash(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching job flash:', error);
      }
    };

    fetchJobFlash();
  }, []);

  const isJobStillValid = (jobflash) => {
    const jobDate = new Date(jobflash.date_posted);
    jobDate.setHours(0, 0, 0, 0); 
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
   
     // Calculate the difference in milliseconds
  const timeDifference = currentDate - jobDate;

  // Calculate the difference in days
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  // Display job flashes posted within the last 30 days
  return daysDifference <= 60;
  };


  
  return (
    <>

<div className="news_detail_div" style={{ 
     background: `url(${require('../../../assets/carrer4.jpg') })`, // replace './path_to_your_image.jpg' with the actual path to your image
     backgroundSize: 'cover',
     padding: '15px' ,animation: 'fadeIn 5s'}} >
      <div  className={isMobile ? "yellow_career_detail_cover-mobile" : "yellow_career_detail_cover_vac"}/>
         <div className="Group199" style={{ height: "305px", position: 'relative' ,justifyContent:'center'}}>
          
           <div  className={isMobile ? "carrer_detail_titel-mobile" : "carrer_detail_titel"} style={{animation: 'fadeIn 5s'}}>Careers 
</div>
          
 
        </div>


</div>
  <div   className={` ${isMobile ? "newsdetails-mobile" : (isDesktop ? "newsdetails-desktop" : "")}`}  style={{position:"relative"}}>
   
         <div  className={` ${isMobile ? "carrer_detailstitel-mobile" : (isDesktop ? "carrer_detailstitel-desktop" : "")}`}>
          We're on a mission to build a great company  
          <p>that serve the People.</p> 
         </div>
         <div  className={` ${isMobile ? "carr_button-mobile" : (isDesktop ? "carr_button-desktop" : "")}`}>
     
  
           <button onClick={() => { navigate('/job');  }}  className={` ${isMobile ? "carr_Bn-mobile" : (isDesktop ? "carr_Bn-desktop" : "")}`}>  View Open positions</button>
      
  
       
         </div>


  </div>

  
  <div className="Mission" style={{background: `url(${require('../../../assets/carrer3.jpg') })` ,backgroundSize:'cover',padding: '50px' }} >
   <div className="Group199" style={{ width:'100px',height: 315, position: 'relative' ,justifyContent:'center'}}>
  </div>


</div>
  
<div  className={` ${isMobile ? "newsdetails-mobile" : (isDesktop ? "newsdetails-desktop" : "")}`}>
   
   <div   className={` ${isMobile ? "carrer_sub_titel-mobile" : (isDesktop ? "carrer_sub_titel-desktop" : "")}`}>
    <p>Droga Career</p>
    We build our company and products for the last 8 years with plans to offer a suit of next generation and bussiness that last a lifetime.we've created simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
   </div>



</div>

<div className="carr_cover" >
   

   <div    className={` ${isMobile ? "carrer_alll-mobile" : (isDesktop ? "carrer_alll-desktop" : "")}`}>

     
   <div   className={` ${isMobile ? "carrer_desc-mobile" : (isDesktop ? "carrer_desc-desktop" : "")}`}>


<div >

<div   className={` ${isMobile ? "carrer_tit2-mobile" : (isDesktop ? "carrer_tit2-desktop" : "")}`}>
Working at <strong style={{fontFamily:'Neuwelt'}}>Droga Pharma P.L.C</strong>
</div>
 <div   className={` ${isMobile ? "carrer_text_sub-mobile" : (isDesktop ? "carrer_text_sub-desktop" : "")}`}>
      <br/><p   className={` ${isMobile ? "carrer_text_sub_2-mobile" : (isDesktop ? "carrer_text_sub_2-desktop" : "")}`}>Knowing that your work is helping to improve people's lives can be incredibly rewarding.</p>
 </div>
 <div  className={` ${isMobile ? "carrer_img_tex_sub-mobile" : (isDesktop ? "carrer_img_tex_sub-desktop" : "")}`}>
 <img  className={` ${isMobile ? "carrer_img-mobile" : (isDesktop ? "carrer_img-desktop" : "")}`} src={f2} />
 <div  className={` ${isMobile ? "carrer_bullet-mobile" : (isDesktop ? "carrer_bullet-desktop" : "")}`}>
 <div  className={` ${isMobile ? "carrer_txt_2-mobile" : (isDesktop ? "carrer_txt_2-desktop" : "")}`}>
 <BsFillCheckCircleFill style={{fontSize: '20px', paddingRight:'2%',color:'#fff200'}}/>
          <div  className={` ${isMobile ? "carrer_titiel_sub-mobile" : (isDesktop ? "carrer_titiel_sub-desktop" : "")}`}>
          Small team, big impact
          <div   className={` ${isMobile ? "carrer_sub1-mobile" : (isDesktop ? "carrer_sub1-desktop" : "")}`}>
          We're a small team working in a fast-paced enviroment, with cross-team projects that allow for strong individual influence on the company.
           </div>
          </div>
          
  </div>

  <div  className={` ${isMobile ? "carrer_txt_3-mobile" : (isDesktop ? "carrer_txt_3-desktop" : "")}`}>
 <BsFillCheckCircleFill style={{fontSize: '20px', paddingRight:'2%',color:'#fff200'}}/>
          <div  className={` ${isMobile ? "carrer_titiel_sub_2-mobile" : (isDesktop ? "carrer_titiel_sub_2-desktop" : "")}`}>
          Startup with experience
          <div   className={` ${isMobile ? "carrer_sub1_2-mobile" : (isDesktop ? "carrer_sub1-desktop" : "")}`}>
          Startups often provide more learning opportunities than larger companies. You may be exposed to a variety of tasks and projects that can help you develop new skills and broaden your understanding of your field.
          
           </div>
          </div>
          
  </div>
  <div className={` ${isMobile ? "carrer_txt_4-mobile" : (isDesktop ? "carrer_txt_4-desktop" : "")}`}>
 <BsFillCheckCircleFill style={{fontSize: '20px', paddingRight:'2%' , color:'#fff200'}}/>
          <div className={` ${isMobile ? "carrer_titiel_sub-mobile" : (isDesktop ? "carrer_titiel_sub-desktop" : "")}`}>
          People and Culture
          <div className={` ${isMobile ? "carrer_sub1-mobile" : (isDesktop ? "carrer_sub1-desktop" : "")}`}>
          Our company's people and culture can reflect its commitment to diversity and inclusion. This might be evident in the our hiring practices, its promotion of a diverse workforce, and its policies on inclusivity and fairness .

           </div>
          </div>
          
  </div>


 </div>
 
 </div>
 


  
 
     
</div>
      
  
</div>
<div style={{textAlign:'right'}}>

</div>
   
 
  </div> 

</div>
          <div  className={` ${isMobile ? "job_detailstitel-mobile" : (isDesktop ? "job_detailstitel-desktop" : "")}`}>
          Find Your Jobs  
          
         </div>
    

<div className={` ${isMobile ? "job_detailstitel_cards-mobile" : (isDesktop ? "job_detailstitel_cards-desktop" : "")}`}>


</div>


<div className="shadow-lg rounded-lg bg-white mx-auto w-11/12 mt-4">
    
    <>

    <div className="flex flex-wrap justify-center ">
      
      {jobflash.map((jobflash) => {
         
       if (isJobStillValid(jobflash)) {
       
        return (
        <div key={jobflash.id} className=" lg:w-1/4 p-4">
            <div className={` ${isMobile ? "job_sub_cards-mobile" : (isDesktop ? "job_sub_cards-desktop" : "")}`}>
      <div className={` ${isMobile ? "mas-mobile" : (isDesktop ? "mas_8-desktop" : "")}`}>
          {/* <div className={` ${isMobile ? "mas_img-mobile" : (isDesktop ? "mas_img-desktop" : "")}`}>
            <a style={{width:'100px',minWidth:'100px',margin:'0px 0px 0px 0px' , borderRadius:'10px',textDecoration:'none'}}>
       
            <img src={jobflash.image}  className="h-30 w-full rounded-t-lg " />

            </a>
          </div> */}
          
          <div style={{padding:'0px 0px 40px 40px',position:'relative' }}>
            <h2>
              <a style={{textAlign:'left',margin:'0px 0px 10px 0px' ,fontSize:'15px',lineHeight:'1.4em',letterSpacing:'-0.15px',fontWeight:'800',fontFamily:'Neuwelt'}}>{jobflash.title}</a>
            </h2>
            <div className={` ${isMobile ? "mas_date_ico-mobile" : (isDesktop ? "mas_date_ico-desktop" : "")}`}>
            <div className={` ${isMobile ? "mas_date_ico_sub-mobile" : (isDesktop ? "mas_date_ico_sub-desktop" : "")}`}>
              <div  className={` ${isMobile ? "mas_date-mobile" : (isDesktop ? "mas_date-desktop" : "")}`}>
    <h5 className={` ${isMobile ? "mas_cata-mobile" : (isDesktop ? "mas_cata-desktop" : "")}`}>
    <CiCalendarDate
className={isMobile ? "mas_date_ico-mobile" : "mas_date_ico"}
  />
      <p className={` ${isMobile ? "mas_date_tex-mobile" : (isDesktop ? "mas_date_tex-desktop" : "")}`}>{jobflash.date_posted}</p>
    </h5>
  </div>
  <div className={` ${isMobile ? "mas_date-mobile" : (isDesktop ? "mas_date-desktop" : "")}`}>
    <h5 className={` ${isMobile ? "mas_cata-mobile" : (isDesktop ? "mas_cata-desktop" : "")}`}>
    <AiOutlineFolderOpen
   className={isMobile ? "mas_date_ico_2-mobile" : "mas_date_ico_2"}
  />
      <p className={` ${isMobile ? "mas_date_tex-mobile" : (isDesktop ? "mas_date_tex-desktop" : "")}`}>{jobflash.content}</p>
    </h5>
  </div>
              </div>
              <div  className={` ${isMobile ? "mas_date-mobile" : (isDesktop ? "mas_date-desktop" : "")}`}>
    <h5 className={` ${isMobile ? "mas_cata-mobile" : (isDesktop ? "mas_cata-desktop" : "")}`}>
    
    <div style={{display:"flex",paddingTop:"10px", width:"100%"}}>
<a  onClick={() => setButtonPopup_2(true)}  className={` ${isMobile ? "mas_date_tex_2-mobile" : (isDesktop ? "mas_date_tex_5-desktop" : "")}`}> Apply Now</a>
     
     <AiOutlineArrowRight
    className={` ${isMobile ? "mas_date_ico_3-mobile" : (isDesktop ? "mas_date_ico_3-desktop" : "")}`}
 />
</div>
    </h5>
              </div>
            </div>
          </div>
      </div>
  </div>

        </div>
        
        );
        
        } else {
          
          return null;
        }
        
})}
  
    </div>
    
    <div className="job_button">
        <button  onClick={() => { navigate('/job');  }}   className={` ${isMobile ? "job_Bn-mobile" : (isDesktop ? "job_Bn-desktop" : "")}`}>
            View All Jobs
        </button>
 </div>
    </>

    </div>


    <Popup trigger={buttonPopup_2} setTrigger={setButtonPopup_2}  >
        <div className="top-0 pt-0 mx-auto font-neuwelt-">
        <div >
        <h6 style={{fontFamily:'Neuwelt',fontSize:'25px',display:'flex',justifyContent:'center'}}>Job Application Form</h6>
        </div>
   
<form onSubmit={handleSubmit} className="max-w-xs mx-auto flex flex-col justify-center h-85 pb-8">
  <div className="mb-3">
    <label className="block text-sm font-bold mb-1 py-1" htmlFor="name">
      Name:
    </label>
    <input
      className="w-full border border-gray-300 rounded-sm px-2 text-sm"
      type="text"
      id="name"
      value={name}
      onChange={e => setName(e.target.value)}
    />
  </div>
  <div className="mb-3">
    <label className="block text-sm font-bold " htmlFor="email">
      Email:
    </label>
    <input
      className="w-full border border-gray-300 rounded-sm px-2  text-sm"
      type="email"
      id="email"
      value={email}
      onChange={e => setEmail(e.target.value)}
    />
  </div>
  <div className="mb-3">
    <label className="block text-sm font-bold " htmlFor="phone">
      Phone:
    </label>
    <input
      className="w-full border border-gray-300 rounded-sm px-2  text-sm"
      type="text"
      id="phone"
      value={phone}
      onChange={e => setPhone(e.target.value)}
    />
  </div>
  {/* Resume field */}
  <div className="mb-3">
    <label className="block text-sm font-bold " htmlFor="resume">
      Resume:
    </label>
    <input
      className="border border-gray-300 rounded-sm px-2  text-sm"
      type="file"
      id="resume"
      onChange={e => setResume(e.target.files[0])}
    />
  </div>
  <div className="mb-3">
    <label className="block text-sm font-bold " htmlFor="introduction">
      About Your Self:
    </label>
    <textarea
      className="w-full border border-gray-300 rounded-sm px-2   text-sm"
      id="introduction"
      value={introduction}
      onChange={e => setIntroduction(e.target.value)}
    ></textarea>
  </div>
  <button
    className="bg-slate-950 hover:bg-gray-950 text-white font-bold mb-1 py-1  px-4 rounded"
    type="submit"
    disabled={isSubmitting}
  >
    Submit
  </button>

  <div>
                <Toaster 
                position="top-center"
                reverseOrder={false}
                />
              
              </div>
</form>
        </div>
        
      </Popup>
    <div  className={isMobile ? "Group200-mobile" : "Group200"} >

<div  className={isMobile ? "Group182-mobile" : "Group182"} >
 

   {/* <img  src={dro} className={` ${isMobile ? "fotter-icon-mobile" : (isDesktop ? "fotter-icon-desktop" : "")}`}/> */}
   
   <div  className={` ${isMobile ? "footer_Sub_txt_5-mobile" : (isDesktop ? "footer_Sub_txt_5-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_5_5-mobile" : (isDesktop ? "footer_Sub_txt_5_5-desktop" : "")}`}>
    
          Ethiopia
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_5-mobile" : (isDesktop ? "footer_Sub_para_5-desktop" : "")}`}> 
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  />
  Gulele Subcity, Addis Ababa, Ethiopia 
    </div>

    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  +251112734554 / +251913667537
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />
  info@drogapharma.com
    </div>
       </div>
   </div>
     
 
 

     
   <div   className={` ${isMobile ? "footer_Sub_txt_2-mobile" : (isDesktop ? "footer_Sub_txt_2-desktop" : "")}`}>


<div   className={` ${isMobile ? "footer_Sub_txt_2_2-mobile" : (isDesktop ? "footer_Sub_txt_2_2-desktop" : "")}`}>
    Somaliland
</div>
<div  className={` ${isMobile ? "footer_Sub_para_2-mobile" : (isDesktop ? "footer_Sub_para_2-desktop" : "")}`}> 
<div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />Hargeysa,Somaliland 
    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />+252634958444

    </div>
    <div  className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />info@drogapharma-som.com
    </div>
   </div>
  </div>


<div   className={` ${isMobile ? "footer_Sub_txt_3-mobile" : (isDesktop ? "footer_Sub_txt_3-desktop" : "")}`}>


    <div   className={` ${isMobile ? "footer_Sub_txt_3_3-mobile" : (isDesktop ? "footer_Sub_txt_3_3-desktop" : "")}`}>
        
        Quick Links
    </div>
    <div   className={` ${isMobile ? "footer_Sub_para_3-mobile" : (isDesktop ? "footer_Sub_para_3-desktop" : "")}`}> 
    <div   className="footer_sub_txt_3">
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
     
    }}
  /> <a href='/' style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Home</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  />  <a onClick={() => { navigate('/aboutus');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}> About Us</a>
    </div>
    <div className="footer_sub_txt_3" >
    <RadioButtonCheckedIcon
    style={{
      position: 'relative',
      right: 10,
      top: 0,
      color: '#000',
      fontSize:'20px',
      
    }}
  /> <a onClick={() => { navigate('/get');  }} style={{textDecoration:'none', color:'black',fontFamily:'Neuwelt'}}>Contact Us</a>
    </div>

       </div>
   </div>
   


 
</div>
   </div>
<div className="footer_right_hm">

<div className='footer_Right_txt_hm'>© 2023 - Droga Pharma PLC</div>

</div>

    </>
    
  );
}
