import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles  } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import axios from 'axios';

import Popup from '../Popup/Popup';

import './acc.css';

import emailjs from 'emailjs-com';

import CircleIcon from '@mui/icons-material/Circle';
import toast, { Toaster } from 'react-hot-toast';




const useStyles = makeStyles((theme) => ({
    accordion: {
      // adjust this value to your needs
      margin: '5px auto', // center the accordion horizontally
      backgroundColor: '#F9F7F1',
      '&:hover': {
        backgroundColor: '#fff200',
      },
    },
    detail:{
      fontFamily:'Neuwelt',
    
      lineheight:'1.5',
      
    },
    rd_img:{
width:'20vw',
height:'30Vh',
marginLeft:'40%',
alignContent:'center'
    },
    expanded: {
      backgroundColor: '#fff200',
    },
    titel:{
        fontFamily:'Neuwelt ',
        fontWeight:'700',
        marginRight:'63%',
        [theme.breakpoints.down('sm')]: {
          fontFamily:'Neuwelt ',
          fontWeight:'700',
          marginRight:'42%',
          fontSize:'16px',
          paddingTop:'10px'
        },		
        
    },
    titel_p:{
      fontFamily:'Neuwelt light', 
      color:'gray', 
      fontSize:'15px',
      display:'flex',
      [theme.breakpoints.down('sm')]: {
        fontFamily:'Neuwelt light', 
        color:'gray', 
        display:'flex',
        fontSize:'12px',
        paddingTop:'10px'
      },
    },
    titel2:{
      fontFamily:'Neuwelt ',
      fontWeight:'700',
      marginRight:'57%',
      [theme.breakpoints.down('sm')]: {
        fontFamily:'Neuwelt ',
        fontWeight:'700',
        marginRight:'35%',
        fontSize:'16px',
        paddingTop:'10px'
      },	
      
  },
  titel2_p:{
    fontFamily:'Neuwelt light', 
    color:'gray', 
    fontSize:'15px',
    display:'flex',
    [theme.breakpoints.down('sm')]: {
      fontFamily:'Neuwelt light', 
      color:'gray', 
      display:'flex',
      fontSize:'12px',
      paddingTop:'10px'
    },
  },
  titel7:{
    fontFamily:'Neuwelt ',
    fontWeight:'700',
    marginRight:'53%',
    [theme.breakpoints.down('sm')]: {
      fontFamily:'Neuwelt ',
      fontWeight:'700',
      marginRight:'28%',
      fontSize:'16px',
      paddingTop:'10px'
    },		
    
},
titel7_p:{
  fontFamily:'Neuwelt light', 
  color:'gray', 
  fontSize:'15px',
  display:'flex',
  
  [theme.breakpoints.down('sm')]: {
    fontFamily:'Neuwelt light', 
    color:'gray', 
    display:'flex',
    fontSize:'12px',
    paddingTop:'10px'
  },
},
  titel6:{
    fontFamily:'Neuwelt ',
    fontWeight:'700',
    marginRight:'59.5%',
   
    [theme.breakpoints.down('sm')]: {
      fontFamily:'Neuwelt ',
      fontWeight:'700',
      marginRight:'28%',
      fontSize:'16px',
      paddingTop:'10px'
    },	
    
},
titel6_p:{
  fontFamily:'Neuwelt light', 
  color:'gray', 
  fontSize:'15px',
  display:'flex',
  [theme.breakpoints.down('sm')]: {
    fontFamily:'Neuwelt light', 
    color:'gray', 
    display:'flex',
    fontSize:'12px',
    paddingTop:'10px'
  },
},
  titel4:{
    fontFamily:'Neuwelt ',
    fontWeight:'700',
    marginRight:'54.5%',
    
    [theme.breakpoints.down('sm')]: {
      fontFamily:'Neuwelt ',
      fontWeight:'700',
      marginRight:'32%',
      fontSize:'16px',
      paddingTop:'10px'
    },	
    
},
titel4_p:{
  fontFamily:'Neuwelt light', 
  color:'gray', 
  fontSize:'15px',
  display:'flex',
  [theme.breakpoints.down('sm')]: {
    fontFamily:'Neuwelt light', 
    color:'gray', 
    display:'flex',
    fontSize:'12px',
    paddingTop:'10px'
  },
},
  titel3:{
    fontFamily:'Neuwelt ',
    fontWeight:'700',
    marginRight:'60%',	
    [theme.breakpoints.down('sm')]: {
      fontFamily:'Neuwelt ',
      fontWeight:'700',
      marginRight:'32%',
      fontSize:'16px',
      paddingTop:'10px'
    },	
    
},
titel3_p:{
  fontFamily:'Neuwelt light', 
  color:'gray', 
  fontSize:'15px',
  display:'flex',
  [theme.breakpoints.down('sm')]: {
    fontFamily:'Neuwelt light', 
    color:'gray', 
    display:'flex',
    fontSize:'12px',
    paddingTop:'10px'
  },
},
titel5:{
  fontFamily:'Neuwelt ',
  fontWeight:'700',
  marginRight:'61.2%',	
  [theme.breakpoints.down('sm')]: {
    fontFamily:'Neuwelt ',
    fontWeight:'700',
    marginRight:'40%',
    fontSize:'16px',
    paddingTop:'10px'
  },	
},
titel5_p:{
  fontFamily:'Neuwelt light', 
  color:'gray', 
  fontSize:'15px',
  display:'flex',
  [theme.breakpoints.down('sm')]: {
    fontFamily:'Neuwelt light', 
    color:'gray', 
    display:'flex',
    fontSize:'12px',
    paddingTop:'10px'
  },
},
titel8:{
  fontFamily:'Neuwelt ',
  fontWeight:'700',
  marginRight:'80.6%',
  [theme.breakpoints.down('sm')]: {
    fontFamily:'Neuwelt ',
    fontWeight:'700',
    marginRight:'35%',
    fontSize:'16px',
    paddingTop:'10px'
  },	
  
},
titel8_p:{
  fontFamily:'Neuwelt light', 
  color:'gray', 
  fontSize:'15px',
  display:'flex',

  [theme.breakpoints.down('sm')]: {
    fontFamily:'Neuwelt light', 
    color:'gray', 
    display:'flex',
    fontSize:'12px',
    paddingTop:'10px'
  },
},
    titel1:{
      fontFamily:'Neuwelt',
      marginBottom:'20px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft:'15px',
        fontSize: '25px',
      },
  },
  
  tit:{
    display:'flex',
    flexDirection:'row'
  },
    sub:{
        fontFamily:'Neuwelt medium',
        textAlign:'justify',
        marginTop:'10px',
        marginBottom:'30px'
    },
    job_Bn:{
      fontSize: '15px',
      fontFamily: 'Neuwelt',
      borderRadius: '10px',
      boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.3)',
      background: '#fff200',
      paddingLeft: '25px',
      paddingRight: '25px',
      paddingTop:'20px',
      paddingBottom:'20px',
      marginLeft: '40%',
      marginBottom: '5%',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop:'15px',
        paddingBottom:'15px',
        marginLeft: '20%',
        marginBottom: '5%',
        fontSize: '12px',
      },
    }
  }));
  const JobPanel = () => {
    const [job, setJob] = useState(null);
   
    useEffect(() => {

       axios.get('https://backend.drogapharmacy.com/api/job-postings/') 
         .then(response => {
           setJob(response.data);
         })
         .catch(error => {
           console.error('Failed to fetch job data:', error);
         });
    }, []);
   
    if (!job) {
       return <div>Loading...</div>;
    }
  }
const MyAccordion = () => {
  



  
 

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [buttonPopup,setButtonPopup] = useState(false);
  const [jobpos,setJobpos] = useState();

  useEffect(() => {
    axios
          .get('https://backend.drogapharmacy.com/api/jobpositionCrate/')
          .then((response) => {
            console.log(response.data);
            setJobpos(response.data);
           
          })
          .catch((error) => {
            console.error('Error fetching blog appState:', error);
          
          });
  
  }, []);


  // const handlecli = () => {
  //   setIsOpen(!isOpen);
  // };

  useEffect(() => {
    const handler = e => setIsMobile(e.matches);
    window.matchMedia("(max-width: 950px)").addEventListener('change', handler);
  }, []);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 950px)").matches);
  
  useEffect(() => {
    const handler = e => setIsDesktop(e.matches);
    window.matchMedia("(min-width: 1280px) and (max-width: 1920px)").addEventListener('change', handler);
  }, []);
  
  const [isDesktop, setIsDesktop] = useState(window.matchMedia("(min-width: 1080px) and (max-width: 1920px)").matches);
  
  const[result,showResult] = useState(false);
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_6g2p4q7', 'template_tlpj557', e.target, 'wsT4oMIJu8oD0V-Zx')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      showResult(true);
      
  };

setTimeout(() => {
  showResult(false);
},0);


const [name, setName] = useState('');
const [email, setEmail] = useState('');
const [phone, setPhone] = useState('');
const [linkedinProfile, setLinkedinProfile] = useState('');
const [resume, setResume] = useState(null);
const [introduction, setIntroduction] = useState('');
const [jobPostings, setJobPostings] = useState([]);
const [isSubmitting, setIsSubmitting] = useState(false);



const [resultt, setResult] = useState(false);

const handleSubmit = async (e) => {
 e.preventDefault();
 setIsSubmitting(true);
 const formData = new FormData();
 formData.append('name', name);
 formData.append('email', email);
 formData.append('phone', phone);
 formData.append('resume', resume);
 formData.append('introduction', introduction);
  
 if (resultt) {
  toast.success('Your Messsage Successfully sent. We will contact you soon!');
}

 axios.post('https://backend.drogapharmacy.com/api/user_details/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
 })
 .then((response) => {
    console.log(response.data);
    setResult(true);
    e.target.reset();
    setIsSubmitting(false);
 })
 .catch((error) => {
    console.log(error);
    setIsSubmitting(false);
 });
};

 





useEffect(() => {
   axios.get('http://127.0.0.1:8000/api/job-postings/') 
     .then(response => setJobPostings(response.data))
     .catch(error => console.error(`Error: ${error}`));
}, []); 






  return (
    <div>
      <Typography className={classes.titel1} variant="h4" gutterBottom>
      Open Positions
      </Typography>

      {jobPostings.map((jobPosting) => {
 const dateParts = jobPosting.date_posted.split("-");
 const postedDate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);
 const currentDate = new Date();
 const diffTime = Math.abs(currentDate - postedDate);
 const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
 const status = diffDays <= 1 ? "Open" : "Closed";
 const color = status === "Open" ? "green" : "red";
 const Icon = status === "Open" ? CircleIcon : CircleIcon;

 return (

        <Accordion key={jobPosting.id} className={classes.accordion}>
        <AccordionSummary  expandIcon={<ExpandMoreIcon /> } 
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{display: 'flex', justifyContent: 'space-between'}}
                          
        >
          <Typography className={classes.titel8}>{jobPosting.title}</Typography>
          <div style={{ color: color,marginLeft: 'auto',display:'flex' }}>
          <Icon style={{ marginLeft: 'auto' }} />
          <span style={{ color: color , marginLeft: 'auto', }}>{status}</span>

        </div>

        </AccordionSummary>
        <AccordionDetails >
          <div>
          <Typography className={classes.sub}>{jobPosting.description}</Typography>
          <p style={{fontFamily:'Neuwelt medium',fontWeight:'700',marginBottom:'10px'}}>{jobPosting.accountability}</p>
          <ul>
      {jobPosting.responsibilities.map((responsibility) => (
        <li key={responsibility.id} style={{ fontFamily: 'Neuwelt', marginBottom: '10px' }}>
          <RadioButtonCheckedIcon style={{ fontSize: '18px' }} />
          {responsibility.description}
        </li>
        
      ))}
         <div className="job_detail_acc_btn">
      <button onClick={() => setButtonPopup(true)}  className={classes.job_Bn}>
                  Apply Now!
   
              </button>
      </div>
      <Popup trigger={buttonPopup} setTrigger={setButtonPopup}  >
        <div className="top-0 pt-0 mx-auto font-neuwelt-">
        <div >
        <h6 style={{fontFamily:'Neuwelt',fontSize:'25px',display:'flex',justifyContent:'center'}}>Job Application Form</h6>
        </div>
 

<form onSubmit={handleSubmit} className="max-w-xs mx-auto flex flex-col justify-center h-85 pb-8">
  <div className="mb-3">
    <label className="block text-sm font-bold mb-1 py-1" htmlFor="name">
      Name:
    </label>
    <input
      className="w-full border border-gray-300 rounded-sm px-2 text-sm"
      type="text"
      id="name"
      value={name}
      onChange={e => setName(e.target.value)}
    />
  </div>
  <div className="mb-3">
    <label className="block text-sm font-bold " htmlFor="email">
      Email:
    </label>
    <input
      className="w-full border border-gray-300 rounded-sm px-2  text-sm"
      type="email"
      id="email"
      value={email}
      onChange={e => setEmail(e.target.value)}
    />
  </div>
  <div className="mb-3">
    <label className="block text-sm font-bold " htmlFor="phone">
      Phone:
    </label>
    <input
      className="w-full border border-gray-300 rounded-sm px-2  text-sm"
      type="text"
      id="phone"
      value={phone}
      onChange={e => setPhone(e.target.value)}
    />
  </div>
  {/* Resume field */}
  <div className="mb-3">
    <label className="block text-sm font-bold " htmlFor="resume">
      Resume:
    </label>
    <input
      className="border border-gray-300 rounded-sm px-2  text-sm"
      type="file"
      id="resume"
      onChange={e => setResume(e.target.files[0])}
    />
  </div>
  <div className="mb-3">
    <label className="block text-sm font-bold " htmlFor="introduction">
      About Your Self:
    </label>
    <textarea
      className="w-full border border-gray-300 rounded-sm px-2   text-sm"
      id="introduction"
      value={introduction}
      onChange={e => setIntroduction(e.target.value)}
    ></textarea>
  </div>
  <button
    className="bg-slate-950 hover:bg-gray-950 text-white font-bold mb-1 py-1  px-4 rounded"
    type="submit"
    disabled={isSubmitting}
  >
    Submit
  </button>
  <div>
  <div style={{
    position: 'absolute',
    top: '40%',
    transform: 'translate(-10%, -10%)',

}}>
    <Toaster 
        position="top-center"
        reverseOrder={false}
    />
</div>

               

                
              </div>
</form>

        </div>
        
      </Popup>
    </ul>
    </div>

    
        </AccordionDetails>

      </Accordion>
 );
})}

   
    </div>
  );
}

export default MyAccordion;
